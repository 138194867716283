import React from 'react';
import PropTypes from 'prop-types';
import { MainView } from '../../../templates/MainView';
import { Field } from '../../../components/molecules/Field';
import { InfoCard } from '../../../components/molecules/InfoCard';
import { HeadingBlock } from '../../../components/molecules/HeadingBlock';

import password from '../../../components/validation/password';
import { useFormState } from 'react-final-form';

// import './styles.css';

/**
 * Primary UI component for user interaction
 */
export const PasswordCreation = ({ buttons, customButtons }) => {
  const formState = useFormState();

  const matchesPassword = value => {
    return formState.values.password == value ? undefined : 'Must match';
  }
  return (
    <MainView BPadding1>
        <div className='px-6 pt-8 pb-14 smd:p-12 flex flex-col'>
          <div className='flex flex-col space-y-8'>
            <HeadingBlock heading='Create your password' text='The password you choose will be how you get into your LiveWell account.' />

            <InfoCard text='Passwords must contain a minimum of 8 characters.' icon='info-round' />

            <Field
                input='password'
                name='password'
                label='Password'
                placeholder='Enter password'
                modalsubtitle='What is this?'
                modaltitle='Password'
                help= 'Enter your password again to ensure you have entered it correctly'
                validation={[password]}
                isRequired
            />

            <Field
                input='password'
                name='password_confirmation'
                label='Confirm Password'
                placeholder='Enter password'
                modalsubtitle='What is this?'
                modaltitle='Confirm Password'
                help= 'Enter your password again to ensure you have entered it correctly'
                validation={matchesPassword}
                isRequired
            />
          </div>
            
          { customButtons || buttons }
        </div>
    </MainView>
  );
};

PasswordCreation.propTypes = {
    buttons: PropTypes.element,
    customButtons: PropTypes.element
};

PasswordCreation.defaultProps = {
    buttons: null
};
