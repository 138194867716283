import React from 'react';
import PropTypes from 'prop-types';
import { Text } from '../../atoms/Text';
import { Heading } from '../../atoms/Heading';

/**
 * Primary UI component for user interaction
 */
export const ResultCard = ({ title, body, classes }) => {
  return (
    <div className={[
      'result-card bg-lwd-grey-100 rounded-xl shadow-card overflow-hidden',
      classes && classes
      ].join(' ')}>
      <div className="p-4 flex flex-col justify-center items-center text-center">
        <Heading classes='mb-2 text-primary-500' h1>{title}</Heading>
        <Text body>{body}</Text>
      </div>
    </div>
  );
};

ResultCard.propTypes = {
  /**
   * Heading of the card
   */
  // title: PropTypes.string.isRequired,

  /**
   * Main body text
   */
  body: PropTypes.string.isRequired,
};

ResultCard.defaultProps = {
  heading: '',
  body: '',
};

