import React from 'react';
import PropTypes from 'prop-types';

import { Text } from '../../atoms/Text';

import './styles.css';

/**
 * Primary UI component for user interaction
 */
export const HeadingTopBlock = ({ text, classes }) => (
  <div className={[
        'heading-top-block',
        classes && classes
    ].join(' ')}
  >
    <Text body1m>{ text }</Text>
  </div>
);

HeadingTopBlock.propTypes = {
    /**
     * Many heading block text
     */
    text: PropTypes.string.isRequired,
    classes: PropTypes.string,
};

HeadingTopBlock.defaultProps = {
    text: null,
};