import React, { useState } from 'react';
import PropTypes from 'prop-types';
import './styles.css';
import { Icon } from '../../atoms/Icon';
import { Text } from '../../atoms/Text';

import './styles.css';

/**
 * Primary UI component for user interaction
 */
export const AlcoholCard = ({ alcoholType, alcoholUnitText, units, iconName, classes, onAmountChange, itemKey, amount }) => {
  // const [amount, setAmount] = useState(0);

  const onReduceAmount = () => {
    if (amount > 0) {
      
      // setAmount(prevAmount => {
        onAmountChange(-1 * units, amount - 1,itemKey);
        return amount - 1;
      // });
    }
  };

  const onIncreaseAmount = () => {
    // setAmount(prevAmount => {
      onAmountChange(1 * units, amount + 1, itemKey);
      return amount + 1;
    // });
  };

  return (
    <div className={['alcohol-card', classes && classes].join(' ')}>
      <div className='alcohol-title'>
        {iconName && <Icon name={iconName} />}
        <div className='flex flex-col items-start pr-1'>
          <Text body>{alcoholType}</Text>
          {alcoholUnitText && <Text classes='unit-text' body>({alcoholUnitText})</Text>}
        </div>
      </div>
      <div className='counter-container'>
        <button type='button' onClick={onReduceAmount} className='counter-button'>
          <Icon name='minus' />
        </button>
        <Text classes='font-todaysbmed text-26 leading-lwd-1.2 tracking-lwd-tight'>{amount}</Text>
        <button type='button' onClick={onIncreaseAmount} className='counter-button'>
          <Icon name='plus' />
        </button>
      </div>
    </div>
  );
};

AlcoholCard.propTypes = {
  alcoholType: PropTypes.string,
  alcoholUnitText: PropTypes.string,
  units: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  iconName: PropTypes.string,
  classes: PropTypes.string,
  onAmountChange: PropTypes.func,
};

AlcoholCard.defaultProps = {
  alcoholType: '',
  alcoholUnitText: '',
  iconName: '',
  classes: '',
  onAmountChange: () => {}
};
