import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import { MainView } from '../../../templates/MainView';
import { Image } from '../../../components/atoms/Image';
import { Heading } from '../../../components/atoms/Heading';
import { Button } from '../../../components/atoms/Button';
import { HeadingBlock } from '../../../components/molecules/HeadingBlock';
import { Card } from '../../../components/molecules/Card';
import { Carousel } from '../../../components/organisms/Carousel';
import { ImageCtaSection } from '../../../components/organisms/ImageCtaSection';

import yogaImgSrc from '../../../assets/images/registation/yoga.svg';
import questionsImgSrc from '../../../assets/images/registation/questions.svg'
import { getArticles } from '../../../features/articles';
import nhsImage from '../../../assets/images/tooyoung/nhs.png';
import koothImage from '../../../assets/images/tooyoung/kooth.png';
import betterHealthImage from '../../../assets/images/tooyoung/betterhealth.png';
import edasImage from '../../../assets/images/tooyoung/edas.png';
import wessexImage from '../../../assets/images/tooyoung/wessex.png';

/**
 * Primary UI component for user interaction
 */
export const TooYoung = ({ children }) => {
  const dispatch = useDispatch();
  const articles = useSelector(state => state.articles);

  useEffect(() => {
    dispatch(getArticles(true));
  }, [dispatch]);


  const youngSupport = [
    {
      id:'nhs-chat-healthUK',
      image: nhsImage,
      title: 'NHS Chat Health',
      link: 'https://chathealth.nhs.uk/',
    },
    {
      id:'kooth-wellbeing-community',
      image: koothImage,
      title: 'Kooth Wellbeing Community',
      link: 'https://www.kooth.com/',
    },
    {
      id:'nhs-betterHealth',
      image: betterHealthImage,
      title: 'NHS Better Health',
      link: 'https://www.nhs.uk/healthier-families/',
    },
    {
      id:'edasUk',
      image:edasImage,
      title: 'EDAS Drug and Alcohol Support',
      link: 'https://www.edasuk.org/support/yp/',
    },
    {
      id:'wessexHealthier',
      image: wessexImage,
      title: 'Wessex Healthier Together',
      link: 'https://what0-18.nhs.uk/',
    },
  ]

  return (
    <MainView type='fullwidth' showFooter>
      <div className='px-6 py-8 smd:p-12 bg-primary-500'>
        <div className="max-w-lwd-860 mx-auto">
          <HeadingBlock heading='Oh no! You need to be 18 to use our service' text='Never mind. We are really impressed that you are thinking about your health and wellbeing. Keep up the good work!' classes='text-lwd-grey-100' />
        </div>
      </div>


     <div className='py-8 smd:p-12'>
        <div className="max-w-lwd-860 mx-auto">
          <Heading classes="px-6 mb-6 smd:px-0" h2>You might find some help here</Heading>
          <Carousel
            classes="pl-6 smd:pl-0 smd:mr-neg-12 w-auto lg:mr-0 lg:w-auto lg:hidden"
            layout='fullscreen'
            data={youngSupport.map((service) => (
              <Card
                key={service.id}
                classes='h-full'
                image={service.image}
                imageAlt={service.title}
                title={service.title}
                btnLabel="Visit Service"
                btnOnClick={() => window.open(service.link, '_blank')}
              />
            ))}
          />
          <div className='hidden lg:flex flex-auto flex-wrap gap-5 justify-center'>
            {youngSupport.map((service) => (
              <Card key={service.id}
                classes='min-h-[350px] w-[270px]'
                image={service.image}
                imageAlt={service.title}
                title={service.title}
                btnLabel="Visit Service"
                btnOnClick={() => window.open(service.link, '_blank')}
              />
            ))}
          </div>
        </div>
      </div>

      {articles && articles.length > 0 ? (<div className='py-8 smd:p-12 bg-primary-100'>
        <div className="max-w-lwd-860 mx-auto">
          <div className='flex justify-between items-center mb-6'>
            <Heading classes="px-6 smd:px-0" h2>Here's some reading that might help, too</Heading>
            <Button label='View All Articles' onClick={() => window.open('https://www.livewelldorset.co.uk/articles/', '_blank', 'noopener')} mode='underlineBtn' classes='see-all-mobile' />
          </div>
          <Carousel
            classes="pl-6 mb-2 smd:mb-0 smd:pl-0 smd:mr-neg-12 w-auto lg:mr-0 lg:w-full"
            data={articles.slice(0,3).map((article, idx) => (
              <Card
                key={`article-${idx}`}
                classes='h-full'
                image={article.image}
                imageAlt={article.title}
                date={moment.unix(article.published_at).format('Do MMMM, Y')}
                title={article.title}
                body={article.intro}
                btnLabel="Read More"
                btnOnClick={() => window.open(`https://www.livewelldorset.co.uk/${article.url}`, '_blank')}
              />
            ))}
          />
          <div className='px-6 smd:px-0 block smd:hidden'>
            <Button label='View All Articles' onClick={() => window.open('https://www.livewelldorset.co.uk/articles/', '_blank', 'noopener')} mode='primary' classes='w-full justify-center' />
          </div>
        </div>
      </div>) : ''}

      <ImageCtaSection
        image={yogaImgSrc}
        imageAlt='yoga image'
        imageContainerClasses='yoga-image-container'
        heading='Understand your habits'
        content='Our self-help habit hacker tool will support you to make changes by understanding what you find tough and making a plan.'
        contentClasses='my-auto'
        btnLabel='Try Habit Hacker'
        btnOnClick={() => window.open('https://www.livewelldorset.co.uk/habit-hacker/', '_blank', 'noopener')}
      />

      <ImageCtaSection
        containerClasses='bg-primary-100'
        image={questionsImgSrc}
        imageAlt='questions image'
        imageContainerClasses='yoga-image-container smd:order-1'
        heading='Take our quiz'
        content='Try out our interactive quiz to find out if you’re living well, and receive your LiveWell score. See what national guidelines have to asay about your habits!'
        contentClasses='my-auto'
        btnLabel='Take Quiz'
        btnOnClick={() => window.open('https://www.livewelldorset.co.uk/wellbeing-quiz/', '_blank', 'noopener')}
      />
    </MainView>
  );
};

TooYoung.propTypes = {
};

TooYoung.defaultProps = {
};

