import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { getDropdowns } from '../features/dropdowns';

/**
 * Primary UI component for user interaction
 */
export const useDropDown = (key, filterCB=null, sortAlpha=false) => {
  const dispatch = useDispatch();

  const values = useSelector(state => state.dropdowns[key]);

  useEffect(() => {
    dispatch(getDropdowns(key, sortAlpha));
  }, [dispatch]);

  if(filterCB && values) return filterCB(values);

  return values ? values : [];
};
