import React, { useState, useRef, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getMessages } from '../../../features/messages';
import { getActions } from '../../../features/actions';
import PropTypes from 'prop-types';
import moment from 'moment';

import { MyLiveWellView } from '../../../templates/MyLiveWellView';
import { Heading } from '../../../components/atoms/Heading';
import { Text } from '../../../components/atoms/Text';
import { Button } from '../../../components/atoms/Button';
import { ModalView } from '../../../templates/ModalView';
import { Badge } from '../../../components/molecules/Badge';
import { PlanItem } from '../../../components/molecules/PlanItem';
import { Card } from '../../../components/molecules/Card';
import { Carousel } from '../../../components/organisms/Carousel';

import './styles.css';

export const Plan = () => {
  const helpModalRef = useRef(null);
  const [activePlanID, setActivePlanID] = useState(null);
  const [events, setEvents] = useState([]);

  const plan = useSelector(state => state.plan);
  const totalActions = useSelector(
    (state) => state.actions.filter((r) => r.status === "incomplete").length
  );
  const totalMessages = useSelector(state => state.messages.length);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getMessages());
    dispatch(getActions());
  }, [dispatch]);

  useEffect(() => {
    if (!(plan && plan.events?.length)) return;

    // hide past events (soonest first)
    const futureEvents = plan.events
      .filter((event) => event.start_date && moment(event.start_date).unix() - moment().startOf('day').unix() >= 0)
      .sort((a, b) => moment(a.start_date).diff(b.start_date, 'minutes'));

    // show always active events (newest first)
    const alwaysActiveEvents = plan.events
      .filter((event) => !event.start_date)
      .sort((a, b) => moment(b.created_at).diff(a.created_at, 'minutes'));

    setEvents([...futureEvents, ...alwaysActiveEvents]);
  }, [plan]);


  return [
    <MyLiveWellView key={0}>
      <div className='max-w-lwd-860 w-full mx-auto px-6 pt-8 pb-8 smd:py-12 lg:px-0 flex flex-col gap-5 smd:gap-8'>
        <div className='flex flex-row items-end justify-between sm:justify-start'>
          <Heading classes='text-lwd-grey-900' h2>My plan</Heading>
          <Button mode='plainBtn' classes='ml-lwd-30 mb-lwd-5' onClick={() => { helpModalRef.current.openModal(); }} label='What is a plan?' />
        </div>
        <div className='flex flex-row gap-5'>
          <Badge classes='reset-badge'>
            <Text classes='uppercase text-lwd-grey-900 leading-lwd-1.4 font-todaysbbold' caption>Actions</Text>
            <Heading classes='text-secondary-900 leading-lwd-1.2 tracking-lwd-0.1 lg:text-40' h1>{totalActions}</Heading>
          </Badge>
          {typeof plan.id === 'number' && [2, 3].includes(plan.service_level) && (<Badge classes='reset-badge'>
            <Text classes='uppercase text-lwd-grey-900 leading-lwd-1.4 font-todaysbbold' caption>Messages</Text>
            <Heading classes='text-secondary-900 leading-lwd-1.2 tracking-lwd-0.1 lg:text-40' h1>{totalMessages}</Heading>
          </Badge>)}
        </div>
        {plan && plan.goal_notes && (<div className='plan-content-item'>
          <Text classes='uppercase text-lwd-grey-900 leading-lwd-1.4 font-todaysbbold mb-lwd-5' caption>My goal</Text>
          <Text body2>{plan.goal_notes}</Text>
        </div>)}
        {plan && plan.goal_barrier_notes && (<div className='plan-content-item'>
          <Text classes='uppercase text-lwd-grey-900 leading-lwd-1.4 font-todaysbbold mb-lwd-5' caption>What is stopping me?</Text>
          <Text body2>{plan.goal_barrier_notes}</Text>
        </div>)}
        {plan && plan.bcts && plan.bcts.length > 0 && (<div className='plan-content-item'>
          <Text classes='uppercase text-lwd-grey-900 leading-lwd-1.4 font-todaysbbold mb-lwd-5' caption>I am going to try ...</Text>
          <div className='accordion'>
            {plan && plan.bcts && plan.bcts.map((bct, idx) => (
              <PlanItem
                key={`bct${idx}`}
                item={bct}
                plan={plan}
                setActivePlan={setActivePlanID}
                active={activePlanID === bct.id}
              />
            ))}
          </div>
        </div>)}
      </div>

      {plan && plan.articles && plan.articles.length > 0 && (<div className='bg-primary-100 py-8 smd:p-12'>
        <div className='max-w-lwd-860 mx-auto'>
          <div className="max-w-lwd-860 mx-auto">
            <div className='flex justify-between items-center mb-6'>
              <Heading classes="px-6 smd:px-0" h2>Suggested articles</Heading>
              <Button label='View All Articles' onClick={() => window.open('https://www.livewelldorset.co.uk/articles/', '_blank', 'noopener')} mode='underlineBtn' classes='see-all-mobile' />
            </div>

            <Carousel
              key='article-carousel'
              classes="pl-6 mb-2 smd:mb-0 smd:pl-0 smd:mr-neg-12 w-auto lg:mr-0 lg:w-full"
              sliderClasses='lg:flex-wrap'
              itemClasses='flex-1 basis-1/4'
              data={plan.articles.map((article, idx) => (
                <Card
                  key={`article-${idx}`}
                  classes='h-full'
                  image={article.image}
                  imageAlt={article.title}
                  date={moment.unix(article.published_at).format('Do MMMM, Y')}
                  title={article.title}
                  body={article.intro}
                  btnLabel="Read More"
                  btnOnClick={() => window.open(`https://www.livewelldorset.co.uk/${article.url}`, '_blank')}
                />
              ))}
            />
            <div className='px-6 smd:px-0 block smd:hidden'>
              <Button label='View All Articles' onClick={() => window.open('https://www.livewelldorset.co.uk/articles/', '_blank', 'noopener')} mode='primary' classes='w-full justify-center' />
            </div>
          </div>
        </div>
      </div>)}

      {events && events.length > 0 && (<div className='py-8 smd:p-12'>
        <div className='max-w-lwd-860 mx-auto'>
          <div className="max-w-lwd-860 mx-auto">
            <div className='flex justify-between items-center mb-6'>
              <Heading classes="px-6 smd:px-0" h2>Suggested events</Heading>
              <Button label='Explore All Events' onClick={() => window.open('https://www.livewelldorset.co.uk/livewell-finder/', '_blank', 'noopener')} mode='underlineBtn' classes='see-all-mobile' />
            </div>

            <Carousel
              key='event-carousel'
              classes="pl-6 mb-2 smd:mb-0 smd:pl-0 smd:mr-neg-12 w-auto lg:mr-0 lg:w-full"
              sliderClasses='lg:flex-wrap'
              itemClasses='flex-1 basis-1/4'
              data={events.map((event, idx) => {
                const bodyWords = typeof event.description === 'string' ? event.description.split(' ') : [];
                return <Card
                  key={`event-${idx}`}
                  classes='h-full'
                  image={event.image}
                  imageAlt={event.title}
                  title={event.title}
                  body={bodyWords.slice(0, 40).join(' ') + (bodyWords.length > 40 ? '...' : '')}
                  btnLabel="Learn More"
                  btnOnClick={() => window.open(`${event.slug}`, '_blank')}
                />;
              })}
            />
            <div className='px-6 smd:px-0 block smd:hidden'>
              <Button label='Explore All Events' onClick={() => window.open('https://www.livewelldorset.co.uk/livewell-finder/', '_blank', 'noopener')} mode='primary' classes='w-full justify-center' />
            </div>
          </div>
        </div>
      </div>)}
    </MyLiveWellView>,
    <ModalView key={1} ref={helpModalRef} title='Help'>
      <Heading classes='text-primary-500 font-todaysbbold mb-2.5 smd:mb-5' h2>What is a plan?</Heading>
      <Text body2>A plan is what we create for our clients on our first call. We discuss what your goals are and how you might achieve them using behaviour change techniques.</Text>
    </ModalView>
  ];
};

Plan.propTypes = {
  plans: PropTypes.array.isRequired
};

Plan.defaultProps = {
  plans: []
};