import React from 'react';
import PropTypes from 'prop-types';
import Slider from "react-slick";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

/**
 * Primary UI component for user interaction
 */
export const Carousel = ({ data, classes, sliderClasses, itemClasses, ...props }) => {
  const carouselSetting = {
    infinite: false,
    dots: props.dot,
    arrows: props.arrows,
    speed: 500,
    responsive: [
      {
        breakpoint: 9999,
        settings: 'unslick'
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2.07,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 640,
        settings: {
          slidesToShow: 1.07,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <div className={['card-carousel-container', 'w-full', classes && classes].join(' ')}>
      <Slider className={['card-carousel lg:flex lg:flex-row lg:gap-4', sliderClasses && sliderClasses].join(' ')} {...carouselSetting}>
        {data.map(item => (
          <div className={['carousel-item pr-4 lg:pr-0 lg:grow w-full', itemClasses && itemClasses].join(' ')}>
            {item}
          </div>
        ))}
      </Slider>
    </div>
  );
};

Carousel.propTypes = {
  /**
   * This is all our carousel array items
   */
  data: PropTypes.array,

  sliderClasses: PropTypes.string,

  itemClasses: PropTypes.string,
};

Carousel.defaultProps = {
  data: [],
};

