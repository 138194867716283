import React from 'react';
import PropTypes from 'prop-types';
import { MainView } from '../../../templates/MainView';
import { Image } from '../../../components/atoms/Image';
import { Text } from '../../../components/atoms/Text';
import { BadgeGroup } from '../../../components/molecules/BadgeGroup';
import { HeadingBlock } from '../../../components/molecules/HeadingBlock';

import HeaderImg from '../../../assets/images/header.jpg';

import './styles.css';
import { Link } from 'react-router-dom';

/**
 * Primary UI component for user interaction
 */
export const GettingStarted = ({ buttons, customButtons }) => {

  const badges = [
    { label: 'Around 2 Minutes', icon: 'timer', value: null },
    { label: '4 Steps To Complete', icon: 'questions', value: null },
    { label: 'Secure Data Handling', icon: 'secure', value: null },
  ];

  return (
    <MainView>
      <div className='p-0 md:px-12 md:pt-12'>
        <Image src={HeaderImg} alt='Get support from LiveWell Dorset' imgClass="max-h-lwd-200 object-top" />
      </div>

      <div className='px-6 pt-8 pb-32 smd:px-12 smd:pb-12 flex flex-col space-y-6 smd:space-y-8'>
          <HeadingBlock heading='Discover a healthier, happier you' text="Register today for your FREE personal health and wellbeing plan. We're here to support you every step of the way." />
          <BadgeGroup options={badges} />
          <Text body>By tapping “Get Started” you agree to the terms of our <a title='Terms of Service' rel='noopener noreferrer' target='_blank' href='https://www.livewelldorset.co.uk/privacy/'>Privacy Policy</a> and <a title='Terms of Service' rel='noopener noreferrer' target='_blank' href='https://www.livewelldorset.co.uk/terms-and-conditions/'>Terms of Service</a>.</Text>

          { customButtons || buttons }
      </div>
    </MainView>
  );
};

GettingStarted.propTypes = {
  buttons: PropTypes.element,
  customButtons: PropTypes.element
};

GettingStarted.defaultProps = {
  buttons: null,
  customButtons: null,
};

