import React, { useEffect, useState } from 'react';
import { useFormState } from 'react-final-form';
import PropTypes from 'prop-types';
import { MainView } from '../../../templates/MainView';
import { Heading } from '../../../components/atoms/Heading';
import { Text } from '../../../components/atoms/Text';
import { Rating } from '../../../components/molecules/Rating';
import { Field } from '../../../components/molecules/Field';
import { Icon } from '../../../components/atoms/Icon';
import { InfoCard } from '../../../components/molecules/InfoCard';

/**
 * Primary UI component for user interaction
 */
export const LeaveFeedback = ({ buttons, customButtons, submitting, showFeedbackError }) => {
  const formState = useFormState();
  const [commentsLabel, setCommentsLabel] = useState('Do you have any additional feedback?');
  const [commentsPlaceholder, setCommentsPlaceholder] = useState('Enter any additional feedback');

  const feedbackTopics = [
    {label: 'My general experience', value: 'experience', key: 'experience'},
    {label: 'I’d like to report an issue', value: 'issue', key: 'issue'},
    {label: 'I’d like to request a feature', value: 'feature', key: 'feature'},
  ];

  useEffect(() => {
    switch (formState.values.topic) {
      case 'experience':
        setCommentsLabel('Do you have any additional feedback?');
        setCommentsPlaceholder('Enter any additional feedback');
        break;
      case 'issue':
        setCommentsLabel('Please provide details about the issue:');
        setCommentsPlaceholder('Enter any details about the issue');
        break;
      case 'feature':
        setCommentsLabel('What new feature would you like to request?');
        setCommentsPlaceholder('Tell us about the feature');
        break;
    }
  }, [formState.values.topic]);

  return (
    <MainView BPadding1>
      <div className='title-container'>
        <Text classes="font-todaysbmed text-lwd-grey-900 flex flex-row items-center" body1m nav link='/'>
          <Icon name='chevron-purple-left' />
          <span className='ml-3'>Back to home</span>
        </Text>
      </div>

      <div className='px-6 pt-8 pb-14 smd:p-12 flex flex-col'>
        <Heading classes="mb-2" h1>What would you like to provide feedback about?</Heading>

        {!showFeedbackError ? (
          <div>
            <div className='flex flex-col pb-8 space-y-8 smd:space-y-14'>
              <Field
                input='radio'
                name='topic'
                label=''
                inputOptions={{
                  options: feedbackTopics
                }}
                isRequired
              />

              {formState.values.topic === 'experience' && (
                <Rating
                  heading='How would you rate your experience with us so far?'
                  onChange={(value) => {
                    formState.values['rating'] = parseFloat(value);
                  }}
                  isRequired
                  submitting={submitting}
                />
              )}

              {formState.values.topic && (
                <Field
                  input='textarea'
                  rows='4'
                  name='comments'
                  label={commentsLabel}
                  placeholder={commentsPlaceholder}
                  isRequired
                />
              )}
            </div>

            { customButtons || buttons }
          </div>
        ) : (
          <InfoCard
            icon='form-error'
            childrenComponent={(
              <div>
                <Text>Something went wrong while submitting your feedback.</Text>
                <Text>Need some help? Please call our team on <a href='tel:08008401628' className="underline hover:opacity-75">0800 840 1628</a>.</Text>
              </div>
            )}
          />
        )}
      </div>
    </MainView>
  );
};

LeaveFeedback.propTypes = {
  buttons: PropTypes.element,
  customButtons: PropTypes.element,
  submitting: PropTypes.bool,
};

LeaveFeedback.defaultProps = {
  buttons: null,
  submitting: false,
};
