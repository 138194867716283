const phoneUK = value => {
  const errorMsg = 'Please enter a valid phone number.';
  if (! value) return errorMsg;

  value = value.replace(/\s/g, "");
  const regex = /^\d{5}\s?\d{6}$/i;
  const isValid = regex.test(value);
  return isValid ? undefined : errorMsg;
};

export default phoneUK;