import api from "../lib/api";

export const SET_DATES = 'SET_DATES';
export const CLEAR_DATES = 'CLEAR_DATES';

/** dates */

export const getDates = (date) => (dispatch) => {

  api.dates(date, (err, responseJSON) => {
      if(responseJSON && responseJSON.data) {
        return dispatch(setDates(responseJSON.data));
      }

      console.error("Unable to find dates");
  });
};

/** dates */
export const setDates = (payload) => ({
  type: SET_DATES,
  payload,
});

export const clearDates = () => ({
  type: CLEAR_DATES,
});


/** reducer */
const initialState = [];


export default function reducer(state = initialState, action) {
    const { type, payload } = action;

    switch (type) {
    case SET_DATES:
        return payload;

    case CLEAR_DATES:
        return initialState;

    default:
        return state;
    }
}
