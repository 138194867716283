import React from 'react';
import PropTypes from 'prop-types';
import { useFormState } from 'react-final-form';

import { MainView } from '../../../templates/MainView';
import { Text } from '../../../components/atoms/Text';
import { Button } from '../../../components/atoms/Button';
import { Heading } from '../../../components/atoms/Heading';
import { InfoCard } from '../../../components/molecules/InfoCard';
import { InfoCardGroup } from '../../../components/molecules/InfoCardGroup';
import { ProgressBar } from '../../../components/molecules/ProgressBar';
import { useSelector } from 'react-redux';
import { useDropDown } from '../../../lib/useDropdown';
import { ResultCardBig } from '../../../components/molecules/ResultCardBig';

import './styles.css';

/**
 * Primary UI component for user interaction
 */
export const Summary = ({ buttons, customButtons, skipToPage, currentPage, totalPages, pageOffset }) => {
  const formState = useFormState();
  const preAssessment = useSelector(state => state.preassessment);

  const smokingTypes = useDropDown('SmokingType', (values) => values.filter(obj => ['Non-Smoker', 'e-Cigarettes'].indexOf(obj.label) === -1));

  const getValue = (name) => {
    // display alcohol question labels
    if (name.substring(0,8) === 'alcohol_' && name !== 'alcohol_units') {
      let terms = [];
      switch (name) {
        case 'alcohol_others_concerned':
        case 'alcohol_caused_injury':
          terms = [
            {value: '0', label: 'No', key: '1'},
            {value: '2', label: 'Yes, but not in the last year', key: '2'},
            {value: '4', label: 'Yes, during the last year', key: '3'},
          ];
          break;
        default:
          terms = [
            {value: '0', label: 'Never', key: '1'},
            {value: '1', label: 'Less than monthly', key: '2'},
            {value: '2', label: 'Monthly', key: '3'},
            {value: '3', label: 'Weekly', key: '4'},
            {value: '4', label: 'Daily or almost daily', key: '5'},
          ];
      }

      const answer = terms.find(answer => answer.value === formState.values.assessment[name])
      return answer && answer.label ? answer.label : formState.values.assessment[name];
    }

    switch (name) {
      case 'smoking_type':
        let typeId = formState.values.assessment[name];
        let method = smokingTypes.find(method => method.value === parseInt(typeId));
        return method ? method.label : formState.values.assessment[name];

      case 'smoking_time_years':
      case 'smoking_time_months':
      case 'smoking_vape_time_years':
      case 'smoking_vape_time_months':
        return parseInt(formState.values.assessment[name]);

      case 'motivations':
        let motivations = formState.values.assessment[name];
        let motivationOther = formState.values.assessment['motivation_other'];
        if (typeof motivations === 'object' && motivationOther && !motivations.includes(motivationOther)) {
          motivations.push(motivationOther);
        }

        return motivations;

      default:
        if (formState.values.assessment[name]) {
          if (formState.values.assessment[name].value) {
            return formState.values.assessment[name].value;
          }
          return formState.values.assessment[name];
        }

        return '';
    }
  }

  const pathways = getValue('pathways');

  let selectedPathways = [];

  if(pathways.includes('lose-weight') || (preAssessment.pathways && preAssessment.pathways.weight)) selectedPathways.push(<InfoCard classes='pathway-result' icon='lose-weight' text='Lose Weight' key={1} />);
  if(pathways.includes('move-more') || (preAssessment.pathways && preAssessment.pathways.activity)) selectedPathways.push(<InfoCard classes='pathway-result' icon='move-more' text='Move More' key={2} />);
  if(pathways.includes('drink-less') || (preAssessment.pathways && preAssessment.pathways.alcohol)) selectedPathways.push(<InfoCard classes='pathway-result' icon='drink-less' text='Drink Less' key={3} />);
  if(pathways.includes('quit-smoking') || (preAssessment.pathways && preAssessment.pathways.smoking)) selectedPathways.push(<InfoCard classes='pathway-result' icon='quit-smoking' text='Quit Smoking' key={4} />);

  const getDrinksConsumed = () => {
    const infoCards = [];
    const alcoholDrinks = Object.keys(formState.values.assessment).filter(key => key.substring(0, 17) === 'alcohol_beverage_');

    for (let drink of alcoholDrinks) {
      if (! formState.values.assessment[drink]) continue;
      let cardTitle = `${formState.values.assessment[drink]} ${drink.replace('alcohol_beverage_', '').replaceAll('_', ' ')}`;
      infoCards.push(<InfoCard text={cardTitle} key={infoCards.length} />);
    }

    return infoCards.length > 0 ? (
      <InfoCardGroup cards={infoCards} />
    ) : null;
  }

  return (
    <MainView>
      <ProgressBar min={0} max={totalPages} current={currentPage} label='Quick health check' />
      <div className='pb-28 smd:px-12 smd:pt-8 smd:pb-12'>
        <div className='divide-y-4 divide-primary-100'>
          <div className='px-6 py-8 smd:px-0 smd:pt-0'>
            <Text classes='mb-2' body>{`Step ${currentPage} of ${totalPages}`}</Text>
            <Heading classes='mb-2' h1>Please check you are happy with your answers</Heading>
            <Text body>You are not able to change your answers once you have submitted them.</Text>
          </div>

          {/* goal & motivations */}
          <div className='px-6 py-8 smd:px-0 smd:pb-10'>
            <Heading classes='mb-6' h2>Goals {getValue('motivations') ? '& motivations' : ''}</Heading>

            <Text classes='mt-8 mb-4' body1m>You would like to:</Text>
            <InfoCardGroup horizontal cards={selectedPathways} />

            {getValue('motivations') && [<Text classes='mt-8 mb-4' body1m>Your reasons for wanting to change are:</Text>,
              <InfoCardGroup
                cards={getValue('motivations').map((mv, idx) => (
                  <InfoCard text={mv} key={`motivation-${idx}`} />
                ))}
              />
            ]}

            <Button classes='mt-8 smd:mb-8 w-full justify-center' label='Edit goals & motivations' mode='secondary' onClick={() => skipToPage(1)} />
          </div>

          {/* BMI */}
          <div className='px-6 py-8 smd:px-0 smd:pb-10'>
            <Heading classes='mb-6' h2>BMI</Heading>

            <Text classes='mb-2' body1m>Weight:</Text>
            {getValue('weight_metric') === 'kg' ? (
               <InfoCard text={`${getValue('assessment_weight_kg')}kg`} />
            ):(
              <div className='grid gap-2 md:grid-cols-2'>
                <InfoCard text={`${getValue('assessment_weight_st')}st`} />
                <InfoCard text={`${getValue('assessment_weight_lbs')}lbs`} />
              </div>
            )}
           

            <Text classes='mt-8 mb-2' body1m>Height:</Text>
            <InfoCard text={`${getValue('assessment_height_cm')}cm`} />

            {getValue('ethnicity') && [
              <Text classes='mt-8 mb-2' body1m>Ethnicity:</Text>,
              <InfoCard text={getValue('ethnicity')} />
            ]}

            {preAssessment.gender !== 'Male' && (<div>
              <Text classes='mt-8 mb-2' body1m>Are you currently pregnant?</Text>
              <InfoCard text={getValue('assessment_pregnant')} />
            </div>)}

            <Button classes='mt-8 smd:mb-8 w-full justify-center' label='Edit BMI' mode='secondary' onClick={() => skipToPage(3 + pageOffset)}  />
          </div>

          {/* Activity */}
          <div className='px-6 py-8 smd:px-0 smd:pb-10'>
            <Heading classes='mb-6' h2>Your Activity</Heading>

            <Text classes='mb-2' body1m>On an average day, how long are you moderately active?</Text>
            <InfoCard text={`${getValue('active_hours')} ${getValue('active_hours') !== '1' ? 'Hours' : 'Hour'} ${getValue('active_minutes') ? getValue('active_minutes') : '0'} ${getValue('active_minutes') !== '1' ? 'Minutes' : 'Minute'}`} />

            <Text classes='mt-8 mb-2' body1m>On an average day, how much time do you spend sitting?</Text>
            <InfoCard text={`${getValue('sitting_hours')} ${getValue('sitting_hours') !== '1' ? 'Hours' : 'Hour'} ${getValue('sitting_minutes') ? getValue('sitting_minutes') : '0'} ${getValue('sitting_minutes') !== '1' ? 'Minutes' : 'Minute'}`} />

            <Button classes='mt-8 smd:mb-8 w-full justify-center' label='Edit Activity' mode='secondary' onClick={() => skipToPage(4 + pageOffset)}  />
          </div>

          {/* Smoking */}
          <div className='px-6 py-8 smd:px-0 smd:pb-10'>
            <Heading classes='mb-6' h2>Smoking</Heading>

            <Text classes='mb-2' body1m>Do you smoke or vape?</Text>
            <InfoCard text={getValue('smoking_smoke_or_vape')} />

            {['smoke and vape', 'smoke'].includes(getValue('smoking_smoke_or_vape')) && <div>
              {getValue('smoking_type') && [
                <Text key='smoking_type-0' classes='mt-8 mb-2' body1m>Which of the below do you smoke the most?</Text>,
                <InfoCard key='smoking_type-1' text={getValue('smoking_type')} />
              ]}

              {getValue('smoking_type') && getValue('smoking_type').toLowerCase() === 'other' && getValue('smoking_type_other') && [
                <Text key='smoking_type_other-0' classes='mt-8 mb-2' body1m>What do you smoke?</Text>,
                <InfoCard key='smoking_type_other-1' text={getValue('smoking_type_other')} />
              ]}

              {getValue('smoking_daily') && [
                <Text key='smoking_daily-0' classes='mt-8 mb-2' body1m>Do you smoke daily?</Text>,
                <InfoCard key='smoking_daily-1' text={getValue('smoking_daily')} />
              ]}

              {getValue('smoking_frequency') && [
                <Text key='smoking_frequency-0' classes='mt-8 mb-2' body1m>How often do you smoke?</Text>,
                <InfoCard key='smoking_frequency-1' text={getValue('smoking_frequency')} />
              ]}

              {getValue('smoking_numbers') && [
                <Text key='smoking_numbers-0' classes='mt-8 mb-2' body1m>How many do you smoke a day?</Text>,
                <InfoCard key='smoking_numbers-1' text={getValue('smoking_numbers')} />
              ]}

              {(typeof getValue('smoking_time_years') === 'number' || typeof getValue('smoking_time_months') === 'number') && [
                <Text key='smoking_time-0' classes='mt-8 mb-2' body1m>How long have you been smoking?</Text>,
                <InfoCard key='smoking_time-1' text={`${getValue('smoking_time_years')} ${getValue('smoking_time_years') !== 1 ? 'Years' : 'Year'} ${getValue('smoking_time_months')} ${getValue('smoking_time_months') !== 1 ? 'Months' : 'Month'}`} />
              ]}
            </div>}

            {['smoke and vape', 'vape'].includes(getValue('smoking_smoke_or_vape')) && (<div>
              {getValue('smoking_vape_daily') && [
                <Text key='smoking_vape_daily-0' classes='mt-8 mb-2' body1m>Do you vape daily?</Text>,
                <InfoCard key='smoking_vape_daily-1' text={getValue('smoking_vape_daily')} />
              ]}

              {getValue('smoking_vape_frequency') && [
                <Text key='smoking_vape_frequency-0' classes='mt-8 mb-2' body1m>How often do you vape?</Text>,
                <InfoCard key='smoking_vape_frequency-1' text={getValue('smoking_vape_frequency')} />
              ]}

              {getValue('smoking_vape_nicotine') && [
                <Text key='smoking_vape_nicotine-0' classes='mt-8 mb-2' body1m>What nicotine strength of vape do you use?</Text>,
                <InfoCard key='smoking_vape_nicotine-1' text={getValue('smoking_vape_nicotine')} />
              ]}

              {(typeof getValue('smoking_vape_time_years') === 'number' || typeof getValue('smoking_vape_time_months') === 'number') && [
                <Text key='smoking_vape_time-0' classes='mt-8 mb-2' body1m>How long have you been vaping?</Text>,
                <InfoCard
                  key='smoking_vape_time-1'
                  text={`${getValue('smoking_vape_time_years')} ${getValue('smoking_vape_time_years') !== 1 ? 'Years' : 'Year'} ${getValue('smoking_vape_time_months')} ${getValue('smoking_vape_time_months') !== 1 ? 'Months' : 'Month'}`} />
              ]}
            </div>)}

            <Button classes='mt-8 smd:mb-8 w-full justify-center' label='Edit Smoking' mode='secondary' onClick={() => skipToPage(6 + pageOffset)}  />
          </div>

          {/* Drinking */}
          <div className='px-6 py-8 smd:px-0 smd:pb-0'>
            <Heading classes='mb-6' h2>Drinking</Heading>

            <Text classes='mb-2' body1m>How often do you have a drink containing alcohol?</Text>
            <InfoCard text={getValue('alcohol_drink_frequency')} />

            {getValue('alcohol_units') && [
              <div>
                <Text classes='mt-8 mb-2' body1m>What do you tend to drink on a typical day when you are drinking?</Text>
                <ResultCardBig
                  type="NoResultBar"
                  classes='alcohol-units-card'
                  title={getValue('alcohol_units')}
                  body={`Unit${parseInt(getValue('alcohol_units')) > 1 ? 's' : ''} per day`}
                />
                {getDrinksConsumed()}
                {/* <Text classes='mt-4 text-20 leading-normal tracking-lwd-0.5 text-secondary-900'>Total units: <span className='font-todaysbmed text-26 leading-lwd-1.2 tracking-lwd-0.25'>{getValue('alcohol_units')}</span></Text> */}
              </div>
            ]}

            {getValue('alcohol_how_often') && [
              <Text key='alcohol_how_often-1' classes='mt-8 mb-2' body1m>How often do you have six or more units in one session?</Text>,
              <InfoCard key='alcohol_how_often-2' text={getValue('alcohol_how_often')} />
            ]}

            {getValue('alcohol_unable_to_stop') && [
              <Text key='alcohol_unable_to_stop-1' classes='mt-8 mb-2' body1m>How often during the last year have you found that you were not able to stop drinking once you had started?</Text>,
              <InfoCard key='alcohol_unable_to_stop-2' text={getValue('alcohol_unable_to_stop')} />
            ]}

            {getValue('alcohol_failed_to_do') && [
              <Text key='alcohol_failed_to_do-1' classes='mt-8 mb-2' body1m>How often during the last year have you failed to do what was normally expected from you because of your drinking?</Text>,
              <InfoCard key='alcohol_failed_to_do-2' text={getValue('alcohol_failed_to_do')} />
            ]}

            {getValue('alcohol_morning_drink') && [
              <Text key='alcohol_morning_drink-1' classes='mt-8 mb-2' body1m>'How often during the last year have you needed an alcoholic drink in the morning to get yourself going after a heavy drinking session?</Text>,
              <InfoCard key='alcohol_morning_drink-2' text={getValue('alcohol_morning_drink')} />
            ]}

            {getValue('alcohol_guilt') && [
              <Text key='alcohol_guilt-1' classes='mt-8 mb-2' body1m>How often during the last year have you had a feeling of guilt or remorse after drinking?</Text>,
              <InfoCard key='alcohol_guilt-2' text={getValue('alcohol_guilt')} />
            ]}

            {getValue('alcohol_loss_memory') && [
              <Text key='alcohol_loss_memory-1' classes='mt-8 mb-2' body1m>How often during the last year have you been unable to remember what happened the night before because you had been drinking?</Text>,
              <InfoCard key='alcohol_loss_memory-2' text={getValue('alcohol_loss_memory')} />
            ]}

            {getValue('alcohol_caused_injury') && [
              <Text key='alcohol_caused_injury-1' classes='mt-8 mb-2' body1m>Have you or somebody else been injured as a result of your drinking?</Text>,
              <InfoCard key='alcohol_caused_injury-2' text={getValue('alcohol_caused_injury')} />
            ]}

            {getValue('alcohol_others_concerned') && [
              <Text key='alcohol_others_concerned-1' classes='mt-8 mb-2' body1m>Has a relative or friend, doctor or other health worker been concerned about your drinking or suggested that you cut down?</Text>,
              <InfoCard key='alcohol_others_concerned-2' text={getValue('alcohol_others_concerned')} />
            ]}

            <Button classes='mt-8 smd:mb-8 w-full justify-center' label='Edit Drinking' mode='secondary'  onClick={() => skipToPage(7 + pageOffset)}  />
          </div>
        </div>

        { customButtons || buttons }
      </div>
    </MainView>
  );
};

Summary.propTypes = {
  skipToPage: PropTypes.func,
  buttons: PropTypes.node,
  currentPage: PropTypes.number,
  totalPages: PropTypes.number,
  pageOffset: PropTypes.number,
  customButtons: PropTypes.node,
};

Summary.defaultProps = {
  totalPages: 12,
  currentPage: 12,
  pageOffset: 0,
};