import React, { useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { MainView } from '../../../templates/MainView';
import { Text } from '../../../components/atoms/Text';
import { Heading } from '../../../components/atoms/Heading';
import { AlcoholCard } from '../../../components/molecules/AlcoholCard';
import { AlcoholCardGroup } from '../../../components/molecules/AlcoholCardGroup';
import { ModalView } from '../../../templates/ModalView';
import { ProgressBar } from '../../../components/molecules/ProgressBar';
import { Icon } from '../../../components/atoms/Icon';

import './styles.css';
import { useFormState } from 'react-final-form';

/**
 * Primary UI component for user interaction
 */
export const DrinkingOptions = ({ buttons, currentPage, totalPages }) => {
  const helpModalRef = useRef(null);
  const formState = useFormState();
  const [totalAmount, setTotalAmount] = useState(0);

  const onAmountChange = (increment, newAmount, key) => {
    const amount = Math.round((totalAmount + increment) * 10) / 10;
    setTotalAmount(amount);
    formState.values.assessment['alcohol_units'] = amount;
    formState.values.assessment[`alcohol_beverage_${key}`] = newAmount;
  };

  if (typeof formState.values.assessment['alcohol_units'] !== 'number') formState.values.assessment['alcohol_units'] = 0;

  useEffect(() => {
    if(formState.values.assessment['alcohol_units'] !== totalAmount) setTotalAmount(formState.values.assessment['alcohol_units']);
  }, formState.values.assessment['alcohol_units']);

  return (
    <MainView BPadding2>
      <ProgressBar min={0} max={totalPages} current={currentPage} label='Quick health check' />

      <div className='px-6 pt-8 pb-14 smd:p-12 flex flex-col'>
        <Text classes='mb-2' body>{`Step ${currentPage} of ${totalPages}`}</Text>
        <Heading classes='mb-8' h1>What do you tend to drink on a typical day when you are drinking? <span className='text-error-500'>*</span></Heading>

        <div className='flex flex-col pb-8 space-y-8 smd:space-y-14'>
          <div className='flex flex-col gap-6'>
            <Heading h2>Lager/Beer/Cider</Heading>
            <AlcoholCardGroup alcohols={[
              <AlcoholCard amount={formState.values.assessment[`alcohol_beverage_bottle`] || 0} iconName='beer-bottle' alcoholType='Bottle' alcoholUnitText='330ml, ABV 5%' units={1.7} key='bottle' itemKey='bottle' onAmountChange={onAmountChange} />,
              <AlcoholCard amount={formState.values.assessment[`alcohol_beverage_pint`] || 0} iconName='beer-pint' alcoholType='Pint' alcoholUnitText='568ml, ABV 3.6%' units={2} key='pint' itemKey='pint' onAmountChange={onAmountChange} />,
            ]} />
          </div>
          
          <div className='flex flex-col gap-6'>
            <Heading h2>Red/white/rosé wine</Heading>
            <AlcoholCardGroup alcohols={[
              <AlcoholCard amount={formState.values.assessment[`alcohol_beverage_small_wine_glass`] || 0}  iconName='small-glass-wine' alcoholType='Small glass' alcoholUnitText='125ml, ABV 12%' units={1.5} key='small_wine_glass' itemKey='small_wine_glass' onAmountChange={onAmountChange} />,
              <AlcoholCard amount={formState.values.assessment[`alcohol_beverage_standard_wine_glass`] || 0}  iconName='small-glass-wine' alcoholType='Standard glass' alcoholUnitText='175ml, ABV 12%' units={2.1} key='standard_wine_glass' itemKey='standard_wine_glass' onAmountChange={onAmountChange} />,
              <AlcoholCard amount={formState.values.assessment[`alcohol_beverage_large_wine_glass`] || 0}  iconName='large-glass-wine' alcoholType='Large glass' alcoholUnitText='250ml, ABV 12%' units={3} key='large_wine_glass' itemKey='large_wine_glass' onAmountChange={onAmountChange} />,
              <AlcoholCard amount={formState.values.assessment[`alcohol_beverage_wine_bottle`] || 0}  iconName='wine-bottle' alcoholType='Bottle' alcoholUnitText='750ml, ABV 13.5%' units={10} key='wine_bottle' itemKey='wine_bottle' onAmountChange={onAmountChange} />,
            ]} />
          </div>
          
          <div className='flex flex-col gap-6'>
            <Heading h2>Spirits</Heading>
            <AlcoholCardGroup alcohols={[
              <AlcoholCard amount={formState.values.assessment[`alcohol_beverage_single_shot`] || 0}  iconName='spirits-shot' alcoholType='Single shot' alcoholUnitText='25ml, ABV 40%' units={1} key='single_shot' itemKey='single_shot' onAmountChange={onAmountChange} />,
              <AlcoholCard amount={formState.values.assessment[`alcohol_beverage_700ml_bottle_of_spirit`] || 0}  iconName='small-spirits-bottle' alcoholType='Bottle of spirit' alcoholUnitText='700ml, ABV 40%' units={28} key='700ml_bottle_of_spirit' itemKey='700ml_bottle_of_spirit' onAmountChange={onAmountChange} />,
              <AlcoholCard amount={formState.values.assessment[`alcohol_beverage_1l_bottle_of_spirit`] || 0}  iconName='large-spirits-bottle' alcoholType='Bottle of spirit' alcoholUnitText='1L, ABV 40%' units={40} key='1l_bottle_of_spirit' itemKey='1l_bottle_of_spirit' onAmountChange={onAmountChange} />,
            ]} />
          </div>
        </div>
        
        <div className='sticky-total'>
          <div className='total-amounts'>
            <div className='flex flex-row items-center'>
              <Text body>Total units</Text>
              <div className='help-icon'>
                <button onClick={() => { helpModalRef.current.openModal(); }} type='button'>
                  <Icon name='info-round' />
                </button>
                <ModalView ref={helpModalRef} title='This is a modal subtitle'>
                  <Heading classes='font-todaysbbold mb-2.5 smd:mb-5' h2>This is a modal title</Heading>
                  <Text body2>This is some help text</Text>
                </ModalView>
              </div>
            </div>
            <Text classes='font-todaysbmed text-26 leading-lwd-1.2 tracking-lwd-tight'>{totalAmount}</Text>
          </div>
        </div>
        { buttons }
      </div>
    </MainView>
  );
};

DrinkingOptions.propTypes = {
  buttons: PropTypes.element,
  currentPage: PropTypes.number,
  totalPages: PropTypes.number,
};

DrinkingOptions.defaultProps = {
  buttons: null,
  totalPages: 1,
  currentPage: 1,
};