import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { Form } from 'react-final-form';
import { useNavigate } from 'react-router-dom';

import { Heading } from '../../../components/atoms/Heading';
import { Text } from '../../../components/atoms/Text';
import { Button } from '../../../components/atoms/Button';
import { Field } from '../../../components/molecules/Field';
import { InfoCard } from '../../../components/molecules/InfoCard';
import { MainView } from '../../../templates/MainView';

import logo from '../../../assets/logos/logo-stack.svg';

import email from '../../../components/validation/email';
import { attemptLogin } from '../../../features/login';

/**
 * Primary UI component for user interaction
 */
export const Login = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { alert } = useSelector(state => state.alert);

  const onLoginSubmit = (values) => {
    dispatch(attemptLogin(values.email, values.dob, values.password));
  };

  return (
    <MainView>
      <div className='px-6 py-8 smd:p-12 flex flex-col personal-details'>
        <img className='block h-lwd-70 mb-8 smd:mb-12' src={logo} alt="livewell dorset logo" />

        <div className='grow w-full flex flex-col'>
          <Heading classes="mb-2" h1>Login to MyLiveWell</Heading>
          <Text classes="mb-8" body>Our MyLiveWell platform provides you a personal health and wellbeing plan, and access to our LiveWell coaches and advisors. We're here to support you every step of the way.</Text>
          <Form
            onSubmit={onLoginSubmit}
            render={({ handleSubmit, form, submitting, pristine, values }) => (
              <form onSubmit={handleSubmit} className='w-full flex-grow flex flex-col'>
                <div className='flex flex-col items-start space-y-8 smd:space-y-14'>
                  { alert && <InfoCard text='Invalid email/dob/password' icon='info-round' /> }
                  <Field
                    input='datepicker'
                    name='dob'
                    label='Date of Birth'
                    placeholder='Date of Birth'
                    isRequired
                    limitToAdultAge
                  />
                  <Field
                    input='email'
                    name='email'
                    label='Email Address'
                    placeholder='Enter your email address'
                    isRequired
                    validation={[email]}
                  />
                  <Field
                    input='password'
                    name='password'
                    label='Password'
                    placeholder='Enter your password'
                    isRequired
                  />
                  <div className='w-full flex flex-col sm:flex-row sm:items-center sm:justify-between'>
                    <Button classes='reset-padding mb-4 sm:mb-0' label='Not registered yet?' title='Not registered yet?' mode='underlineBtn' onClick={() => navigate('/register')} />
                    <Button classes='reset-padding' label='Forgotten password' title='Forgotten password' mode='underlineBtn' onClick={() => navigate('/reset-password')} />
                  </div>
                  <Button
                    isSubmit
                    classes='w-full justify-center smd:!mt-12'
                    mode='primary'
                    label='Log in'
                  />
                </div>
              </form>
            )}
          />
        </div>
      </div>
    </MainView>
  );
};

Login.propTypes = {
    buttons: PropTypes.element
};

Login.defaultProps = {
    buttons: null
};
