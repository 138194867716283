import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import { useDispatch } from 'react-redux';
import { getSessions } from '../../../features/sessions';
import { pingUser } from '../../../features/login';
import moment from 'moment';

import { StepCard } from '../../molecules/StepCard';
import { Icon } from '../../atoms/Icon';
import { InfoCard } from '../../molecules/InfoCard';

/**
 * Primary UI component for user interaction
 */
export const NextSteps = ({ ...props }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [hasContactPreferences, setHasContactPreferences] = useState(false);

  const profile = useSelector((state) => state.profile);
  const completedSessions = useSelector(state => state.sessions
    .filter(session => session.status === 'Complete')
    .filter(session => session.due_date && moment().isSameOrAfter(session.due_date))
    .sort((a, b) => new Date(b.due_date) - new Date(a.due_date))
  );

  useEffect(() => {
    dispatch(getSessions());
    dispatch(pingUser());
  }, [dispatch]);

  useEffect(() => {
    for(let preference of Object.keys(profile).filter(key => key.substring(0, 8) === 'contact_')) {
      if (profile[preference] === true && !hasContactPreferences) setHasContactPreferences(true);
    }
  }, [profile]);

  const steps = [];

  const takeAssessmentCard = <StepCard
    key={1}
    stepText='1'
    title='Quick health check'
    body='This check takes just five minutes. It will tell us a little bit more about you, including whether we can help with free weight loss vouchers.'
    btnLabel='Get Started'
    btnOnClick={() => navigate('/assessment')}
  />;

  const assessmentCompletedCard = <StepCard
    key={2}
    stepText={<Icon name='tick-big' />}
    title='Quick health check (Completed)'
    body='Thank you for completing the health check. Now we know more about you, we can begin to make a plan of action.'
  />;

  const viewResultsCard = <StepCard
    key={2}
    stepText={<Icon name='tick-big' />}
    title='Quick health check (Completed)'
    body='Thank you for completing the health check. Now we know more about you, we can begin to make a plan of action.'
    // btnLabel='View Results'
    // btnOnClick={() => navigate('/assessment/results')}
  />;


  // MyLiveWell Plus next steps - must have had an initial call
  if (typeof profile.completed_calls === 'number' && profile.completed_calls > 0) {

    // assessment card - order as step 1 if not completed
    if (profile.assessment_count === 0) steps.push(takeAssessmentCard);

    // chat card
    steps.push(completedSessions.length < 1 ? [
      <StepCard
        key={1}
        stepText={<Icon name='tick-big' />}
        title="Initial chat (Completed)"
        body="You’re getting on track to changing your health and wellbeing habits! We’d love to hear how you found your first session."
        btnLabel='Leave Feedback'
        btnOnClick={() => navigate('/feedback')}
        secBtnLabel='View Chat Notes'
        secBtnOnClick={() => navigate('/actions')}
      />
    ] : [
      <StepCard
        key={1}
        stepText={<Icon name='tick-big' />}
        title={`How was your latest session on ${moment(completedSessions[0].due_date, "YYYY-MM-DD").format('DD/MM/YYYY')}, ${profile.first_name}?`}
        body="You’re getting on track to changing your health and wellbeing habits! We’d love to hear how you found your latest session."
        btnLabel='Leave Feedback'
        btnOnClick={() => navigate('/feedback')}
        secBtnLabel='View Chat Notes'
        secBtnOnClick={() => navigate('/actions')}
      />
    ]);

    // assessment results card - order as step 2 if completed
    if (profile.assessment_count !== 0) steps.push(viewResultsCard);

    return (<div className='steps'>{steps}</div>);
  }

  // standard MyLiveWell next steps
  steps.push(profile.assessment_count === 0 ? [
    takeAssessmentCard,
    <StepCard
      key={2}
      stepText='2'
      title="Let's have a chat"
      body={hasContactPreferences ?
        "We'll be in touch within at your next preferred time. Alternatively, you can get started now!" :
        "We'll be in touch within 48 hours for our first chat. Alternatively, you can get started now!"}
      btnLabel='Have My Chat Now'
      btnOnClick={() => navigate('/book-a-chat')}
      secBtnLabel='How does this work?'
      mode='underlineBtn'
      secBtnOnClick={() => navigate('/what-happens-next')}
    />
  ] : [
    <StepCard
      key={1}
      stepText='1'
      title="Let's have a chat"
      body={hasContactPreferences ?
        "We'll be in touch at your next preferred time - or you can book in yourself right now!" :
        "We'll be in touch within 48 hours for our first chat - or you can book in yourself right now!"}
      btnLabel='Have my chat now'
      btnOnClick={() => navigate('/book-a-chat')}
      secBtnLabel='How does this work?'
      mode='underlineBtn'
      secBtnOnClick={() => navigate('/what-happens-next')}
    />,
    assessmentCompletedCard
  ]);

  return (
    <div>
      <InfoCard
        classes='mb-4'
        text={hasContactPreferences ?
            "We'll be in touch at your next preferred time. Here's what you can do now to get started." :
            "We'll be in touch in the next 48 hours. Here's what you can do now to get started."}
        icon='timer'
      />
      <div className='steps'>{steps}</div>
    </div>
  );
};

NextSteps.propTypes = {

};

NextSteps.defaultProps = {

};

