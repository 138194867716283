import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import ReactDatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import moment from 'moment';

import './styles.css';

const isWeekday = (date) => {
  const day = date.getDay();
  return day !== 0 && day !== 6;
};

export const FlatDatepicker = (props) => {
  const {
    setMinDate, setMaxDate, setSelectedDate, value, onChange, ...attributes
  } = props;
  const transferredDate = moment(value);

  const excludedDates = [];
  const [startDate, setStartDate] = useState((transferredDate && transferredDate.isValid()) ? new Date(transferredDate) : null);

  useEffect(() => {
    if (onChange) {
      onChange(startDate);
    }
  }, [startDate]);

  return (
    <ReactDatePicker
      id='date-picker'
      selected={startDate}
      onChange={(date) => {
        setStartDate(date);
        setSelectedDate(moment(date).format('YYYY-MM-DD'));
      }}
      inline
      excludeDates={excludedDates}
      minDate={setMinDate ?? moment().toDate()}
      maxDate={setMaxDate ?? null}
      filterDate={isWeekday}
      {...attributes}
    />
  );
};

FlatDatepicker.propTypes = {
  onChange: PropTypes.func,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.instanceOf(Date),
  ]),
};

FlatDatepicker.defaultProps = {
  value: null,
  onChange: null,
};