import React from 'react';
import PropTypes from 'prop-types';
import './styles.css';
import { Icon } from '../../atoms/Icon';
import { Text } from '../../atoms/Text';
import { Heading } from '../../atoms/Heading';

/**
 * Primary UI component for user interaction
 */
export const InfoCard = ({ icon, text, largeText, childrenComponent, classes, setInnerHtml, neutral }) => (
  <div className={[
    'info-card-container',
    icon === 'form-error' && 'infor-card-error',
    neutral && 'info-card-neutral',
    classes && classes
    ].join(' ')}
  >
    {icon && <Icon name={icon} />}
    <div className='info-card-contents'>
      {(!text && childrenComponent) && childrenComponent}
      {text && !setInnerHtml && <Text body>{text}</Text>}
      {setInnerHtml && <div className='body' dangerouslySetInnerHTML={{__html: text}}></div>}
      {largeText && <Heading h2>{largeText}</Heading>}
    </div>
  </div>
);

InfoCard.propTypes = {
   /**
   * The icon to show on the Info Card
   */
    icon: PropTypes.string,
  
  /**
   * Many info card text
   */
  text: PropTypes.string,
  largeText: PropTypes.string,
  classes: PropTypes.string,
  childrenComponent: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  setInnerHtml: PropTypes.bool,
  neutral: PropTypes.bool
};

InfoCard.defaultProps = {
  text: null,
  setInnerHtml: false,
  neutral: false
};
