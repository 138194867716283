import React from 'react';
import PropTypes from 'prop-types';
import { useFormState } from 'react-final-form';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { MainView } from '../../../templates/MainView';
import { Field } from '../../../components/molecules/Field';
import { Text } from '../../../components/atoms/Text';
import { Heading } from '../../../components/atoms/Heading';
import { Icon } from '../../../components/atoms/Icon';

// import './styles.css';

/**
 * Primary UI component for user interaction
 */
export const SelectATime = ({ buttons, customButtons }) => {
  const formState = useFormState('selectATime');
  const dayOptions = [
    {label: 'Monday', value: 'mon', name:'contact-mon', key: 'mon'},
    {label: 'Tuesday', value: 'tue', name:'contact-tue', key: 'tue'},
    {label: 'Wednesday', value: 'wed', name:'contact-wed', key: 'wed'},
    {label: 'Thursday', value: 'thu', name:'contact-thu', key: 'thu'},
    {label: 'Friday', value: 'fri', name:'contact-fri', key: 'fri'},
  ];

  const timeOptions = [
    {label:
      [ <div className='flex flex-col'>
          <span>Morning</span>
          <span>09:00 - 12:00</span>
        </div> ],
      value: 'morning', name:'contact-morning', key: 'morning'},
    {label:
      [ <div className='flex flex-col'>
          <span>Lunch time</span>
          <span>12:00 - 14:00</span>
        </div> ],
      value: 'lunch', name:'contact-lunch', key: 'lunch'},
    {label:
      [ <div className='flex flex-col'>
          <span>Afternoon</span>
          <span>14:00 - 17:00</span>
        </div> ],
      value: 'afternoon', name:'contact-afternoon', key: 'afternoon'},
    {label:
      [ <div className='flex flex-col'>
          <span>Early evening</span>
          <span>17:00 - 18:30</span>
        </div> ],
      value: 'evening', name:'contact-evening', key: 'evening'},
  ];

  const location = useLocation();
  const navigate = useNavigate();

  const fromHome = location.state && location.state.fromHome;

  return (
    <MainView BPadding1>
      <div className='title-container'>
        <div onClick={()=>{
          if(fromHome){
            navigate('/');
          }else {
              navigate(-1);
          }
        }}>
        <Text classes="text-lwd-grey-900 flex flex-row items-center" body1m>
          <Icon name='chevron-purple-left' />
          <Text classes='ml-3' to='/'>{fromHome ?'Back to home':'Back'}</Text>
        </Text>
        </div>
      </div>

      <div className='px-6 pt-8 pb-32 smd:p-12'>
        <Heading classes='mb-2' h1>When are you free for us to contact you?</Heading>
        <Text classes='mb-8' body>If you would like to wait for us to get in touch, please choose the days and times that are easiest for you. We'll try our best to contact you at those times.</Text>

        <div className='flex flex-col space-y-8 smd:space-y-14'>
          <Field
            name='days'
            input='checkboxgroup'
            label='What days of the week are you likely to be available?'
            options={dayOptions}
            isRequired
          />

          <Field
            name='times'
            input='checkboxgroup'
            label='What times of day are you likely to be available?'
            options={timeOptions}
            isRequired
            // modaltitle='Contact times'
            // help='Please select the times you are most likely to be available on each day.'
          />
        </div>

        { customButtons(formState) || buttons }
      </div>
    </MainView>
  );
};

SelectATime.propTypes = {
    buttons: PropTypes.element
};

SelectATime.defaultProps = {
    buttons: null
};
