import moment from 'moment';

import api from '../lib/api';

export const LOGIN = 'LOGIN';
export const LOGGEDIN = 'LOGGEDIN';
export const LOGOUT = 'LOGOUT';

/** actions */

export const forgotPassword = ({email, dob}, cb) => (dispatch) => {
  const data = {
      dob: moment(dob).format('YYYY-MM-DD'),
      email
  };

    api.forgot(data, cb);
};
