import React from 'react';
import PropTypes from 'prop-types';
import { Heading } from '../../../components/atoms/Heading';
import { Text } from '../../../components/atoms/Text';
import { Button } from '../../../components/atoms/Button';

import logo from '../../../assets/logos/logo-stack.svg';

import './styles.css';

/**
 * Primary UI component for user interaction
 */
export const Responses = (props) => {
  const { title, description, buttonLabel, secondButtonLabel, onClick, onSecondBtnClick } = props;

  return (
    <div className='full-page response-page w-screen h-screen flex flex-col items-center p-10'>
      <img className='block h-lwd-70 mb-10 smd:md-lwd-30' src={logo} alt="livewell dorset logo" />

      <div className='grow w-full flex flex-col items-center justify-center'>
        <Heading classes='text-lwd-grey-900 mb-2.5' h1>{title}</Heading>
        <Text classes='mb-5' body2>{description}</Text>
        {buttonLabel &&
          <Button label={buttonLabel} title={buttonLabel} mode="secondary" key={2} onClick={onClick} />
        }
        {secondButtonLabel &&
          <Button label={secondButtonLabel} title={secondButtonLabel} mode="plainBtn" key={3} onClick={onSecondBtnClick} />
        }
      </div>
    </div>
  );
};

Responses.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  onClick: PropTypes.func,
};

Responses.defaultProps = {
  onClick: () => {},
};

