import React from 'react';

import { Text } from '../../atoms/Text';
import { Button } from '../../atoms/Button';
import './styles.css';

/**
 * Primary UI component for user interaction
 */
export const ContactNav = ({ data, ...props }) => {
  return (
    <div className="contact-nav">
      <div className="contact-nav-left">
        <Text nav link='https://www.livewelldorset.co.uk/about-livewell/' newTab>Meet the Team</Text>
        <Text nav link='https://www.livewelldorset.co.uk/commercial-training/' newTab>Workplace Wellbeing</Text>
        <Text nav link='https://www.livewelldorset.co.uk/engage-with-livewell/' newTab>Community Wellbeing</Text>
        <Text nav link='https://www.livewelldorset.co.uk/healthcare-professionals/' newTab>Partner Information</Text>
      </div>
    
      <div className="contact-nav-right">
        <Text nav>
          <span className='hidden xl:inline'>0800 840 1628 • </span>
          Monday - Friday 9am - 6:30pm
        </Text>
        <Button label='Contact Us' onClick={() => window.location = 'https://www.livewelldorset.co.uk/contact/'} mode='underlineBtn' classes="text-20" />
      </div>
    </div>
  );
};

ContactNav.propTypes = {

};

ContactNav.defaultProps = {
};
