import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from "react-router-dom";
import { configureStore } from '@reduxjs/toolkit';
import { Provider } from 'react-redux';
import storage from 'reduxjs-toolkit-persist/lib/storage/session' // defaults to localStorage for web


import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import reducer from './features/';
import api from './lib/api';

const store = configureStore({
  reducer,
  storage
});
api.dispatch = store.dispatch;

ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter>
      <Provider store={store}>
        <App />
      </Provider>
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
reportWebVitals(console.log)
