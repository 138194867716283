import React from 'react';
import PropTypes from 'prop-types';
import { Button } from '../../atoms/Button';
import { Text } from '../../atoms/Text';
import './styles.css';
import { Heading } from '../../atoms/Heading';

/**
 * Primary UI component for user interaction
 */
export const StepCard = ({ stepText, title, body, bodyHtml, classes, btnLabel, btnClass, btnOnClick, secBtnLabel, secBtnClass, secBtnOnClick, mode, ...props }) => {
  return (
    <div className={['step-card shadow-card w-full rounded-xl', classes].join(' ')}>
        <div className='step-body p-8 flex justify-between items-center flex-col smd:flex-row text-center'>
            {stepText && <Text classes='mb-6 mx-auto smd:mx-0 smd:self-start smd:shrink-0 smd:mr-6' step>{stepText}</Text>}
            <div className='text-center smd:text-left smd:mr-lwd-62'>
              <Heading h3>{title}</Heading>
              {body && <Text classes='mt-2 mb-6 smd:mb-0' body>{body}</Text>}
              {bodyHtml}
            </div>
            {(btnLabel || secBtnLabel) && (<div className='flex flex-col text-left smd:shrink-0 w-full smd:w-[222px]'>
              {btnLabel && <Button label={btnLabel} onClick={btnOnClick} mode='primary' classes={'w-full justify-center shrink-0 ' + btnClass} />}
              {secBtnLabel && <Button label={secBtnLabel} onClick={secBtnOnClick} mode={mode} classes={'mt-4 ml-auto mr-auto !p-0' + secBtnClass } />}
            </div>)}
        </div>
    </div>
  );
};

StepCard.propTypes = {
  /**
   * Step text of the card
   */
  stepText: PropTypes.string,

  /**
   * Title of the card
   */
  title: PropTypes.string.isRequired,

  /**
   * Main body text
   */
  body: PropTypes.string,

  /**
   * Optional body markup
   */
  bodyHtml: PropTypes.element,

   /**
   * Optional Button contents
   */
  btnLabel: PropTypes.string,

   /**
   * Optional Button classes
   */
  btnClass: PropTypes.string,

  /**
   * Optional Button click handler
   */
  btnOnClick: PropTypes.func,

  /**
   * Optional Button 2 contents
   */
   secBtnLabel: PropTypes.string,

  /**
   * Optional Button 2 click handler
   */
   secBtnOnClick: PropTypes.func,

   /**
    * Optional Button 2 classes
    */
   secBtnClass: PropTypes.string,
};

StepCard.defaultProps = {
  stepText: null,
  title: null,
  body: null,
  bodyHtml: null,
  btnLabel: undefined,
  btnOnClick: undefined,
  btnClass: '',
  secBtnLabel: undefined,
  secBtnOnClick: undefined,
  secBtnClass: '',
};

