import React from 'react';
import PropTypes from 'prop-types';
import './styles.css';
import { Text } from '../../atoms/Text';

/**
 * Primary UI component for user interaction
 */
export const MessageBubble = ({ isCoach, isLatest, author, date, message, classes }) => (
  <div className={[
    'message-bubble-card',
    isCoach ? 'message-from-coach' : 'message-from-user',
    isLatest && 'latest-message',
    classes && classes
  ].join(' ')}>
    <div className='message-bubble-contents'>
      <div className='bubble-title'>
        <span>{author} said</span>
        <time>{date}</time>
      </div>
      <div className='message-content'>
        <Text body2>{message}</Text>  
      </div>
    </div>
  </div>
);

MessageBubble.propTypes = {
   /**
   * The icon to show on the Info Card
   */
    icon: PropTypes.string,
  
  /**
   * Many info card text
   */
  text: PropTypes.string,
  largeText: PropTypes.string,
  classes: PropTypes.string,
  childrenComponent: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
};

MessageBubble.defaultProps = {
  text: null,
};
