// import { useEffect } from 'react';
// import { useDispatch, useSelector } from 'react-redux';

// import { getDropdowns } from '../features/dropdowns';
/**
 * Primary UI component for user interaction
 */
export const analytics = (event, data={}) => {
  const obj = data;
  obj.event = event;
  if(window.dataLayer) window.dataLayer.push(obj);

  console.log('gtm', obj);
  return null;
};
