import React, { useState } from 'react';
import PropTypes from 'prop-types';
import './styles.css';

/**
 * Primary UI component for user interaction
 */
export const Switch = ({ labels, selectedIndex, onChange, classes }) => {
  const [selected, setSelected] = useState(selectedIndex); 

  const onClickHandler = (idx) => {
    setSelected(idx);
    onChange && onChange(idx);
  }

  return (
    <div className={['switch-buttons', classes && classes].join(' ')}>
      {labels.map((labelText, i) => (
        <button
          key={`switch${i}`}
          type='button'
          onClick={() => { onClickHandler(i); }}
          className={['switch-button', selected === i && 'selected-switch'].join(' ')}
        >
          {labelText}
        </button>
      ))}
    </div>
  );
};

Switch.propTypes = {
  labels: PropTypes.array.isRequired, 
  selectedIndex: PropTypes.oneOf([0, 1]),
  onChange: PropTypes.func, 
  classes: PropTypes.string
};

Switch.defaultProps = {
  selectedIndex: 0,
  onChange: () => {},
};
