import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';

import { Heading } from '../../components/atoms/Heading';
import { Button } from '../../components/atoms/Button';
import { MainView } from '../../templates/MainView';
import { Goal } from '../../components/molecules/Goal';
import { GoalGroup } from '../../components/molecules/GoalGroup';
import { getPlan } from '../../features/plan';
import { getSessions } from '../../features/sessions';
import { Text } from '../../components/atoms/Text';
import { Icon } from '../../components/atoms/Icon';

import './styles.css';

export const MyLiveWellView = ({ children, innerClasses, classes }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [showPlusWelcomePanel, setShowPlusWelcomePanel] = useState(true);
  const [welcomePanelDismissed, setWelcomePanelDismissed] = useState(localStorage.getItem('mlw-plus-welcome-dismissed'));

  const plan = useSelector(state => state.plan);
  const profile = useSelector(state => state.profile);
  // const nextSession = useSelector(state => state.sessions.filter(session => session.status.toLowerCase() === 'due').sort((a, b) => new Date(a.due_date) - new Date(b.due_date)).shift());
  const completedSessions = useSelector(state => state.sessions.filter(session => session.status === 'Complete').sort((a, b) => new Date(b.due_date) - new Date(a.due_date)));
  useEffect(() => {
    dispatch(getPlan());
    dispatch(getSessions());
  }, [dispatch]);

  const [goals, setGoals] = useState([
    <Goal text='Add Goal' image='add-goal' key='updateGoals' />
  ]);

  useEffect(() => {
    const newGoals = [];

    if(plan.pathways && plan.pathways.weight) newGoals.push(<Goal text='Lose Weight' image='lose-weight' key={1} />);
    if(plan.pathways && plan.pathways.smoking) newGoals.push(<Goal text='Quit Smoking' image='quit-smoking' key={2} />);
    if(plan.pathways && plan.pathways.alcohol) newGoals.push(<Goal text='Drink Less' image='drink-less' key={3} />);
    if(plan.pathways && plan.pathways.activity) newGoals.push(<Goal text='Move More' image='move-more' key={4} />);

    if (plan.pathways && newGoals.length < 4) newGoals.push(<Goal text='Add Goal' image='add-goal' key='updateGoals' onClick={() => navigate('/update-goals')} />);
    setGoals(newGoals);
  }, [navigate, plan]);

  useEffect(() => {
    // show LWD Plus welcome on initial call completion
    setShowPlusWelcomePanel(
      typeof profile.completed_calls === 'number' && profile.completed_calls > 0 && completedSessions.length < 1
    );
  }, [profile, completedSessions]);

  const dismissWelcomePanel = () => {
    localStorage.setItem('mlw-plus-welcome-dismissed', false);
    setWelcomePanelDismissed(true);
  };

  return [
    <MainView key={0} type='fullwidth' innerClasses={innerClasses} classes={classes} showFooter>
      { showPlusWelcomePanel && (<div className='py-8 px-6 smd:p-12 max-w-lwd-860 mx-auto'>
        <div className='close-plus-icon' onClick={() => setShowPlusWelcomePanel(false)}><Icon name='exit-button' /></div>
        <Heading classes="text-primary-500 pb-lwd-15" h2>Welcome to MyLiveWell Plus!</Heading>
        <Text body>You’ve been granted access to MyLiveWell Plus, which has unlocked some new features in your account. Open the menu to see what’s new and take a look around!</Text>
      </div>)}
      <div className='py-8 px-6 smd:p-12 bg-primary-700'>
        <div className="max-w-lwd-860 mx-auto">
          <Heading classes="text-lwd-grey-100 pb-lwd-15" h1>{`Welcome${profile.first_name ? ', ' + profile.first_name : ''}`}</Heading>

          <div className='flex justify-between items-center mb-6'>
            <Heading classes="text-lwd-grey-100" h2>You want to</Heading>
          </div>

          <GoalGroup goals={goals}
          />
        </div>
      </div>
      <div className='flex flex-col'>
        {children}
      </div>
    </MainView>
  ];
};

MyLiveWellView.propTypes = {
  children: PropTypes.oneOfType([PropTypes.object, PropTypes.array]).isRequired,
  classes: PropTypes.string,
  innerClasses: PropTypes.string,
};

MyLiveWellView.defaultProps = {
  classes: null,
};

