import moment from 'moment';

export const isAgeOrOver = (dob, age) => {
  const format = 'YYYY-MM-DD';
  const dobDate = moment(dob, format, true);
  const maxDate = moment(new Date(), format, true);

  const isValidDate = dobDate.isValid() && parseInt(dobDate.year()) > 0;
  const isValidDoB = dobDate.diff(maxDate) < 0;
  const isInRange = maxDate.diff(dobDate, 'years') >= age;

  return isValidDate && isValidDoB && isInRange;
}

export const isAdult = (birthDate) => {
  return isAgeOrOver(birthDate, 18);
};