import api from "../lib/api";

export const SET_SESSIONS = 'SET_SESSIONS';
export const CLEAR_SESSIONS = 'CLEAR_SESSIONS';

/** actions */

export const getSessions = () => (dispatch) => {
  api.sessions((err, responseJSON) => {
      if(responseJSON && responseJSON.data) {
        console.log('getSessions',responseJSON);
        return dispatch(setSessions(responseJSON.data.data));
      }

      console.error("Unable to find sessions");
  });
};
/** reschedule */
export const rescheduleSessions =  (id, date) => (dispatch) => {
    console.log('SENT DATE', date);
    api.rescheduleSession(id, date,(err, responseJSON) => {
        if(responseJSON && responseJSON.data) {
            console.log('get rescheduled Sessions',responseJSON);
            return dispatch(setSessions(responseJSON.data.data));
        }

        console.error("Unable to find sessions");
    });
};

/** session rescheduling */
export const reschedule = (data) => (dispatch) => {
    api.rescheduleSession(data, (err, responseJSON) => {
        if(responseJSON && responseJSON.data) {
            console.log(responseJSON)
            //  return dispatch(setTimes(responseJSON.data));

        }
        console.error("Unable to reschedule session", responseJSON);
    });
};



export const cancelSession = (sessionId) => (dispatch) => {
  console.log('cancelSession', sessionId);
  api.cancelSession(sessionId, (err, responseJSON) => {
      // if(responseJSON && responseJSON.data) {
        return dispatch(getSessions());
      // }

      // console.error("Unable to find plan");
  });
};



/** actions */
export const setSessions = (payload) => ({
  type: SET_SESSIONS,
  payload,
});

export const clearSessions = () => ({
  type: CLEAR_SESSIONS,
});


/** reducer */
const initialState = [];


export default function reducer(state = initialState, action) {
    const { type, payload } = action;

    switch (type) {
    case SET_SESSIONS:
        return payload;

    case CLEAR_SESSIONS:
        return initialState;

    default:
        return state;
    }
}
