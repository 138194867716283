import api from "../lib/api";

export const SET_NOTIFICATIONS = 'SET_NOTIFICATIONS';
export const CLEAR_NOTIFICATIONS = 'CLEAR_NOTIFICATIONS';

/** actions */

export const getNotifications = () => (dispatch) => {
  api.notifications((err, responseJSON) => {
      if(responseJSON && responseJSON.data) {
        console.log('getNotifications',responseJSON);
        return dispatch(setNotifications(responseJSON.data.data));
      }

      console.error("Unable to find notifications");
  });
};

export const updateNotification = (id) => (dispatch) => {
  api.updateNotification(id, (err, responseJSON) => {
    dispatch(getNotifications());
  });
};

/** actions */
export const setNotifications = (payload) => ({
  type: SET_NOTIFICATIONS,
  payload,
});

export const clearNotifications = () => ({
  type: CLEAR_NOTIFICATIONS,
});


/** reducer */
const initialState = [];


export default function reducer(state = initialState, action) {
    const { type, payload } = action;

    switch (type) {
    case SET_NOTIFICATIONS:
        return payload;

    case CLEAR_NOTIFICATIONS:
        return initialState;

    default:
        return state;
    }
}
