import api from "../lib/api";

export const SET_PLAN = 'SET_PLAN';
export const CLEAR_PLAN = 'CLEAR_PLAN';

/** actions */

export const getPlan = () => (dispatch) => {
  api.plan((err, responseJSON) => {
      if(responseJSON && responseJSON.data) {
        return dispatch(setPlan(responseJSON.data.data));
      }

      console.error("Unable to find plan");
  });
};

export const updateBCTFeeling = (bctId, score) => (dispatch) => {
  console.log('updateBCTFeeling',{bctId, score});
  api.updateBCT({bctId, score}, (err, responseJSON) => {
      // if(responseJSON && responseJSON.data) {
        return dispatch(getPlan());
      // }

      // console.error("Unable to find plan");
  });
};

/** actions */
export const setPlan = (payload) => ({
  type: SET_PLAN,
  payload,
});

export const clearPlan = () => ({
  type: CLEAR_PLAN,
});


/** reducer */
const initialState = {};


export default function reducer(state = initialState, action) {
    const { type, payload } = action;

    switch (type) {
    case SET_PLAN:
        return payload;

    case CLEAR_PLAN:
        return initialState;

    default:
        return state;
    }
}
