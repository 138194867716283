import React from 'react';
import PropTypes from 'prop-types';
import { MainView } from '../../../templates/MainView';
import { Text } from '../../../components/atoms/Text';
import { Heading } from '../../../components/atoms/Heading';
import { Field } from '../../../components/molecules/Field';
import { ProgressBar } from '../../../components/molecules/ProgressBar';
import { InfoCard } from '../../../components/molecules/InfoCard';

/**
 * Primary UI component for user interaction
 */
export const DrinkingQuestion = ({ buttons, step, stepText, title, titleWithStar, description, cardDescription, radioGroupArray }) => {
  return (
    <MainView BPadding2>
      <ProgressBar min={1} max={13} current={step} label='Quick health check' />

      <div className='px-6 pt-8 pb-14 smd:p-12 flex flex-col'>
        <Text classes='mb-2' body>{stepText}</Text>
        <Heading classes={description ? 'mb-2' : 'mb-8'} h1>
          {title}
          {titleWithStar && <span className='text-error-500'> *</span>}
        </Heading>
        {description &&
          <Text classes='mb-8' body>{description}</Text>
        }

        {cardDescription &&
          <InfoCard classes='mb-4' text={cardDescription} icon='info-round' />
        }

        <div className='flex flex-col space-y-8 smd:space-y-14 pb-10 smd:pb-0'>
          {radioGroupArray && radioGroupArray.map((item, key) => (
            <Field
              key={item.key}
              input='radio'
              name={item.name}
              label={item.title}
              options={item.options}
              isRequired
            />
          ))}
        </div>
        
        { buttons }
      </div>
    </MainView>
  );
};

DrinkingQuestion.propTypes = {
  buttons: PropTypes.element,
  step: PropTypes.number.isRequired,
  stepText: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.string,
  cardDescription: PropTypes.string,
  radioGroupArray: PropTypes.array,
  titleWithStar: PropTypes.bool
};

DrinkingQuestion.defaultProps = {
  buttons: null,
  description: '',
  cardDescription: '',
  radioTitle: '',
  titleWithStar: true,
  radioGroupArray: [],
};