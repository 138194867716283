import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button } from '../../../components/atoms/Button';
import { ButtonGroup } from '../../../components/molecules/ButtonGroup';
import { StickyButtons } from '../../../components/molecules/StickyButtons';
import { ButtonCard } from '../../../components/molecules/ButtonCard';

import { MultiForm } from '../../../components/organisms/MultiForm';
import { ThankYou } from '../../ThankYou';
import { UpdateContactMethod } from '../../RegistrationFlow/UpdateContactMethod';
import { Icon } from '../../../components/atoms/Icon';
import { Text } from '../../../components/atoms/Text';
import { useDropDown } from '../../../lib/useDropdown';
import api from '../../../lib/api';

import './styles.css';
import { PreferenceItem } from '../../../components/molecules/PreferenceItem';
import { useSelector } from 'react-redux';

/**
 * Primary UI component for user interaction
 */
export const UpdateContactMethodForm = () => {
  const navigate = useNavigate();
  const profile = useSelector((state) => state.profile);
  const contactMethods = useDropDown('PreferredContact') || [];
  const [methodId, setMethodId] = useState(0);

  const getContactMethod = () => {
    const method = contactMethods.find(option => methodId && (option.value === parseInt(methodId)));
    return method ? method.label : '';
  };

  useEffect(() => {
    if (! profile.preferred_method) return;

    const selectedMethod = contactMethods.find(option => (option.label === profile.preferred_method));
    if (! selectedMethod) return;

    setMethodId(selectedMethod.value);
  }, [profile, contactMethods]);

  return (
    <MultiForm
      name="UpdateContactMethodForm"
      onSubmit={() => {}}
      hideButtons={false}
      initialHistory={[0]}
      initialValues={{contact_method: methodId}}
    >
      <MultiForm.Page next={(values, step, nextPage) => {
        if (! values.contact_method) return;

        api.updateMethodPreference(values, (err, res) => {
          if(err) {
            console.log(err);
            return;
          }

          if (res.data !== 'ok') {
            console.log(res.data);
            return;
          }
          setMethodId(values.contact_method);
          nextPage(values, step);
        });
      }}>
        <UpdateContactMethod customButtons={
          <StickyButtons>
            <ButtonGroup isStacked classes='mx-auto md:pb-10' buttons={[
              <Button label="Confirm My Preferences" mode="primary" key={2} isSubmit />
            ]} />
          </StickyButtons>
        }/>
      </MultiForm.Page>

      <MultiForm.Page next={(values, step, nextPage) => {
          nextPage(values, step -2);
        }}>
        <ThankYou
          imageSrc=''
          hideImage
          title="Thank you, we've updated your contact preferences!"
          description="We'll try our very best to contact you via your chosen contact method. If you change your mind, you can go back and change your contact preferences at any time!"
          buttonLabel='Back To Home'
          onClick={() => navigate('/')}
          highlightedAction={[
            <ButtonCard
              key='contact-chosen-card'
              classes='mx-auto mt-5 md:max-w-lwd-600 bg-lwd-grey-100'
              title='Your contact preferences:'
              icon='phone'
              btnIsSubmit={true}
              btnLabel='Change preference'
              btnMode='secondary'
              body={
                <PreferenceItem
                icon='phone'
                classes='mt-5'
                title={getContactMethod()}
              />}
              footer={<div className='mt-2'>
                <Text classes='text-center text-lwd-grey-900'>Please note that updating your time & day preferences will overwrite your existing selection.</Text>
              </div>}
            />
          ]}
        />
      </MultiForm.Page>

      <MultiForm.Page></MultiForm.Page>
    </MultiForm>
  );
};

UpdateContactMethodForm.propTypes = {
};

UpdateContactMethodForm.defaultProps = {
};

