export const SET_ALERT = 'SET_Alert';
export const CLEAR_ALERT = 'CLEAR_Alert';

/** actions */
export const setAlert = (alert) => ({
    type: SET_ALERT,
    payload: alert,
});

export const clearAlert = () => ({
    type: CLEAR_ALERT,
});



/** reducer */
const initialState = {};

export default function reducer(state = initialState, action) {
    const { type, payload } = action;

    switch (type) {
    case SET_ALERT:
        return { alert: payload };

    case CLEAR_ALERT:
        return { alert: '' };

    default:
        return state;
    }
}
