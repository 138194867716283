import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';

import { Heading } from '../../atoms/Heading';
import { Text } from '../../atoms/Text';
import { Button } from '../../atoms/Button';
import { Icon } from '../../atoms/Icon';
import { ModalView } from '../../../templates/ModalView';

import { updateAction, getActions } from '../../../features/actions';

import './styles.css';

export const ActionItem = ({ id, title, date, description, isCompleted, classes }) => {
  const dispatch = useDispatch();

  const actionOverviewModal = useRef(null);
  const actionCompleteModal = useRef(null);

  const textEllipsis = (text) => {
    // *** change the value to adjust how many characters that you want to show
    return text.substring(0, 77) + "...";
  };

  const onToggleActionComplete = (status) => {
    dispatch(updateAction(id, status));
    actionOverviewModal.current.closeModal();
    if(status === 'completed') {
      actionCompleteModal.current.openModal();
    }
    dispatch(getActions());
  };

  const ctaClick = () => onToggleActionComplete(isCompleted ? 'incomplete' : 'complete');

  return [
    <div key={0} className={[
      'action-card-item',
      classes && classes
    ].join(' ')}>
      {isCompleted &&
        (<div className='flex flex-row items-center mb-lwd-5'>
          <Icon name='small-tick' />
          <Text classes='ml-lwd-5 uppercase font-todaysbbold' body2>Completed</Text>
        </div>)
      }
      <Heading classes='font-todaysbbold mb-lwd-5' h3>{title}</Heading>
      <Text classes='text-lwd-grey-900 mb-lwd-5' body>{date ? `Due date: ${date}` : "No due date"}</Text>
      <Text classes='text-lwd-grey-400 action-description' body2>
        {textEllipsis(description)}
        <Button mode='plainBtn' onClick={() => { actionOverviewModal.current.openModal(); }} label='read more' />
      </Text>
      <div className='action-button-container mt-lwd-15'>
        <Button
          mode='secondary'
          label={isCompleted ? 'Mark as incomplete' : 'Mark as complete'}
          isIcon={!isCompleted}
          iconComponent={<Icon name='small-tick' />}
          onClick={ctaClick}
        />
      </div>
    </div>,
    <ModalView key={1} ref={actionOverviewModal} title='Action overview'>
      <Heading classes='text-primary-500 font-todaysbbold mb-2.5 smd:mb-5' h2>{title}</Heading>
      <Text classes='mb-2.5 smd:mb-5' body2>{description}</Text>
      <Text classes='mb-lwd-5 uppercase font-todaysbbold' body2>Due date</Text>
      <Text classes='mb-5 smd:mb-lwd-30' body2>{date}</Text>
      <Button
        mode='secondary'
        label={isCompleted ? 'Mark as incomplete' : 'Mark as complete'}
        isIcon={!isCompleted}
        iconComponent={<Icon name='small-tick' />}
        onClick={ctaClick}
      />
    </ModalView>,
    <ModalView key={2} ref={actionCompleteModal} title='Action complete'>
      <div className='flex flex-col items-center pb-5'>
        <Heading classes='font-todaysbbold mb-2.5' h1>Great job!</Heading>
        <Text classes='mb-2.5' body>You’ve completed:</Text>
        <Heading classes='text-primary-500 font-todaysbbold mb-5 smd:mb-lwd-30' h3>{title}</Heading>
        <Button
          mode='primary'
          label='Back to my actions'
          onClick={() => {
            actionCompleteModal.current.closeModal();
          }}
        />
      </div>
    </ModalView>
  ];
}

ActionItem.propTypes = {
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  title: PropTypes.string,
  date: PropTypes.string,
  description: PropTypes.string,
  isCompleted: PropTypes.bool,
  classes: PropTypes.string,
};

ActionItem.defaultProps = {
  isCompleted: false
};