import React from 'react';
import { useLocation, useNavigate } from 'react-router';
import PropTypes from 'prop-types';

import { MainView } from '../../../templates/MainView';
import { Image } from '../../../components/atoms/Image';
import { InfoCard } from '../../../components/molecules/InfoCard';
import { HeadingBlock } from '../../../components/molecules/HeadingBlock';

import mailbox from '../../../assets/images/registation/mailbox.svg'

import './styles.css';

/**
 * Primary UI component for user interaction
 */
export const VerificationEmail = ({ buttons, customButtons }) => {
  const navigate = useNavigate();
  const location = useLocation();

  const redirectToHome = () => {
    navigate('/');
    return (<MainView></MainView>);
  };

  if (! (location.state && location.state.first_name && location.state.email && location.state.dob)) return redirectToHome();

  const heading = location.state && location.state.someone_else === 'for-someone-else' ?
    `We've just sent ${location.state.first_name} an email` :
    `${location.state.first_name}, we've just sent you an email`;

  const directions = location.state && location.state.someone_else === 'for-someone-else' ?
    `Open the email and click the link. ${location.state.first_name} will be asked to set-up a password. Then their LiveWell account will be ready! Please allow a few minutes for the email to arrive.` :
    'Open the email and click the link. You will be asked to set-up a password. Then your LiveWell account will be ready! Please allow a few minutes for the email to arrive.';

  return (
    <MainView>
        <div className='px-6 py-8 smd:p-12 flex flex-col gap-8'>
          <div className='verification-email-img'>
            <Image square src={mailbox} alt='mailbox image' containerClasses='container-max-h-none' />
          </div>

          <HeadingBlock
            heading={heading}
            text={directions}
          />

          <InfoCard
            text={`You can close this tab once ${location.state.someone_else === 'for-someone-else' ? `${location.state.first_name} has set-up their` : 'you have set-up your'} password.`}
            icon='info-round'
          />

          { customButtons || buttons }
        </div>
    </MainView>
  );
};

VerificationEmail.propTypes = {
  buttons: PropTypes.element,
  customButtons: PropTypes.element,
};

VerificationEmail.defaultProps = {
  buttons: null,
  customButtons: null,
};

