import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import api from '../../../lib/api';

import { MultiForm } from '../../../components/organisms/MultiForm';
import { GettingStarted } from '../../RegistrationFlow/GettingStarted';
import { BasicDetails } from '../../RegistrationFlow/BasicDetails';
import { EligibilityCheck } from '../../RegistrationFlow/EligibilityCheck';
import { PersonalDetails } from '../../RegistrationFlow/PersonalDetails';
import { SignupBehalf } from '../../RegistrationFlow/SignupBehalf';
import { Summary } from '../../RegistrationFlow/Summary';

import { isDorsetPostcode } from '../../../components/validation/postcode';
import { isAdult } from '../../../components/validation/age';
import { StickyButtons } from '../../../components/molecules/StickyButtons';
import { ButtonGroup } from '../../../components/molecules/ButtonGroup';
import { Button } from '../../../components/atoms/Button';

import './styles.css';

/**
 * Primary UI component for user interaction
 */
export const Registration = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const { loggedIn } = useSelector((state) => state.login);
  if (loggedIn) navigate('/');

  let startingHistory = [0];
  if (location.state && location.state.startingHistory && location.state.startingVals) {
    startingHistory = location.state.startingHistory;
    localStorage.setItem(`registration-values`, JSON.stringify(location.state.startingVals));
    localStorage.setItem(`registration-history`, JSON.stringify(startingHistory));
  }

  const onSubmit = (values) => {
    // only submit field values (ignore labels)
    for (let name of Object.keys(values.client)) {
      if (values.client[name] && values.client[name].value) {
        values.client[name] = values.client[name].value;
      }
    }

    const qs = new URLSearchParams(window.location.search);

    if (qs.get('hash')) values.hash = qs.get('hash');

    api.newClient(values, (err, res) => {
      if(err) {
        console.error(err);
        return;
      }

      if (res.data !== 'ok') {
        console.error(res.data);
        return;
      }

      navigate('/register/complete', {
        state: {
          first_name: values.client.first_name,
          someone_else: values.client.someone_else,
          email: values.client.email,
          dob: values.client.dob,
        }
      });
    });
  };

  return (
    <MultiForm
      name="registration"
      initialHistory={startingHistory}
      onSubmit={onSubmit}
      alwaysUseButtonRows={true}
    >
      <MultiForm.Page>
        <GettingStarted customButtons={
          <StickyButtons classes='md-sticky-pt-0'>
            <ButtonGroup isStacked buttons={[
              <Button label="Get Started" mode="primary" key={1} isSubmit />
            ]} />
          </StickyButtons>
        } />
      </MultiForm.Page>

      <MultiForm.Page next={(values, step, nextPage) => {
        const valuesToCheck = {
          email: values.client.email,
          dob: values.client.dob,
        };

         api.eligibility(valuesToCheck, (err, res) => {
          if(err) {
            console.error(err);
            return;
          }

          if(res.data.exists) {
            navigate('/eligibility/email-exists');
            return;
          }

          if (! isAdult(values.client.dob)) {
            navigate('/eligibility/too-young');
            return;
          }

          if (! isDorsetPostcode(values.client.postcode)) {
            navigate('/eligibility/not-in-dorset', {
              state: {
                registrationValues: values
              }
            });
            return;
          }

          nextPage(values, step);
        });
      }} >
        <EligibilityCheck />
      </MultiForm.Page>

      <MultiForm.Page next={(values, step, nextPage) => {
        const valuesToCheck = {
          first_name: values.client.first_name,
          last_name: values.client.last_name,
          postcode: values.client.postcode,
        };

        api.eligibility(valuesToCheck, (err, res) => {
          if(err) {
            console.error(err);
            return;
          }

          if(res.data.exists) {
            navigate('/eligibility/email-exists');
            return;
          }

          nextPage(values, step);
        });
      }} >
        <BasicDetails />
      </MultiForm.Page>

      <MultiForm.Page next={(values, step, nextPage) => {
        if (values.client.someone_else === 'for-someone-else') {
          nextPage(values, step); // SignupBehalf
          return;
        }
        nextPage(values, step + 1); // Summary
      }}>
        <PersonalDetails />
      </MultiForm.Page>

      <MultiForm.Page>
        <SignupBehalf />
      </MultiForm.Page>

      <MultiForm.Page>
        <Summary customButtons={
          <StickyButtons classes='md-sticky-pt-0'>
            <ButtonGroup isStacked buttons={[
              <Button label="Submit" mode="primary" key={1} isSubmit />
            ]} />
          </StickyButtons>
        } />
      </MultiForm.Page>

    </MultiForm>
  );
};

Registration.propTypes = {
};

Registration.defaultProps = {
};

