export const SET_ELIGIBILITY = 'SET_ELIGIBILITY';
export const CLEAR_ELIGIBILITY = 'CLEAR_ELIGIBILITY';

/** dates */

export const checkEligibility = (fields) => (dispatch) => {

  console.log('checkEligibility',fields);
  // api.dates(date, (err, responseJSON) => {
  //     if(responseJSON && responseJSON.data) {
  //       return dispatch(setEligibility(responseJSON.data));
  //     }

  //     console.error("Unable to find dates");
  // });
};

/** dates */
export const setEligibility = (payload) => ({
  type: SET_ELIGIBILITY,
  payload,
});

export const clearEligibility = () => ({
  type: CLEAR_ELIGIBILITY,
});


/** reducer */
const initialState = false;


export default function reducer(state = initialState, action) {
    const { type, payload } = action;

    switch (type) {
    case SET_ELIGIBILITY:
        return payload;

    case CLEAR_ELIGIBILITY:
        return initialState;

    default:
        return state;
    }
}
