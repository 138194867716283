import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import { useDispatch } from 'react-redux';
import { getSessions } from '../../../features/sessions';
import { pingUser } from '../../../features/login';
import PropTypes from 'prop-types';

import { Button } from '../../atoms/Button';
import { Image } from '../../atoms/Image';
import { HeadingBlock } from '../../molecules/HeadingBlock';

/**
 * Primary UI component for user interaction
 */
export const ImageCtaSection = ({ containerClasses, innerClasses, imageContainerClasses, contentClasses, heading, content, btnLabel, btnClasses, btnOnClick, image, imageAlt, ...props }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const profile = useSelector((state) => state.profile);
  const completedSessions = useSelector(state => state.sessions.filter(session => session.status === 'Complete').sort((a, b) => new Date(b.due_date) - new Date(a.due_date)));

  useEffect(() => {
    dispatch(getSessions());
    dispatch(pingUser());
  }, [dispatch]);


  return (
    <div className={['py-8 px-6 smd:p-12', containerClasses].join(' ')}>
        <div className='max-w-lwd-860 mx-auto'>
          <div className={["max-w-lwd-860 mx-auto flex flex-col smd:flex-row-reverse smd:gap-x-28 smd:items-center", innerClasses].join( )}>
            {image && <Image
              src={image}
              alt={imageAlt}
              containerClasses={['yoga-image-container flex justify-center', imageContainerClasses].join(' ')}
              imgClass='object-contain'
            />}

            <div className={contentClasses}>
              {(heading || content) && (
                <HeadingBlock
                  classes="mb-6"
                  heading={heading}
                  text={content}
                  mode="h2"
                />
              )}

              {btnLabel && <Button
                label={btnLabel}
                onClick={btnOnClick}
                mode='primary'
                classes={['w-full smd:w-auto justify-center', btnClasses].join(' ')}
              />}
            </div>
          </div>
        </div>
      </div>
  );
};

ImageCtaSection.propTypes = {
  containerClasses: PropTypes.string,
  imageContainerClasses: PropTypes.string,
  innerClasses: PropTypes.string,
  contentClasses: PropTypes.string,
  heading: PropTypes.string,
  content: PropTypes.string,
  btnLabel: PropTypes.string,
  btnClasses: PropTypes.string,
  btnOnClick: PropTypes.func,
  image: PropTypes.string,
  imageAlt: PropTypes.string,
};

ImageCtaSection.defaultProps = {

};

