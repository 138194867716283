import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { MainView } from '../../../templates/MainView';
import { Heading } from '../../../components/atoms/Heading';
import { Field } from '../../../components/molecules/Field';

import email from '../../../components/validation/email';
import { attemptLogin } from '../../../features/login';
import { useNavigate } from 'react-router';
import { InfoCard } from '../../../components/molecules/InfoCard';

/**
 * Primary UI component for user interaction
 */
export const EmailAlreadyExists = ({ buttons, customButtons, children }) => {
  const { alert } = useSelector(state => state.alert);

  return (
    <MainView BPadding1>
      <div className='px-6 py-8 smd:p-12 flex flex-col gap-8'>
        <Heading h1>It looks like you already have an account with us - please log in below</Heading>

        { alert && <InfoCard text='Invalid email/dob/password' icon='info-round' /> }

        <Field
          input='datepicker'
          name='dob'
          label='Date of Birth'
          placeholder='Date of Birth'
          isRequired
          limitToAdultAge
        />

        <Field
          input='email'
          name='email'
          label='Email Address'
          placeholder='Enter your email address'
          modalsubtitle='What is this?'
          modaltitle='Email Address'
          help='Please enter the email address you used to register with us.'
          isRequired
          validation={[email]}
        />

        <Field
          input='password'
          name='password'
          label='Password'
          placeholder='Enter your password'
          modalsubtitle='What is this?'
          modaltitle='Password'
          help='Please enter the password you created when you registered with us.'
          isRequired
        />

        { customButtons || buttons }
      </div>
    </MainView>
  );
};

EmailAlreadyExists.propTypes = {
  buttons: PropTypes.node,
  customButtons: PropTypes.node,
};

EmailAlreadyExists.defaultProps = {
};

