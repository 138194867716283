import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";

import './styles.css';

/**
 * Primary UI component for user interaction
 */
export const TabView = ({ tabTitles, tabContents }) => {
  const [tabIndex, setTabIndex] = useState(0);

  return (
    <Tabs selectedIndex={tabIndex} onSelect={index => setTabIndex(index)}>
      <TabList>
        {tabTitles.map((titleText, idx) => (
          <Tab key={`tab-${idx}`}>{titleText}</Tab>
        ))}
      </TabList>

      {tabContents.map((tabContent, idx) => (
        <TabPanel key={`tab-content-${idx}`}>{tabContent}</TabPanel>
      ))}
    </Tabs>
  );
};

TabView.propTypes = {
  tabTitles: PropTypes.oneOfType([PropTypes.object, PropTypes.array]).isRequired,
  tabContents: PropTypes.oneOfType([PropTypes.object, PropTypes.array]).isRequired,
};

TabView.defaultProps = {
};

