import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';

import { MyLiveWellView } from '../../../templates/MyLiveWellView';
import { Heading } from '../../../components/atoms/Heading';
import { Text } from '../../../components/atoms/Text';
import { SessionItem } from '../../../components/molecules/SessionItem';

export const Sessions = ({ buttons }) => {
  const [upcoming, setUpcoming] = useState([]);
  const [previous, setPrevious] = useState([]);

  const sessions = useSelector(state => state.sessions);

  useEffect(() => {
    if (!sessions.length) return;

    const smDue = [];
    const smPrev = [];

    sessions.forEach((act) => {
      if (act.status.toLowerCase() === "due") {
        smDue.push(act);
      } else {
        smPrev.push(act);
      }
    });

    setUpcoming(smDue);
    setPrevious(smPrev);
  }, [sessions]);

  return (
    <MyLiveWellView>
      <div className='max-w-lwd-860 w-full mx-auto px-6 py-8 smd:py-12 lg:px-0 flex flex-col gap-8'>
        <div>
          <Heading classes='text-lwd-grey-900' h2>Sessions</Heading>
          <Text classes="mt-2.5" body2>Your upcoming and previous sessions can be found here.</Text>
        </div>

        <div className='session-container upcoming-sessions'>
          <Heading classes='mb-5 font-todaysbbold' h2>Upcoming sessions</Heading>
          {upcoming.length === 0 ? (
            <Text classes='font-todaysbbold' body>No upcoming session yet.</Text>
          ) : (
            <div role="list" className='session-list'>
                {upcoming.map((session, idx) => (
                  <SessionItem
                    key={`upcoming${idx}`}
                    session={session}
                  />
                ))}
            </div>
          )}
        </div>
        <div className='session-container previous-sessions'>
          <Heading classes='mb-5 font-todaysbbold' h2>Previous sessions</Heading>
          {previous.length === 0 ? (
            <Text classes='font-todaysbbold' body>No previous session yet.</Text>
          ) : (
            <div role="list" className='session-list'>
                {previous.map((session, idx) => (
                  <SessionItem
                    key={`prev${idx}`}
                    session={session}
                  />
                ))}
            </div>
          )}
        </div>
      </div>
    </MyLiveWellView>
  );
};

Sessions.propTypes = {
  buttons: PropTypes.element,

};

Sessions.defaultProps = {
  buttons: null,
};