import React from 'react';
import PropTypes from 'prop-types';
import './styles.css';

/**
 * Primary UI component for user interaction
 */
export const ResultBar = ({ min, max, current, type, invert }) => {
  if (current > max) current = max;
  if (current < min) current = min;
  let position = Math.abs((current - min) / (max - min) * 100);

  if (invert === true) position = 100 - position;

  return (
    <div className='result-bar-container'>
      <div className="result-bar">
        <div className={ type === "oneThird" ? "result-bar-warning-one-third" : "result-bar-warning" }></div>
        <div className={ type === "oneThird" ? "result-bar-safe-one-third" : "result-bar-safe" }></div>
        <div className='indicator' style={{left: `${position}%`}}>
          <svg width="14" height="35" viewBox="0 0 14 35" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M6 24.7143L6 10.2857L0 0H14L8 10.2857L8 24.7143L14 35H0L6 24.7143Z" fill="black"/>
          </svg>
        </div>
      </div>
    </div>
    
  );
};

ResultBar.propTypes = {
   /**
   * Minimum number of steps
   */
  min: PropTypes.number,

  /**
   * Maximum number of steps
   */
  max: PropTypes.number.isRequired,

  /**
   * Current number of steps
   */
  current: PropTypes.number.isRequired,

  /**
   * Current number of steps
   */
  type: PropTypes.oneOf(['oneThird', null]),

  /**
   * Invert the positive result direction
   */
   invert: PropTypes.bool,
};

ResultBar.defaultProps = {
  min: 1,
  max: 5,
  current: 1,
  type: null,
  invert: false,
};
