import React from 'react';
import PropTypes from 'prop-types';
import { MainView } from '../../../templates/MainView';
import { Heading } from '../../../components/atoms/Heading';
import { Field } from '../../../components/molecules/Field';
import { Text } from '../../../components/atoms/Text';


/**
 * Primary UI component for user interaction
 */
export const NotInDorset = ({ buttons, customButtons }) => {
    const radiosData = [
        {label: 'My GP is in Dorset', value: 'gp in Dorset', key: '1'},
        {label: 'I work as a health professional in Dorset', value: 'health professional in Dorset', key: '2'},
        {label: 'Neither of these apply to me', value: 'neither', key: '3'},
    ];

    return (
        <MainView BPadding1>
          <div className='title-container'>
            <Text body1m='true'>MyLiveWell Registration</Text>
          </div>

          <div className='px-6 pt-8 pb-14 smd:p-12 flex flex-col'>
            <div className='flex flex-col space-y-8'>
              <Heading h1>You don't live in Dorset - but we might still be able to help</Heading>

              <Field
                  input='radio'
                  name='not_in_dorset'
                  label='Please select all that apply:'
                  inputOptions={{
                  options: radiosData
                  }}
                  isRequired
              />
            </div>

            { customButtons || buttons }
          </div>
        </MainView>
    );
};

NotInDorset.propTypes = {
  buttons: PropTypes.node,
  customButtons: PropTypes.node,
};

NotInDorset.defaultProps = {
};

