import React from 'react';
import PropTypes from 'prop-types';
import { MainView } from '../../../templates/MainView';
import { Text } from '../../../components/atoms/Text';
import { Heading } from '../../../components/atoms/Heading';
import { Field } from '../../../components/molecules/Field';
import { InfoCard } from '../../../components/molecules/InfoCard';
import { Icon } from '../../../components/atoms/Icon';

import email from '../../../components/validation/email';

/**
 * Primary UI component for user interaction
 */
export const EmailHandler = ({ buttons, notRecieved }) => {

  return (
    <MainView BPadding1>
      <div className='title-container'>
        <Text classes="font-todaysbmed text-lwd-grey-900 flex flex-row items-center" body1m nav link='/'>
          <Icon name='chevron-purple-left' />
          <span className='ml-3'>Back to home</span>
        </Text>
      </div>

      <div className={['px-6', 'pt-8', 'smd:p-12', notRecieved ? 'pb-44' : 'pb-28'].join(' ')}>
        <Heading classes="mb-8" h1>Please check your email address is correct</Heading>
        <InfoCard
          classes='justify-center text-center'
          text='Verification email was sent to:'
          largeText="jane@gmail.com"
        />

        {notRecieved && <div className='pt-6'>
          <Text body>The verification email was sent to the email address above, please ensure it is correct before trying to resend.</Text>
          { buttons }
        </div>}

        {!notRecieved &&
          <div className='pt-6'>
            <Field
              input='email'
              name='email'
              label='New Email Address'
              placeholder='e.g. joebloggs@gmail.com'
              isRequired
              validation={[email]}
              errorcomponent={[
                <Text key={0} body>It looks like you already have an account with this email address!</Text>,
                <a href="/" title='Login Now' key={1} className='underline'>Login Now</a>
              ]}
            />
            { buttons }
          </div>
        }
      </div>
    </MainView>
  );
};

EmailHandler.propTypes = {
  buttons: PropTypes.node,
  notRecieved: PropTypes.bool
};

EmailHandler.defaultProps = {
};

