import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { pingUser } from '../../../features/login';

import { MultiForm } from '../../../components/organisms/MultiForm';

import { Results } from '../../AssessmentFlow/Results';
import { ThankYou } from '../../ThankYou';
import { Icon } from '../../../components/atoms/Icon';
import { Heading } from '../../../components/atoms/Heading';
import { Text } from '../../../components/atoms/Text';

import './styles.css';

/**
 * Assessment results flow form.
 */
export const AssessmentResultsForm = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { loggedIn } = useSelector((state) => state.login);

  return (
    <MultiForm
      name="assessmentCompleteForm"
      hideButtons={true}
      initialHistory={[0]}
      onSubmit={() => {}}
    >
      <MultiForm.Page next={(values, step, nextPage) => {
        dispatch(pingUser());
        nextPage(values, step);
      }}>
        <Results redirectIfInvalid={true} />
      </MultiForm.Page>


      <MultiForm.Page>
        <ThankYou
          imageSrc=''
          hideImage
          title="Thank you for completing the health check!"
          description="Completing the assessment is a great first step in your journey to achieving your goals. Here is what you can do next."
          isStackedBtns
          buttonLabel='Have Your Chat Now'
          onClick={() => navigate(!loggedIn ? '/' : '/book-a-chat', {
            state: {
              assessmentCompleted: true,
            }
          })}
          secondBtnLabel='Back To Dashboard'
          secondBtnOnClick={() => navigate('/')}
          highlightedAction={[
            <div className='flex flex-col gap-6 mt-10 mx-3'>
              <div className='flex flex-row gap-3'>
                <div className='tick-rounded filled'>
                  <Icon name='tick' />
                </div>
                <div className='item-contents'>
                  <Heading h3>Quick health check</Heading>
                  <Text body>Now we know more about you, we can begin to make a plan of action.</Text>
                </div>
              </div>
              <div className='flex flex-row gap-3'>
                <div className='tick-rounded'></div>
                <div className='item-contents'>
                  <Heading h3>Next steps</Heading>
                  <Text body>Let's book in a chat to go through the plan and see how we can support you.</Text>
                </div>
              </div>
            </div>
          ]}
        />
      </MultiForm.Page>

    </MultiForm>
  );
};


AssessmentResultsForm.propTypes = {
};

AssessmentResultsForm.defaultProps = {
};
