import React from 'react';
import PropTypes from 'prop-types';
import { MainView } from '../../../templates/MainView';
import { Heading } from '../../../components/atoms/Heading';
import { Text } from '../../../components/atoms/Text';
import { ProgressBar } from '../../../components/molecules/ProgressBar';
import { Field } from '../../../components/molecules/Field';

import './styles.css';

/**
 * Primary UI component for user interaction
 */
export const BasicDetails = ({ buttons }) => {
  return (
    <MainView BPadding1>
      <div className='title-container'>
        <Text body1m='true'>MyLiveWell Registration</Text>
        <ProgressBar  max={3} current={2} type='buttons' />
      </div>

      <div className='px-6 pt-8 pb-14 smd:p-12 flex flex-col basic-details'>
        <div className='flex flex-col space-y-8'>
        <ProgressBar max={3} current={2} type='buttons' />
          <Heading h1>Thank you, that's great. Let's get started!</Heading>

          <Field
            input='text'
            name='client[first_name]'
            label='First Name'
            placeholder='First Name'
            isRequired
          />

          <Field
            input='text'
            name='client[last_name]'
            label='Last Name'
            placeholder='Last Name'
            isRequired
          />

          <div className='inner-box'>
            <Heading h2>What would you like to do?</Heading>

            <Field
              input='multiselect'
              name='client[pathways]'
              label='Please select all that apply:'
              isRequired
              inputOptions={{
                options:[
                  { value: 'lose-weight', label: 'Lose Weight', icon: 'lose-weight' },
                  { value: 'move-more', label: 'Move More', icon: 'move-more' },
                  { value: 'drink-less', label: 'Drink Less', icon: 'drink-less' },
                  { value: 'quit-smoking', label: 'Quit Smoking', icon: 'quit-smoking' },
                ]
              }}
            />
          </div>
        </div>
        

        { buttons }
      </div>
    </MainView>
  );
};

BasicDetails.propTypes = {
  buttons: PropTypes.element,

};

BasicDetails.defaultProps = {
  buttons: null,
};

