import React from 'react';
import PropTypes from 'prop-types';
import './styles.css';

/**
 * Primary UI component for user interaction
 */
export const Checkbox = ({ type, label, isRequired, value, ...props }) => (
  <div className='control-container'>
    <input type={ type } id={ label } {...props} checked={value} />
    {label && <label htmlFor={ label }>{props.labelForDisplay || label}{ isRequired && <em>*</em> }</label>}
  </div>
);

Checkbox.propTypes = {
  /**
   * What is the type?
   */
  type: PropTypes.oneOf(['checkbox', 'radio']),
  /**
   * What is the label name?
   */
  label: PropTypes.string,
  labelForDisplay: PropTypes.string,
  /**
   * Should this input be disabled?
   */
  disabled: PropTypes.bool,
};

Checkbox.defaultProps = {
  disabled: false,
  type: 'checkbox',
};
