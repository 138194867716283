import React, { useRef, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';

import { Text } from '../../atoms/Text';
import { Icon } from '../../atoms/Icon';
import { ModalView } from '../../../templates/ModalView';

import './styles.css';
import { getNotifications, updateNotification } from '../../../features/notifications';

export const Notifications = ({ classes }) => {
  const notificationModalRef = useRef(null);
  const notificationContainerRef = useRef(null);
  const [click, setClick] = useState(false);

  const totalUnreadNotifications = useSelector(
    (state) => state.notifications.filter((r) => ! r.seen).length
  );
  const notifications = useSelector((state) => state.notifications);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleHideDropdown = (event) => {
    if (event.key === 'Escape') {
      notificationModalRef.current.closeModal();
    }
  };

  const handleClickOutside = (event) => {
    if (notificationContainerRef.current && !notificationContainerRef.current.contains(event.target)) {
      notificationModalRef.current.closeModal();
    }
  };

  const fireNotification = (obj) => {
    console.log("fire", obj);

    // mark as read
    dispatch(updateNotification(obj.id));

    // redirect user
    let newLink = false;
    switch (obj.object) {
      case "Task":
      case "Action":
        newLink = "/actions";
        break;
      case "Message":
        newLink = "/messages";
        break;
      default:
    }

    if (newLink) navigate(newLink);
    setClick(!click);
    notificationModalRef.current.closeModal();
  };

  useEffect(() => {
    dispatch(getNotifications());
    document.addEventListener('keydown', handleHideDropdown, true);
    document.addEventListener('click', handleClickOutside, true);
    return () => {
      document.removeEventListener('keydown', handleHideDropdown, true);
      document.removeEventListener('click', handleClickOutside, true);
    };
  }, [dispatch]);

  return <>
    <div ref={notificationContainerRef} key={0} id='notificationContainer' className={[
      'notification-item',
      classes && classes
    ].join(' ')}>
      <button
        type='button'
        className='notification-button'
        title='Notification icon button'
        onClick={() => { notificationModalRef.current.toggleModal(); }}
      >
        {totalUnreadNotifications > 0 && <span className='notification-count'>{ totalUnreadNotifications }</span> }
        <Icon name='dark-bell' />
      </button>
    </div>
    <ModalView
      key={1}
      ref={notificationModalRef}
      title='Notifcations'
      classes='notification-modal'
      overlayClasses='notification-overlay'
      parentSelector={() => document.querySelector('#notificationContainer')}
    >
      <ul className='notification-list'>
        {notifications.length > 0 ? (
            notifications.map((obj, idx) => {
              return (
                  <li key={idx} className={`notification-list-item ${obj.seen === false && 'new'}`}>
                    <div className='cursor-pointer' to="" onClick={() => fireNotification(obj)}>
                      <p className='m-0 text-12 font-todaysbbold uppercase leading-lwd-1.4 tacking-lwd-0.4 text-lwd-grey-900'>
                        {! obj.seen && <span className='text-notification'>new</span>}
                        {! obj.seen && <span> - </span>}
                        {obj.created_at}
                      </p>
                      <Text classes='text-lwd-grey-400' body2>{obj.object} Added</Text>
                    </div>
                  </li>
              );
            })
          ) : (
            <li>You have no messages.</li>
          )}
      </ul>
    </ModalView>
  </>;
}

Notifications.propTypes = {
  classes: PropTypes.string,
};

Notifications.defaultProps = {
};