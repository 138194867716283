import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Select from 'react-select'

// import './styles.css';

/**
 * Primary UI component for user interaction
 */
export const DatePicker = ({ disabled, meta, onChange, value, limitToAdultAge, ...attributes }) => {
  const days = [
    { value: 1, label: '1' },
    { value: 2, label: '2' },
    { value: 3, label: '3' },
    { value: 4, label: '4' },
    { value: 5, label: '5' },
    { value: 6, label: '6' },
    { value: 7, label: '7' },
    { value: 8, label: '8' },
    { value: 9, label: '9' },
    { value: 10, label: '10' },
    { value: 11, label: '11' },
    { value: 12, label: '12' },
    { value: 13, label: '13' },
    { value: 14, label: '14' },
    { value: 15, label: '15' },
    { value: 16, label: '16' },
    { value: 17, label: '17' },
    { value: 18, label: '18' },
    { value: 19, label: '19' },
    { value: 20, label: '20' },
    { value: 21, label: '21' },
    { value: 22, label: '22' },
    { value: 23, label: '23' },
    { value: 24, label: '24' },
    { value: 25, label: '25' },
    { value: 26, label: '26' },
    { value: 27, label: '27' },
    { value: 28, label: '28' },
    { value: 29, label: '29' },
    { value: 30, label: '30' },
    { value: 31, label: '31' }
  ];
  const months = [
    { value: 1, label: '01' },
    { value: 2, label: '02' },
    { value: 3, label: '03' },
    { value: 4, label: '04' },
    { value: 5, label: '05' },
    { value: 6, label: '06' },
    { value: 7, label: '07' },
    { value: 8, label: '08' },
    { value: 9, label: '09' },
    { value: 10, label: '10' },
    { value: 11, label: '11' },
    { value: 12, label: '12' },
  ];
  const years = [];
  let maxYear = new Date().getFullYear();
  if (limitToAdultAge) maxYear = maxYear - 18;
  for(let i=100; i>= 0; i--) {
    years.push({ value: maxYear-i, label: maxYear-i });
  }

  let selectedDay = 0;
  let selectedMonth = 0;
  let selectedYear = 0;

  if(value) {
    const [year, month, day] = value.split('-');
    selectedYear = years.find(y => y.value === parseInt(year, 10));
    selectedMonth = months.find(m => m.value === parseInt(month, 10));
    selectedDay = days.find(d => d.value === parseInt(day, 10));
  }

  const [pickerDate, setPickerDate] = useState({
    day: selectedDay,
    month: selectedMonth,
    year: selectedYear
  });

  const hasError = meta && meta.error && meta.touched;

  const formatDate = (date) => {
    const dd = String(date.getDate()).padStart(2, '0');
    const mm = String(date.getMonth() + 1).padStart(2, '0'); //January is 0!
    const yyyy = date.getFullYear();
    return `${yyyy}-${mm}-${dd}`;
  }

  useEffect(() => {
    if(pickerDate.year && pickerDate.month && pickerDate.day) {
      if (onChange) {
        onChange(formatDate(new Date(pickerDate.year.value, pickerDate.month.value - 1, pickerDate.day.value)));
      }
    }
  }, [pickerDate]);

  return (
    <div className='flex flex-col xs:flex-row gap-4 w-full'>
      <Select
        options={days}
        onChange={obj => {
          setPickerDate(prevPickerDate => ({...prevPickerDate, day: obj }));
        }}
        classNamePrefix="react-select"
        name='day'
        isDisabled={disabled}
        isSearchable={false}
        placeholder='Day'
        className={['react-select', (pickerDate.day !== 0) && 'has-selected', hasError && 'error'].join(' ')}
        value={pickerDate.day}
        {...attributes}
      />
      <Select
        options={months}
        onChange={obj => {
          setPickerDate(prevPickerDate => ({...prevPickerDate, month: obj }));
        }}
        classNamePrefix="react-select"
        name='month'
        isDisabled={disabled}
        isSearchable={false}
        placeholder='Month'
        className={['react-select', (pickerDate.month !== 0) && 'has-selected', hasError && 'error'].join(' ')}
        value={pickerDate.month}
        {...attributes}
      />
      <Select
        options={years.reverse()}
        onChange={obj => {
          setPickerDate(prevPickerDate => ({...prevPickerDate, year: obj }));
        }}
        classNamePrefix="react-select"
        name='year'
        isDisabled={disabled}
        isSearchable={false}
        placeholder='Year'
        className={['react-select', (pickerDate.year !== 0) && 'has-selected', hasError && 'error'].join(' ')}
        value={pickerDate.year}
        {...attributes}
      />
    </div>
  );
};

DatePicker.propTypes = {
  meta: PropTypes.object,
  disabled: PropTypes.bool,
  onChange: PropTypes.func,
  value: PropTypes.string,
  limitToAdultAge: PropTypes.bool,
};

DatePicker.defaultProps = {
  disabled: false,
  value: null,
  onChange: null,
  limitToAdultAge: false,
};