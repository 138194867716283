import React, { useRef, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Form } from 'react-final-form';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import api from '../../../lib/api';

import { MyLiveWellView } from '../../../templates/MyLiveWellView';
import { Heading } from '../../../components/atoms/Heading';
import { Text } from '../../../components/atoms/Text';
import { Field } from '../../../components/molecules/Field';
import { Button } from '../../../components/atoms/Button';
import { Icon } from '../../../components/atoms/Icon';
import { InfoCard } from '../../../components/molecules/InfoCard';

export const Help = () => {
  const navigate = useNavigate();
  const [showMessageError, setShowMessageError] = useState(false);

  const textareaRef = useRef();
  const plan = useSelector(state => state.plan);

  useEffect(() => {
    if (textareaRef.current) textareaRef.current.focus();
  }, []);

  const onSubmit = (values) => {
    if (! (values && values.message)) return;

    setShowMessageError(false);

    api.submitHelpMessage(values, (err, res) => {
      if (err) {
        console.log(err);
        return;
      }

      if (res.data !== 'ok') {
        setShowMessageError(true);
        return;
      }

      navigate('/message-received');
    });
  };

  return (
    <MyLiveWellView>
      <div className='max-w-lwd-860 w-full mx-auto px-6 py-8 smd:py-12 lg:px-0 flex flex-col gap-5 smd:gap-8'>
        <div>
          <Heading classes='text-lwd-grey-900' h2>Need more help?</Heading>
          <Text classes="mt-2.5" body2>We are on hand to help you as much as we can. Get in touch via phone or leave us a message.</Text>
        </div>
        <div className='help-section'>
          <Heading classes="text-lwd-grey-900 font-todaysbbold mb-2.5" h3>Call us</Heading>
          <Text body2>01305 233 105</Text>
        </div>

        <div className='help-section'>
          <Heading classes="text-lwd-grey-900 font-todaysbbold mb-2.5" h3>Send us a message</Heading>
          {!showMessageError ? (
            <Form
              onSubmit={onSubmit}
              render={({ handleSubmit, form, submitting, pristine, values }) => (
                <form onSubmit={handleSubmit} className='w-full flex-grow flex flex-col items-start'>
                  <Field
                    ref={textareaRef}
                    input='textarea'
                    name='message'
                    label='Message'
                    hideLabel
                    placeholder='e.g. I am really struggling this week.'
                    rows='4'
                    isRequired
                  />
                  <Button
                    isSubmit
                    classes='mt-2.5'
                    mode='primary'
                    label='Send message'
                    isIcon
                    iconComponent={<Icon name='chevron-white-right' />}
                  />
                </form>
            )} />
          ) : (
            <InfoCard
              icon='form-error'
              childrenComponent={(
                <div>
                  <Text>Something went wrong while sending your message.</Text>
                  <Text>Need some help? Please call our team on <a href='tel:08008401628' className="underline hover:opacity-75">0800 840 1628</a>.</Text>
                </div>
              )}
            />
          )}
        </div>
      </div>
    </MyLiveWellView>
  );
};

Help.propTypes = {
  buttons: PropTypes.element,

};

Help.defaultProps = {
  buttons: null,
};