import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Form } from 'react-final-form';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import moment, { min } from 'moment';

import { Heading } from '../../atoms/Heading';
import { Text } from '../../atoms/Text';
import { Button } from '../../atoms/Button';
import { ButtonGroup } from '../ButtonGroup';
import { Icon } from '../../atoms/Icon';
import { ModalView } from '../../../templates/ModalView';
import { Field } from '../Field';

import { getDates } from '../../../features/dates';
import { getTimes } from '../../../features/times';

import './styles.css';
import { rescheduleSessions, cancelSession } from '../../../features/sessions';

export const SessionItem = ({ session, classes }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const timeSlots = useSelector(state => state.times);
  const [selectedDate, setSelectedDate] = useState(session.due_date ? session.due_date : '');
  const [minDate, setMinDate] = useState(moment().add(1, 'day').startOf('day').toDate());
  const [maxDate, setMaxDate] = useState(null);
  const [timeVars, setTimeVars] = useState([]);

  const sessionNoteModalRef = useRef(null);
  const cancelSessionModalRef = useRef(null);
  const rescheduleSessionModalRef = useRef(null);

  const rescheduleAllowed = (session.type.toLowerCase().includes('month check in') !== true);

  useEffect(() => {
    if(rescheduleSessionModalRef.current.isModalOpen()) {
      dispatch(getTimes(selectedDate));
    }
  }, [selectedDate]);

  useEffect(() => {
    const today = moment().startOf('day').unix();
    const sessionDay = moment(session.due_date).startOf('day').unix();

    // if the session is past due, set the range as 4 weeks from tomorrow
    if (sessionDay - today <= 0) {
      const minDate = moment().add(1, 'day').startOf('day').toDate();
      const maxDate = moment(minDate).add(28, 'day').startOf('day').toDate();
      setMinDate(minDate);
      setMaxDate(maxDate);
      return;
    }

    // find the a minimum reschedule date that is at most 14 days before the original, but after today
    let minDayOffset = -1; // default to tomorrow
    while (minDayOffset < 14) {
      let minDay = moment(session.due_date).subtract(minDayOffset, 'days').startOf('day').unix();
      if (minDay - today > 0) {
        // minDay is in the future
        minDayOffset++;
        continue;
      }

      // ensure minDay is not today
      if (minDay - today === 0) minDayOffset -= 1;
      break;
    }

    const minDate = moment(session.due_date).subtract(minDayOffset, 'days').startOf('day').toDate();
    setMinDate(minDate);

    // ensure a 4 week reschedule window
    const maxDayOffset = 21;
    const maxDate = moment(session.due_date).add(maxDayOffset, 'days').startOf('day').toDate();
    setMaxDate(maxDate);
  }, [session]);

  const showModal = () => {
    rescheduleSessionModalRef.current.openModal();
    cancelSessionModalRef.current.closeModal();
  }

  useEffect(() => {
    if(rescheduleSessionModalRef.current.isModalOpen()) {
      const newTimes = []
      if(timeSlots && selectedDate && timeSlots[selectedDate] && timeSlots[selectedDate].length) {

        timeSlots[selectedDate].forEach(time => {
          newTimes.push({
            label: time,
            value: time
          });
        })
      }
      console.log('getTimes', rescheduleSessionModalRef.current.isModalOpen());
      setTimeVars(newTimes);
    }
  }, [timeSlots]);


  const onRescheduleSubmit = (values) => {
    if (values) {
      const dateStr = moment(values.session_date).format('D-M-Y')+' '+values.session_timeslot.label;
      dispatch(rescheduleSessions(session.id, dateStr))
      navigate('/session-updated');
    }
  };

  const onCancelSession = () => {
    dispatch(cancelSession(session.id));
    if (session.type.toLowerCase() === 'coaching sessions') {
      navigate('/cancel-coaching');
    } else {
      navigate('/cancel-checkin');
    }
  };

  const dueDateRange = session.type.includes("month check in") && minDate && maxDate ? `${moment(minDate).format('D/M/YY')} - ${moment(maxDate).format('D/M/YY')}` : `${moment(session.due_date).format('D/M/YY')}  at ${session.time}`;

  return (<>
    <div key={0} role='listitem' className={['session-card-item', classes && classes].join(' ')}>
      {session.status.toLowerCase() !== 'due' ? (
        <div className='flex items-start justify-between w-full prev'>
          <div className='session-contents flex items-center flex-row'>
            <Text body2>{session.type}</Text>
            {session.notes.length > 0 && (
              <button type='button' className='count-button' title='Total session notes' onClick={() => { sessionNoteModalRef.current.openModal(); }}>{session.notes.length}</button>
            )}
          </div>
          <Text body2>{session.status.toLowerCase()}</Text>
          <Text body2>{dueDateRange}</Text>
        </div>
      ) : (
        <div className='flex flex-col sm:flex-row sm:items-center sm:justify-between w-full upcoming'>
          <div className='session-contents mb-lwd-5 sm:0'>
            <Text classes='text-primary-500 mb-lwd-5' body1m>{session.type}</Text>
            <Text body2>{dueDateRange}</Text>
          </div>
          <ButtonGroup classes='algin-right' buttons={[
            rescheduleAllowed && <Button label='Reschedule' mode='secondary' onClick={showModal} key={0} />,
            // <Button label='Cancel' mode='secondary' onClick={() => {
            //   cancelSessionModalRef.current.openModal();
            // }} key={1} />
          ]} />
        </div>
      )}
    </div>
    <ModalView key={1} ref={sessionNoteModalRef} title='Session notes'>
      <Heading classes='text-primary-500 font-todaysbbold mb-2.5 smd:mb-5' h2>{session.type}</Heading>
      <div className='content-item mb-5 smd:mb-lwd-30'>
        <Text classes='mb-lwd-5 uppercase font-todaysbbold' body2>Session date</Text>
        <Text body2>{`${dueDateRange}`}</Text>
      </div>
      {session.notes.map((obj, idx) => (
        <div key={`note-${idx}`} className='content-item mb-5'>
          <Text classes='mb-lwd-5 uppercase font-todaysbbold' body2>{obj.date}</Text>
          <Text body2>{obj.note}</Text>
        </div>
      ))}
    </ModalView>
    <ModalView key={2} ref={cancelSessionModalRef} title='Cancel session' subtitle={session.type}>
      <Heading classes='text-primary-500 font-todaysbbold mb-2.5 smd:mb-5' h2>Are you sure you want to cancel this session?</Heading>
      <ButtonGroup classes='algin-right mt-5' buttons={[
        <Button
          key={0}
          label='Yes'
          mode='secondary'
          onClick={onCancelSession}
        />,
        <Button label='No' mode='secondary' onClick={() => { cancelSessionModalRef.current.closeModal(); }} key={1} />
      ]} />
      {rescheduleAllowed &&
        <div className='modal-bottom'>
          <Text classes='text-lwd-grey-900 mb-2.5' body2>Want to reschedule for another time?</Text>
          <Button
            mode='primary'
            label='Reschedule this session'
            isIcon
            iconComponent={<Icon name='chevron-white-right' />}
            onClick={() => {
              cancelSessionModalRef.current.closeModal();
              rescheduleSessionModalRef.current.openModal();
            }}
          />
        </div>
      }
    </ModalView>
    <ModalView key={3} ref={rescheduleSessionModalRef} overlayClasses='overflow-scroll' title='Change session' subtitle={session.type}>
      <Heading classes='text-primary-500 font-todaysbbold mb-2.5 smd:mb-5' h2>Reschedule your session</Heading>
      <Form
        onSubmit={onRescheduleSubmit}
        render={({ handleSubmit, form, submitting, pristine, values }) => (
          <form onSubmit={handleSubmit} className='w-full flex-grow flex flex-col'>
            <div className='flex flex-col items-start gap-8'>
              <Field
                name='session_date'
                input='flatdatepicker'
                label='Select a date'
                value={selectedDate}
                isRequired
                setSelectedDate={setSelectedDate}
                minDate={minDate}
                maxDate={maxDate}
                onMonthChange={(date) => date && dispatch(getDates(moment(date).format('YYYY-MM-01')))}
              />
              <Field
                input='select'
                name='session_timeslot'
                label='Select a timeslot'
                placeholder='Please select'
                inputOptions={timeVars}
                styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
                menuPosition={'fixed'}
                menuPortalTarget={document.body}
                isRequired
                disabled={timeVars.length === 0}
              />
              <ButtonGroup classes='algin-right mt-5 modal-pair-buttons' buttons={[
                <Button
                  label='Confirm Session'
                  mode='primary'
                  isIcon
                  iconComponent={<Icon name='chevron-white-right' />}
                  isSubmit
                  key={0}
                />,
                <Button
                  label='I want to cancel this session'
                  mode='plainBtn'
                  onClick={() => {
                    rescheduleSessionModalRef.current.closeModal();
                    cancelSessionModalRef.current.openModal();
                  }}
                  key={1}
                />
              ]} />
            </div>
          </form>
        )}
      />
    </ModalView>
  </>);
}

SessionItem.propTypes = {
  session: PropTypes.object.isRequired,
};