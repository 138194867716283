import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Button } from '../../../components/atoms/Button';
import { ButtonGroup } from '../../../components/molecules/ButtonGroup';
import { StickyButtons } from '../../../components/molecules/StickyButtons';

import { MultiForm } from '../../../components/organisms/MultiForm';
import api from '../../../lib/api';
import { SelectATime } from '../../RegistrationFlow/SelectATime';
import { ThankYou } from '../../ThankYou';

import './styles.css';
// import alterBalloons from '../../../assets/images/registation/alternative-balloons.svg';
import { ButtonCard } from '../../../components/molecules/ButtonCard';
// import { Icon } from '../../../components/atoms/Icon';
import { Text } from '../../../components/atoms/Text';
import { PreferenceItem } from '../../../components/molecules/PreferenceItem';

/**
 * Primary UI component for user interaction
 */
export const SelectATimeForm = () => {
  const navigate = useNavigate();
  const [days, setDays] = useState([]);
  const [times, setTimes] = useState([]);
  const [initialValues, setInitialValues] = useState({
    days: [],
    times: [],
  });
  const profile = useSelector((state) => state.profile);

  const dayOptions = [
    {label: 'Monday', value: 'mon', rank:1},
    {label: 'Tuesday', value: 'tue', rank:2},
    {label: 'Wednesday', value: 'wed', rank:3},
    {label: 'Thursday', value: 'thu', rank:4},
    {label: 'Friday', value: 'fri', rank:5},
  ];

  const timeOptions = [
    {label: 'Morning', value: 'morning', rank:1},
    {label: 'Lunch time', value: 'lunch', rank:2},
    {label: 'Afternoon', value: 'afternoon', rank:3},
    {label: 'Early evening', value: 'evening', rank:4},
  ];

  const getOptionChosen = (options, selected) => {
    if (! options) return '';
    let selectOptions = [];

    for (let item of selected) {
      let selected = options.find(option => option.value === item);
      if (selected) selectOptions.push(selected);
    }

    return selectOptions.sort((a, b) => a.rank - b.rank).map(option => option.label + 's').join(', ');
  };

  useEffect(() => {
    let previouslySelected = {
      days: [],
      times: [],
    };

    for(let preference of Object.keys(profile).filter(key => key.substring(0, 8) === 'contact_')) {
      if (profile[preference] !== true) continue;
      let key = preference.replace('contact_', '');
      let type = typeof dayOptions.find(day => day.value === key) !== 'undefined' ? 'day' : 'time';
      type === 'day' ? previouslySelected.days.push(key) : previouslySelected.times.push(key);
    }

    setInitialValues(previouslySelected);
  }, [profile]);


  return (
    <MultiForm
      name="SelectATime"
      onSubmit={() => {}}
      hideButtons={false}
      initialHistory={[0]}
      initialValues={initialValues}
    >
      <MultiForm.Page next={(values, step, nextPage) => {
        if (values.days.length === 0 || values.times.length === 0) return;

        api.updateTimePreference(values, (err, res) => {
          if(err) {
            console.log(err);
            return;
          }

          if (res.data !== 'ok') {
            console.log(res.data);
            return;
          }

          setDays(values.days);
          setTimes(values.times);
          nextPage(values, step);
        });
      }}>
        <SelectATime customButtons={({ values })=>(
          <StickyButtons>
            <ButtonGroup isStacked buttons={[
              <Button 
                label="Confirm My Preferences" 
                mode="primary" 
                key={2} 
                isSubmit 
                isDisabled={!values?.days.length && !values?.times.length}  />
            ]} />
          </StickyButtons>)
        }/>
      </MultiForm.Page>

      <MultiForm.Page next={(values, step, nextPage) => {
        nextPage(values, step -2);
      }}>
        <ThankYou
          imageSrc=''
          hideImage
          title="Thank you, we've got your times!"
          description="We'll try our very best to call you at your chosen time. And don't worry, if you change your mind, you can go back and change your times, too!"
          buttonLabel='Back To Home'
          onClick={() => navigate('/')}
          highlightedAction={[
            <ButtonCard
              key='contact-chosen-card'
              classes='mx-auto mt-5 md:max-w-lwd-600 bg-lwd-grey-100'
              title='Your contact preferences:'
              icon='phone'
              btnIsSubmit={true}
              btnLabel='Change preferences'
              btnMode='secondary'
              body={<div>
                <PreferenceItem
                  icon='timer'
                  classes='mt-5'
                  title={getOptionChosen(timeOptions, times)}
                />
                <PreferenceItem
                  icon='calendar'
                  classes='mt-5'
                  title={getOptionChosen(dayOptions, days)}
                />
              </div>}
              footer={<div className='mt-2'>
                <Text classes='text-center text-lwd-grey-900'>Please note that updating your time & day preferences will overwrite your existing selection.</Text>
              </div>}
            />,
          ]}
        />
      </MultiForm.Page>

      <MultiForm.Page></MultiForm.Page>
    </MultiForm>
  );
};

SelectATimeForm.propTypes = {
};

SelectATimeForm.defaultProps = {
};

