import React from 'react';
import PropTypes from 'prop-types';

/**
 * Primary UI component for user interaction
 */
export const AlcoholCardGroup = ({ alcohols, classes, ...props }) => {  
    return (
    <div className={['alcohol-card-group flex flex-col gap-2 w-full', classes & classes].join(' ')}>
        { alcohols }
    </div>
    );
};

AlcoholCardGroup.propTypes = {
  alcohols: PropTypes.arrayOf(PropTypes.object).isRequired,
  classes: PropTypes.string,
};

AlcoholCardGroup.defaultProps = {
  classes: ''
};
