import React from 'react';
import PropTypes from 'prop-types';
import Slider from "react-slick";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

/**
 * Primary UI component for user interaction
 */
export const ResultCarousel = ({ data, classes, ...props }) => {
  const carouselSetting = {
    infinite: false,
    dots: false,
    arrows: false,
    speed: 500,
    responsive: [
      {
        breakpoint: 9999,
        settings: 'unslick'
      },
      {
        breakpoint: 640,
        settings: {
          slidesToShow: 2.25,
          slidesToScroll: 1,
        }
      }
    ],
  };

  const Wrapper = data.length > 2 ? Slider : 'div'; 

  return (
    <div className={['card-carousel-container', 'w-full', classes && classes].join(' ')}>
      <Wrapper className="card-carousel sm:flex sm:flex-row sm:gap-4" {...(data.length > 2 && carouselSetting)}>
        {data.map(item => (
          <div className="carousel-item pr-4 sm:pr-0 sm:grow">
            {item}
          </div>
        ))}
      </Wrapper>
    </div>
  );
};

ResultCarousel.propTypes = {
  /**
   * This is all our carousel array items
   */
  data: PropTypes.array,
  classes: PropTypes.string,
};

ResultCarousel.defaultProps = {
  data: [],
  classes: ''
};

