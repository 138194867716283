import api from "../lib/api";

export const SET_DROPDOWNS = 'SET_DROPDOWNS';
export const CLEAR_DROPDOWNS = 'CLEAR_DROPDOWNS';

/** articles */

export const getDropdowns = (key, sortAlpha=false) => (dispatch) => {
  const opts = {  key, limit: 100 };
  if(key === 'ethnicity') {
      opts.public = true;
  }
  api.dropdowns(opts, (err, responseJSON) => {
      if(responseJSON && responseJSON.data) {
        // format needs to be
        // {label: 'I want to improve my general health', value: 'I want to improve my general health'},

        const values = [];

        const overrideEditableValue = [
          'ethnicity', 'HearAbout'
        ];

        if(responseJSON.data.data) {
          responseJSON.data.data.forEach(group => {
            const editable = overrideEditableValue.includes(key) ? false : group.editable;
            let optKey = 0;
            group.options.forEach(option => {
              optKey ++;
              values.push({
                optKey,
                label: option.value,
                value: editable ? option.id : option.value,
                rank: option.rank
              });
            });
          });
        }

        if (sortAlpha) {
          values.sort((a, b) => {
            let labelA = a.label.toLowerCase();
            let labelB = b.label.toLowerCase();
            return (labelA < labelB) ? -1 : (labelA > labelB) ? 1 : 0;
          });
        } else {
          values.sort((a, b) => a.rank - b.rank);
        }


        return dispatch(setDowndowns(key, values));
      }

      // console.error("Unable to find getDropdown");
  });
};

/** articles */
export const setDowndowns = (key, payload) => ({
  type: SET_DROPDOWNS,
  key,
  payload: payload,
});

export const clearDropDowns = () => ({
  type: CLEAR_DROPDOWNS,
});


/** reducer */
const initialState = [];


export default function reducer(state = initialState, action) {
    const { type, payload } = action;
    const localState = { ...state };

    switch (type) {
    case SET_DROPDOWNS:
      localState[action.key] = payload;
      return localState;

    case CLEAR_DROPDOWNS:
      return initialState;

    default:
        return state;
    }
}
