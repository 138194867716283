import React, { useState } from 'react';
import api from '../../../lib/api';
import { useLocation, useNavigate } from 'react-router-dom';

import { Button } from '../../../components/atoms/Button';
import { ButtonGroup } from '../../../components/molecules/ButtonGroup';
import { StickyButtons } from '../../../components/molecules/StickyButtons';
import { MultiForm } from '../../../components/organisms/MultiForm';
import { VerificationEmail } from '../../RegistrationFlow/VerificationEmail';
import { ThankYou } from '../../ThankYou';

import balloons from '../../../assets/images/registation/balloons.svg';
import { MainView } from '../../../templates/MainView';

/**
 * Primary UI component for user interaction
 */
export const VerificationEmailForm = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [submitting, setSubmitting] = useState(false);

  const redirectToHome = () => {
    navigate('/');
    return (<MainView></MainView>);
  };

  if (! (location.state && location.state.first_name && location.state.email && location.state.dob)) return redirectToHome();

  const thankYouDescription = location.state && location.state.someone_else === 'for-someone-else' ?
    `Please ask ${location.state.first_name} to check their email. Once they receive it, they should open the email and click the link. ${location.state.first_name} will be asked to set-up a password. Then their LiveWell account will be ready! Please allow a few minutes for the email to arrive.` :
    'Please check your email. Once you receive it, open the email and click the link. You will be asked to set-up a password. Then your LiveWell account will be ready! Please allow a few minutes for the email to arrive.';

  return (
    <MultiForm
      name="VerificationEmailForm"
      hideButtons={false}
      initialHistory={[0]}
      onSubmit={() => navigate('/what-happens-next')}
    >
      <MultiForm.Page next={(values, step, nextPage) => {
        const clientInfo = {
          email: location.state.email,
          dob: location.state.dob,
        }

        api.resendWelcomeEmail(clientInfo, (err, res) => {
          if(err) {
            console.log(err);
            return;
          }

          if (res.data !== 'ok') {
            console.error('failed to resend welcome email');
            return;
          }

          nextPage(values, step);
        });
      }}>
        <VerificationEmail submitting={submitting} customButtons={
          <StickyButtons classes='md-sticky-pt-0'>
            <ButtonGroup isStacked classes='mx-auto' buttons={[
              <Button classes='justify-center' mode='primary' isSubmit onClick={() => {
                setSubmitting(true);
              }} label='Resend Email' key={0} />,
              <Button classes='justify-center' mode='secondary' onClick={() => navigate('/what-happens-next')} label='Get in Touch' key={1}/>
            ]} />
          </StickyButtons>
        }/>
      </MultiForm.Page>

      <MultiForm.Page>
        <ThankYou
          imageSrc={balloons}
          imageClass="container-max-h-none"
          title="We've just resent an email"
          description={thankYouDescription}
          buttonLabel='Get in Touch'
          isSubmit
        />
      </MultiForm.Page>
    </MultiForm>
  );
};

VerificationEmailForm.propTypes = {
};

VerificationEmailForm.defaultProps = {
};

