import React from 'react';
import PropTypes from 'prop-types';
import { MainView } from '../../../templates/MainView';
import { Text } from '../../../components/atoms/Text';
import { Heading } from '../../../components/atoms/Heading';
import { Field } from '../../../components/molecules/Field';
import { Image } from '../../../components/atoms/Image';
import { ProgressBar } from '../../../components/molecules/ProgressBar';

import activeSrc from '../../../assets/images/assessment/activity-active.svg';

import './styles.css';

/**
 * Primary UI component for user interaction
 */
export const ActivityQuestion = ({ buttons, imageSrc, isImageSmall, step, stepText, title, description, hourFieldName, minFieldName, totalPages }) => {
  return (
    <MainView BPadding2>
      <ProgressBar min={0} max={totalPages} current={step} label='Quick health check' />

      <div className='px-6 pt-8 pb-14 smd:p-12 flex flex-col'>
        <Text classes='mb-2' body>{stepText}</Text>
        <Heading classes={description ? 'mb-2' : 'mb-8'} h1>
          {title} <span className='text-error-500'>*</span>
        </Heading>
        {description &&
          <Text classes='mb-8' body>{description}</Text>
        }

        <div className='flex flex-col gap-6 pb-10 smd:pb-0'>
          <Image
            src={imageSrc || activeSrc}
            alt='activity question related image'
            containerClasses={['activity-img-container', isImageSmall && 'small', 'flex', 'justify-center'].join(' ')}
            imgClass='object-contain'
          />

          <div className='flex flex-row gap-4 w-full'>
            <Field
              input='text'
              name={hourFieldName}
              label='Hours'
              placeholder='Hours'
              isRequired
            />

            <Field
              input='text'
              name={minFieldName}
              label='Minutes'
              placeholder='Minutes'
            />
          </div>
        </div>

        { buttons }
      </div>
    </MainView>
  );
};

ActivityQuestion.propTypes = {
  buttons: PropTypes.element,
  imageSrc: PropTypes.string,
  step: PropTypes.number.isRequired,
  stepText: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.string,
  hourFieldName: PropTypes.string.isRequired,
  minFieldName: PropTypes.string.isRequired,
  isImageSmall: PropTypes.bool,
  totalPages: PropTypes.number,
};

ActivityQuestion.defaultProps = {
  buttons: null,
  description: '',
  isImageSmall: false,
  totalPages: 4,
};