import React from 'react';

import { MultiForm } from '../../../components/organisms/MultiForm';
import { NotInDorset } from '../../RegistrationFlow/NotInDorset';
import { GPAndWorkplace } from '../../RegistrationFlow/GPAndWorkplace';
import { NotEligible as NotEligibleResult } from '../../RegistrationFlow/NotEligible';

import './styles.css';
import { useFormState } from 'react-final-form';
import { useLocation, useNavigate } from 'react-router';
import { useDropDown } from '../../../lib/useDropdown';
import { StickyButtons } from '../../../components/molecules/StickyButtons';
import { ButtonGroup } from '../../../components/molecules/ButtonGroup';
import { Button } from '../../../components/atoms/Button';

/**
 * Primary UI component for user interaction
 */
export const NotEligible = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const dorsetSurgeries = useDropDown('gpSurgery', (values) => values.filter(obj => ['0 not recorded'].indexOf(obj.label.toLowerCase()) === -1));
  const dorsetWorkplaces = useDropDown('workplaces');

  return (
    <MultiForm
      name="NotEligible"
      // initialValues={{ email: 'mark@adi.do', postcode: 'bh1 1jn' }}
      onSubmit={() => {}}
      alwaysUseButtonRows={true}
    >
      <MultiForm.Page next={(values, step, nextPage) => {
        if (values.not_in_dorset === 'neither') {
          nextPage(values, step + 1);
          return;
        }

        nextPage(values, step);
      }}>
        <NotInDorset customButtons={
          <StickyButtons>
            <ButtonGroup classes='' alwaysRow={true} buttons={[
              <Button label="Back" mode="secondary" key={1} onClick={() => {
                let startingVals = {};
                if (location.state && location.state.registrationValues) {
                  startingVals = location.state.registrationValues;
                }

                navigate('/register', {
                  state: {
                    startingHistory: [0,1],
                    startingVals: startingVals
                  }
                });
              }} />,
              <Button label="Continue" mode="primary" key={2} isSubmit />
            ]} />
          </StickyButtons>
        } />
      </MultiForm.Page>

      <MultiForm.Page next={(values, step, nextPage) => {
        const validSurgery = dorsetSurgeries.find(surgery => values.gp_surgery && surgery.value === values.gp_surgery.value);
        const validWorkplace = dorsetWorkplaces.find(workplace => values.where_work && workplace.value === values.where_work.value);

        if (validSurgery || validWorkplace) {
          let startingVals = {};
          if (location.state && location.state.registrationValues) {
            startingVals = location.state.registrationValues;
          }

          if (values.not_in_dorset === 'gp in Dorset') {
            startingVals['client']['gp_practice_id'] = values.gp_surgery;
          } else {
            startingVals['client']['workplace'] = values.where_work;
          }

          navigate('/register', {
            state: {
              startingHistory: [0,1,2],
              startingVals: startingVals
            }
          });
          return;
        }

        nextPage(values, step);
      }}>
        <GPAndWorkplace />
      </MultiForm.Page>

      <MultiForm.Page>
        <NotEligibleResult />
      </MultiForm.Page>
    </MultiForm>
  );
};

NotEligible.propTypes = {
};

NotEligible.defaultProps = {
};

