import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import './styles.css';
import { Badge } from '../Badge';

/**
 * Primary UI component for user interaction
 */
export const BadgeGroup = ({ options, value, onChange, isClickable, selectedArray }) => {
    const [selectedValues, setSelectedValues] = useState(selectedArray || []);

    useEffect(() => {
      if(value) {
        setSelectedValues(value);
      }
    }, []);

    const handleChange = (selectedValue) => {
      let newValues = [];
      const isSelected = selectedValues.includes(selectedValue);
      if(isSelected) {
        newValues = selectedValues.filter(value => value !== selectedValue);
      } else {
        newValues = [...selectedValues, selectedValue];
      }
      setSelectedValues(newValues);
      onChange(newValues);
    };

    const isSelected = (value) => (selectedValues.includes(value));

    return (
      <div className='badge-group'>
        { options.map((child, idx) => (
          <Badge
            key={idx}
            text={child.label}
            onChange={handleChange}
            isClickable={(selectedArray && selectedArray.includes(child.value)) ? false : isClickable}
            checked={isSelected(child.value)}
            {...child}
          />
        ))}
      </div>
    );
};

BadgeGroup.propTypes = {
  /**
   * Add your buttons here
   */
   options: PropTypes.arrayOf(PropTypes.object).isRequired,

    /** 
     * on value Change event
     */
    onChange: PropTypes.func,

    wrapperClass: PropTypes.string,

    /**
     * Is this a radio group or a checkbox group
     */
    type: PropTypes.oneOf(['radio', 'checkbox']),

    value: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),

    isClickable: PropTypes.bool,
};

BadgeGroup.defaultProps = {
    isClickable: false
};
