import React from 'react';
import { Link, useNavigate } from 'react-router-dom';

import { MainView } from '../../../templates/MainView';
import { Image } from '../../../components/atoms/Image';
import { HeadingBlock } from '../../../components/molecules/HeadingBlock';
import { Text } from '../../../components/atoms/Text';
import { Icon } from '../../../components/atoms/Icon';

import clock from '../../../assets/images/registation/clock.svg'
import { ButtonGroup } from '../../../components/molecules/ButtonGroup';
import { StickyButtons } from '../../../components/molecules/StickyButtons';
import { Button } from '../../../components/atoms/Button';


/**
 * Primary UI component for user interaction
 */
export const CompleteAssessment = ({ buttons, customButtons }) => {
  const navigate = useNavigate();

  return (
    <MainView BPadding2>
      <div className='title-container'>
        <Text classes="text-lwd-grey-900 flex flex-row items-center" body1m>
          <Icon name='chevron-purple-left' />
          <Link className='ml-3' to='/'>Back to home</Link>
        </Text>
      </div>

      <div className='px-6 py-8 smd:p-12 flex flex-col items-center'>
        <Image src={clock} alt='mailbox image' containerClasses='mb-8 max-w-none sm:max-w-[65%]' />

        <HeadingBlock heading='It looks like you haven’t completed the quick health check' text='We recommend completing your health & wellbeing assessment before your advisor session to help give us to understand how we can best help you!' />

        <StickyButtons>
          <ButtonGroup buttons={[
            <Button label="Complete The Check" mode="primary" key={1} onClick={() => navigate('/assessment')}/>,
            <Button label="Have my chat now" mode="secondary" key={2} isSubmit />
          ]} />
        </StickyButtons>
      </div>

      { customButtons || buttons }
    </MainView>
  );
};

CompleteAssessment.propTypes = {
};

CompleteAssessment.defaultProps = {
};

