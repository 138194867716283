
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { Text } from '../../atoms/Text';
import { Button } from '../../atoms/Button';
import { ButtonGroup } from '../../molecules/ButtonGroup';
import { StickyButtons } from '../../molecules/StickyButtons';
import { Icon } from '../../atoms/Icon';

import './styles.css';

import logo from '../../../assets/logos/logo-desktop.svg';
import smallLogo from '../../../assets/logos/logo.svg';

/**
 * Primary UI component for user interaction
 */
export const MainNav = ({ data, ...props }) => {
  const navigate = useNavigate();

  const [isOpen, setIsOpen] = useState(false);

  return (
    <div className={['main-nav', isOpen && 'menu-open'].join(' ')}>
        <a href="https://www.livewelldorset.co.uk" title='home page link' className='flex flex-row items-center'>
          <img className='hidden 2xl:block' src={logo} alt="livewell dorset logo" />
          <img className='block 2xl:hidden w-10 xl:w-12' src={smallLogo} alt="livewell dorset logo" />
          <span className='sr-only'>Livewell Dorset Home page link</span>
        </a>

        <div className="main-nav-container">
          <nav className='navigation' aria-label='Main navigation'>
            <Text nav link='https://www.livewelldorset.co.uk/get-active/' newTab>Get Active</Text>
            <Text nav link='https://www.livewelldorset.co.uk/lose-weight/' newTab>Lose Weight</Text>
            <Text nav link='https://www.livewelldorset.co.uk/stop-smoking/' newTab>Stop Smoking</Text>
            <Text nav link='https://www.livewelldorset.co.uk/drink-less/' newTab>Drink Less</Text>
            <Text nav link='https://www.livewelldorset.co.uk/success-stories/' newTab>Success Stories</Text>
            <Text nav link='https://www.livewelldorset.co.uk/how-were-different/' newTab>How We’re Different</Text>
          </nav>

          <StickyButtons classes='main-nav-buttons'>
            <ButtonGroup buttons={[
              <Button label="Sign In" mode="secondary" onClick={() => navigate('/')} key={0} />,
              <Button label='Register' mode='primary' onClick={() => navigate('/register')} key={1} />
            ]} />
          </StickyButtons>
        </div>

        <button type='button' className='menu-button' title='Toggle menu icon' onClick={() => {setIsOpen(!isOpen)}}>
          {isOpen ? <Icon name='exit-button' /> : <Icon name='nav-button' /> }
          <span className='sr-only'>Menu</span>
        </button>

        <Button classes='register-btn-mobile' label='Get In Touch' mode='secondary' onClick={() => window.location = 'https://www.livewelldorset.co.uk/contact/'} />
    </div>
  );
};

MainNav.propTypes = {

};

MainNav.defaultProps = {
};
