import api from "../lib/api";

export const SET_ACTIONS = 'SET_ACTIONS';
export const CLEAR_ACTIONS = 'CLEAR_ACTIONS';

/** actions */

export const getActions = () => (dispatch) => {
  api.actions((err, responseJSON) => {
      if(responseJSON && responseJSON.data) {
        console.log('getActions',responseJSON);
        return dispatch(setActions(responseJSON.data.data));
      }

      console.error("Unable to find actions");
  });
};

export const updateAction = (id, status) => (dispatch) => {
  api.updateAction(id, { status }, (err, responseJSON) => {
    // dispatch(getActions());
  });
};


/** actions */
export const setActions = (payload) => ({
  type: SET_ACTIONS,
  payload,
});

export const clearActions = () => ({
  type: CLEAR_ACTIONS,
});


/** reducer */
const initialState = [];


export default function reducer(state = initialState, action) {
    const { type, payload } = action;

    switch (type) {
    case SET_ACTIONS:
        return payload;

    case CLEAR_ACTIONS:
        return initialState;

    default:
        return state;
    }
}
