import querystring from 'querystring';

import api from '../lib/api';


export const LOGIN = 'LOGIN';
export const LOGGEDIN = 'LOGGEDIN';
export const LOGOUT = 'LOGOUT';

/** actions */

export const resetPassword = ({password, password_confirmation}, cb) => (dispatch) => {
  const q = querystring.parse(window.location.search.substr(1));

  const data = {
    dob: q.dob,
    email: q.email.replace(' ', '+'),
    password,
    password_confirmation,
    token: q.t
};
    api.reset(data, cb);
};
