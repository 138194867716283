import { combineReducers } from 'redux';

import login from './login';
import alert from './alert';
import profile from './profile';
import plan from './plan';
import messages from './messages';
import actions from './actions';
import sessions from './sessions';
import articles from './articles';
import notifications from './notifications';
import dates from './dates';
import times from './times';
import eligibility from './eligibility';
import preassessment from './preassessment';
import dropdowns from './dropdowns';
import loading from './loading';

export default combineReducers({
  login,
  alert,
  profile,
  plan,
  messages,
  actions,
  sessions,
  articles,
  notifications,
  dates,
  times,
  eligibility,
  preassessment,
  dropdowns,
  loading
});