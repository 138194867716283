import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

import { Header } from '../../components/organisms/Header';
import { Footer } from '../../components/organisms/footer';

/**
 * Primary UI component for user interaction
 */
export const MainView = ({ type, plus, BPadding1, BPadding2, allowOverflow, innerClasses, showFooter, children }) => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const contents = type === 'fullwidth' ? (
    <main id='content' key={1} className='grow w-full pt-18 lg:pt-0'>
      {children}
    </main>
  ) : (
    <main id='content' key={1} className={['smd:bg-lwd-grey-200 bg-lwd-grey-100 smd:py-12 grow w-full pt-18 pb-18 smd:pt-30 lg:pt-12', BPadding1 ? 'pb-18' : ''].join(' ')}>
      <div className={['max-w-lwd-758 mx-auto bg-lwd-grey-100 smd:rounded-xl', allowOverflow ? '' : 'overflow-hidden', innerClasses && innerClasses].join(' ')}>
        {children}
      </div>
    </main>
  );

  return <>
    <Header plus={plus} key={0} />
    {contents}
    {showFooter === true && <Footer BPadding1={BPadding1} BPadding2={BPadding2} />}
    </>;
};

MainView.propTypes = {
  children: PropTypes.oneOfType([PropTypes.object, PropTypes.array]).isRequired,
  type: PropTypes.string,
  innerClasses: PropTypes.string,
  allowOverflow: PropTypes.bool,
  showFooter: PropTypes.bool,
};

MainView.defaultProps = {
  classes: null,
  plus: false,
  allowOverflow: false,
  showFooter: false,
};

