import api from "../lib/api";

export const SET_PREASSESSMENT = 'SET_PREASSESSMENT';
export const CLEAR_PREASSESSMENT = 'CLEAR_PREASSESSMENT';

/** actions */
export const getPreAssessment = (hash) => (dispatch) => {
  api.preAssessment(hash, (err, responseJSON) => {
      if(responseJSON && responseJSON.data && responseJSON.data.status === 'available') {
        return dispatch(setPreAssessment(responseJSON.data));
      }

      console.error("Unable to find client pre-assessment details");
  });
};


/** dates */
export const setPreAssessment = (payload) => ({
  type: SET_PREASSESSMENT,
  payload,
});

export const clearPreAssessment = () => ({
  type: CLEAR_PREASSESSMENT,
});


/** reducer */
const initialState = {};

export default function reducer(state = initialState, action) {
    const { type, payload } = action;

    switch (type) {
      case SET_PREASSESSMENT:
          return payload;

      case CLEAR_PREASSESSMENT:
          return initialState;

      default:
          return state;
      }
}