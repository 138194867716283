import React from 'react';
import decodeHtmlEntity from '../../../features/decodehmtlentity';
import PropTypes from 'prop-types';
import { Button } from '../../atoms/Button';
import { Text } from '../../atoms/Text';
import { Heading } from '../../atoms/Heading';
import logoStack from '../../../assets/logos/logo-stack.svg';
import './styles.css';

/**
 * Primary UI component for user interaction
 */
export const Card = ({ date, title, body, btnLabel, btnOnClick, image, imageAlt, classes, ...props }) => {
  const imageBgStyle = {
    backgroundImage: `url('${image ? image : logoStack}')`,
  }
  if (! image) imageBgStyle['backgroundSize'] = 'auto';

  return (
    <div className={['simple-card bg-lwd-grey-100', classes && classes].join(' ')}>
      <div className='aspect-video bg-cover bg-no-repeat bg-center' style={imageBgStyle}></div>
      <div className="p-6 w-full grow flex flex-col">
        <Text classes='text-lwd-grey-500 mb-2' body>{date}</Text>
        <Heading classes='mb-2' h3>{decodeHtmlEntity(title)}</Heading>
        <Text classes='mb-6 grow' body>{decodeHtmlEntity(body)}</Text>
        <Button label={btnLabel} onClick={btnOnClick} mode="secondary" classes="w-full justify-center" />
      </div>
    </div>
  );
};

Card.propTypes = {
  /**
   * Heading of the card
   */
  title: PropTypes.string.isRequired,

  /**
   * Main body text
   */
  body: PropTypes.string.isRequired,

  /**
   * Image to display on the card
   */
  image: PropTypes.string,

  /**
   * Alt text for image
   */
  imageAlt: PropTypes.string.isRequired,

   /**
   * Optional Button contents
   */
  btnLabel: PropTypes.string,

  /**
   * Optional click handler
   */
  btnOnClick: PropTypes.func,
  classes: PropTypes.string,
};

Card.defaultProps = {
  image: 'https://v1.tailwindcss.com/img/card-top.jpg',
  imageAlt: null,
  heading: null,
  body: null,
  btnLabel: undefined,
  btnOnClick: undefined,
  classes: '',
};

