import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Button } from '../../../components/atoms/Button';
import { ButtonGroup } from '../../../components/molecules/ButtonGroup';
import { StickyButtons } from '../../../components/molecules/StickyButtons';

import { MultiForm } from '../../../components/organisms/MultiForm';
import api from '../../../lib/api';
import { UpdateGoal as Page } from '../../UpdateGoal';

import './styles.css';

/**
 * Primary UI component for user interaction
 */
export const UpdateGoals = () => {

  const navigate = useNavigate();

  const onSubmit = (values) => {
    console.log('form', values);

    api.updatePathways(values,(err, res) => {
      if(err) return alert(err);
      navigate('/update-goals/complete')
    });
    // post to /pathways with "pathways" array
    // navigate('/update-goals/complete');
  };

  return (
    <MultiForm
      name="UpdateGoals"
      // initialValues={{ email: 'mark@adi.do', postcode: 'bh1 1jn' }}
      onSubmit={onSubmit}
    >
      <MultiForm.Page>
        <Page customButtons={
          <StickyButtons classes='md-sticky-pt-0 md:mt-6'>
            <ButtonGroup isStacked buttons={[
              <Button label="Continue" mode="primary" key={1} isSubmit />
            ]} />
          </StickyButtons>
        } />
      </MultiForm.Page>
    </MultiForm>
  );
};

UpdateGoals.propTypes = {
};

UpdateGoals.defaultProps = {
};

