import React, { useRef, useEffect } from 'react';
import { Form } from 'react-final-form';
import { useDispatch, useSelector } from 'react-redux';

import { MyLiveWellView } from '../../../templates/MyLiveWellView';
import { Heading } from '../../../components/atoms/Heading';
import { MessageBubble } from '../../../components/molecules/MessageBubble';
import { Button } from '../../../components/atoms/Button';
import { Field } from '../../../components/molecules/Field';

import { addMessage, getMessages } from "../../../features/messages";

import './styles.css';
import { updateNotification } from '../../../features/notifications';

export const Messages = () => {
  const dispatch = useDispatch();
  const messages = useSelector((state) => state.messages);
  const plan = useSelector((state) => state.plan);

  const inputRef = useRef(null);

  const autoScrollToMessagesBottom = () => {
    const latestMessage = document.querySelector('.latest-message');
    if (latestMessage) latestMessage.scrollIntoView();
  };

  useEffect(() => {
    inputRef.current.focus();
    dispatch(getMessages());
  }, [dispatch]);

  useEffect(() => {
    // mount
    let messagesTimer = setInterval(() => dispatch(getMessages()), 5000);

    // unmount
    return () => clearInterval(messagesTimer);
  }, [dispatch]);

  useEffect(() => {
    inputRef.current.scrollIntoView();
    autoScrollToMessagesBottom();
  }, [messages]);

  useEffect(() => {
    messages.forEach(message => {
      if(! message.user_seen && message.notification_id !== null) {
        // mark as read
        dispatch(updateNotification(message.notification_id));
      }
    })
  }, [dispatch, messages]);

  const onSubmit = (values, form) => {
    console.log(values);

    if (!plan.id) {
      return alert('Unable to post message, client plan not found');
    }
    dispatch(addMessage(plan.id, values.message_textarea));
    form.reset();
  };

  return (
    <MyLiveWellView innerClasses='grow flex flex-col smd:block max-h-[calc(100vh-4.5rem)] smd:max-h-none'>
      <div className='max-w-lwd-860 w-full mx-auto'>
        <div className='px-6 pt-8 pb-8 smd:p-12 flex flex-col gap-8'>
          <Heading classes='text-lwd-grey-900' h2>Messages</Heading>
        </div>

        <div className='grow px-6 pt-6 pb-28 smd:px-12 smd:py-6 flex flex-col smd:min-h-lwd-620 smd:max-h-lwd-620 bg-lwd-grey-300 overflow-y-auto'>
          {messages.map((msg, idx) => {
            return (
              <MessageBubble
                key={`msg-${idx}`}
                isCoach={msg.isCoach}
                isLatest={idx === messages.length - 1}
                author={msg.author}
                date={msg.sent}
                message={msg.message}
              />
            );
          })}
        </div>

        <Form
        onSubmit={onSubmit}
        render={({ handleSubmit, form, submitting, pristine, values }) => (
          <form onSubmit={handleSubmit} className='w-full flex-grow flex flex-col'>
            <div className='message-bottom-container'>
              <Field
                ref={inputRef}
                input='textarea'
                name='message_textarea'
                label='Message'
                hideLabel
                placeholder='Type your message ...'
              />
              <Button
                isSubmit
                mode='primary'
                label='Send'
              />
            </div>
          </form>
        )} />
      </div>
    </MyLiveWellView>
  );
};

Messages.propTypes = {
};

Messages.defaultProps = {
};