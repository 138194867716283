import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Form } from 'react-final-form';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { MyLiveWellView } from '../../../templates/MyLiveWellView';
import { Heading } from '../../../components/atoms/Heading';
import { Text } from '../../../components/atoms/Text';
import { Button } from '../../../components/atoms/Button';
import { Field } from '../../../components/molecules/Field';
import { TabView } from '../../../templates/TabView';
import { InfoCard } from '../../../components/molecules/InfoCard';
import { ModalView } from '../../../templates/ModalView';
import { ButtonGroup } from '../../../components/molecules/ButtonGroup';
import { Icon } from '../../../components/atoms/Icon';

// import postcode from '../../../components/validation/postcode';
// import email from '../../../components/validation/email';
// import phoneUK from '../../../components/validation/phoneUK';

import { updatePassword, updateProfile } from '../../../features/profile';
import { closeAccount } from '../../../features/profile';

import './styles.css';
import phoneUK from '../../../components/validation/phoneUK';
import postcode from '../../../components/validation/postcode';
import password from '../../../components/validation/password';
import { useDropDown } from '../../../lib/useDropdown';

export const Account = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const profile = useSelector((state) => state.profile);
  const genders = useDropDown('gender');

  const closeAccountModalRef = useRef(null);
  const [proValues, setProValues] = useState({
    first_name: profile.first_name || null,
    last_name: profile.last_name || null,
    gender: profile.gender || null,
    address1: profile.address1 || null,
    postcode: profile.postcode || null,
    email: profile.email || null,
    phone_mobile: profile.phone_mobile || profile.phone_mobile || null,
    tool_notification_sms: profile.tool_notification_sms || null,
    tool_notification_email: profile.tool_notification_email || null,
    closure_requested: profile.closure_requested || false,
  });

  useEffect(() => {
    setProValues(profile);
  }, [profile]);

  const onSaveAccountSubmit = (values) => {
    console.log(values);

    if (values) {
      // only submit field values (ignore labels)
      for (let name of Object.keys(values)) {
        if (values[name] && values[name].value) {
          values[name] = values[name].value;
        }

        // format tool fields
        if (name === 'tool_notification_sms' || name === 'tool_notification_email') {
          values[name] = values[name] ? 'yes' : 'no'
        }
      }

      dispatch(updateProfile(values));
      navigate('/account-updated');
    }
  };

  const onChangePwdSubmit = (values) => {
    console.log(values);

    if (values) {
      dispatch(updatePassword(values, (result) => {
        if (result.status === 'Invalid password') {
          console.log('invalid password');
          return;
        }

        if (result.status !== 'OK') {
          console.log(result);
          return;
        }

        navigate('/account-updated');
      }));
    }
  };

  const postcodeKeypress = (event) => {
    const regex = /^[A-Za-z0-9 ]$/;
    if (!regex.test(event.key)) {
      event.preventDefault();
    }
  };

  const phoneUKKeypress = (event) => {
    const regex = /^[0-9 #+*]$/;
    if (!regex.test(event.key)) {
      event.preventDefault();
    }
  };

  const tab1Content = () => (
    <Form
      onSubmit={onSaveAccountSubmit}
      initialValues={{
        first_name: proValues.first_name,
        last_name: proValues.last_name,
        gender: {
          value: proValues.gender,
          label: proValues.gender
        },
        gender_other: proValues.gender_other,
        address1: proValues.address1,
        postcode: proValues.postcode,
        email: proValues.email,
        phone_mobile: proValues.phone_mobile,
        tool_notification_sms: proValues.tool_notification_sms === 'yes',
        tool_notification_email: proValues.tool_notification_email === 'yes',
      }}
      render={({ handleSubmit, form, submitting, pristine, values }) => (
        <form onSubmit={handleSubmit} className='w-full flex-grow flex flex-col'>
          <div className='tab-content flex flex-col items-start gap-8'>
            <Field
              input='text'
              name='first_name'
              label='First Name'
              placeholder='First Name'
              isRequired
            />
            <Field
              input='text'
              name='last_name'
              label='Last Name'
              placeholder='Last Name'
              isRequired
            />
            <Field
              input='select'
              name='gender'
              label='Gender'
              placeholder='Please select'
              modaltitle='Gender'
              modalsubtitle='Why do we need this?'
              help='This helps us assess any health information you give us.'
              inputOptions={genders}
              inputOnChange={opt => {
                setProValues({ ...proValues, gender: opt.value })
              }}
            />
            {proValues.gender === 'Prefer to self describe as...' &&
              <Field
                input='text'
                name='gender_other'
                label='Your gender'
                placeholder='Enter your gender'
                isRequired
              />
            }
            <Field
              input='text'
              name='address1'
              label='Address line 1'
              placeholder='Enter your address line 1'
            />
            <Field
              input='text'
              name='postcode'
              label='Post Code'
              placeholder='e.g. DT4 7BG'
              validation={[postcode]}
              maxLength={8}
              onKeyPress={postcodeKeypress}
              isRequired
            />
            <Field
              input='email'
              name='email'
              label='Email Address'
              placeholder='e.g. joebloggs@gmail.com'
              // validation={[email]}
              disabled={true}
            />
            <Field
              input='tel'
              name='phone_mobile'
              label='Phone Number'
              placeholder='Enter Phone Number'
              modalsubtitle='Why do we ask this?'
              modaltitle='Phone Number'
              help= 'We need your phone number so that our advisors can contact you to talk through your options.'
              isRequired
              validation={[phoneUK]}
              onKeyPress={phoneUKKeypress}
              maxLength={12}
            />
            <div className='checkbox-group-container'>
              <Text classes='mb-lwd-22' body>Send me reminders via</Text>
              <div className='flex flex-col gap-7'>
                <Field
                  input='checkbox'
                  name='tool_notification_sms'
                  hideLabel
                  label='SMS'
                />
                <Field
                  input='checkbox'
                  name='tool_notification_email'
                  hideLabel
                  label='Email'
                />
              </div>
            </div>
            <Button
              isSubmit
              mode='primary'
              label='Save details'
              isIcon
              iconComponent={<Icon name='chevron-white-right' />}
            />
          </div>
        </form>
      )}
    />
  );

  const tab2Content = () => (
    <Form
      onSubmit={onChangePwdSubmit}
      validate={(values) => {
        const errors = {};
        if (values.new_password_confirmation !== values.new_password) {
          errors.new_password_confirmation = 'Must match';
        }
        return errors;
      }}
      render={({ handleSubmit, form, submitting, pristine, values }) => (
        <form onSubmit={handleSubmit} className='w-full flex-grow flex flex-col'>
          <div className='tab-content flex flex-col items-start gap-8'>
            <InfoCard text='Passwords must contain a minimum of 8 characters.' icon='info-round' />
            <Field
              input='password'
              name='password'
              label='Current password'
              placeholder='Enter current password'
              isRequired
            />
            <Field
              input='password'
              name='new_password'
              label='New password'
              placeholder='Enter new password'
              validation={[password]}
              isRequired
            />
            <Field
              input='password'
              name='new_password_confirmation'
              label='New password again'
              placeholder='Enter new password again'
              isRequired
            />
            <Button
              isSubmit
              mode='primary'
              label='Update details'
              isIcon
              iconComponent={<Icon name='chevron-white-right' />}
            />
          </div>
        </form>
      )}
    />
  );

  return [
    <MyLiveWellView key={0}>
      <div className='max-w-lwd-860 w-full mx-auto px-6 py-8 smd:py-12 lg:px-0 flex flex-col gap-8'>
        <div>
          <Heading classes='text-lwd-grey-900' h2>My account</Heading>
          <Button mode='plainBtn' classes='mt-2.5' onClick={() => { closeAccountModalRef.current.openModal(); }} label='I want to close my account' />
        </div>

        <TabView
          tabTitles={['My details', 'Change password']}
          tabContents={[
            tab1Content(),
            tab2Content()
          ]}
        />
      </div>
    </MyLiveWellView>,
    <ModalView key={1} ref={closeAccountModalRef} title='Close account'>
      <Heading classes='text-primary-500 font-todaysbbold mb-2.5 smd:mb-5' h2>Do you want to close your account?</Heading>
      <Text body2>Please note you will no longer be able to log in once your account is closed. We will not be able to re-open this account and you will need to start over again.</Text>
      <ButtonGroup classes='algin-right mt-5' buttons={[
        <Button label='Yes' mode='secondary' disabled={proValues.closure_requested} onClick={() => { dispatch(closeAccount()); }} key={0} />,
        <Button label='No' mode='secondary' disabled={proValues.closure_requested} onClick={() => { closeAccountModalRef.current.closeModal(); }} key={1} />
      ]} />
      {proValues.closure_requested === true && (
        <Text body2 classes='align-center mt-5'>You have requested account closure, a member of the team will action this within two working days.</Text>
      )}
      <div className='modal-bottom'>
        <Text classes='text-lwd-grey-900 mb-2.5' body2>If you want more help - please just ask.</Text>
        <Button
          mode='primary'
          label='I need more help'
          isIcon
          iconComponent={<Icon name='chevron-white-right' />}
          onClick={() => navigate('/help')}
        />
      </div>
    </ModalView>
  ];
};

Account.propTypes = {
  buttons: PropTypes.element,

};

Account.defaultProps = {
  buttons: null,
};