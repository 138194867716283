import React from 'react';
import PropTypes from 'prop-types';

import { ContactNav } from '../ContactNav';
import { MainNav } from '../MainNav';
import { AuthedNav } from '../AuthedNav';

import './styles.css';
import { useSelector } from 'react-redux';

/**
 * Primary UI component for user interaction
 */
export const Header = ({ plus, ...props }) => {
  const { loggedIn } = useSelector((state) => state.login);

  const handleHasChange=()=>{
    window.addEventListener("hashchange", ()=>{ 
      setTimeout(()=>{
        window.history.pushState('', '', window.location.pathname + window.location.search);
      },50);
    });
  }

  const nav = (
    <>
      <a href='#content' aria-label='Skip to main content' className='skip' 
      onKeyDown={(evt)=>{
        if (evt.key ==='Enter') { 
          handleHasChange()
        }
      }} 
      onClick={()=>{
        document.getElementById('content').focus();
        handleHasChange()
      }}>Skip to main content</a>
      {loggedIn ? <AuthedNav plus={plus} /> : <MainNav />}
    </>
    );

  return [
    !loggedIn && <ContactNav key={0} />,
    nav
  ];
};

Header.propTypes = {
  plus: PropTypes.bool,
};

Header.defaultProps = {
  plus: false,
};

