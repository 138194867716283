import React from 'react';
import PropTypes from 'prop-types';
import { MainView } from '../../../templates/MainView';
import { Heading } from '../../../components/atoms/Heading';
import { Text } from '../../../components/atoms/Text';
import { BadgeGroup } from '../../../components/molecules/BadgeGroup';
import { ProgressBar } from '../../../components/molecules/ProgressBar';
import { Field } from '../../../components/molecules/Field';

import './styles.css';

import postcode from '../../../components/validation/postcode';
import email from '../../../components/validation/email';

/**
 * Primary UI component for user interaction
 */
export const EligibilityCheck = ({ buttons }) => {

  const badges = [
    { label: 'You must be 18 or over', icon: 'timer', helpText: 'Our service is designed to support adults. You need to be 18 years + to be eligible for our service.', title: '', extraTitle: 'Over 18', type: 'extraTitle', },
    { label: 'You must be based in Dorset', icon: 'questions', helpText: 'Our service is funded by Public Health Dorset to improve the health and wellbeing of local people. It is therefore only available to adults living in the county or registered with a Dorset GP.', title: '', extraTitle: 'Dorset-based', type: 'extraTitle', },
  ];

  const postcodeKeypress = (event) => {
    const regex = /^[A-Za-z0-9 ]$/;
    if (!regex.test(event.key)) {
      event.preventDefault();
    }
  };

  return (
    <MainView BPadding1>
      <div className='title-container'>
        <Text body1m='true'>MyLiveWell Registration</Text>
        <ProgressBar  max={3} current={1} type='buttons' />
      </div>

      <div className='px-6 pt-8 pb-14 smd:p-12 flex flex-col eligibility-check'>
        <ProgressBar max={3} current={1} type='buttons' />
        <Heading classes='my-8 smd:mt-0' h1>First, we need to check a couple of things...</Heading>

        <div className='flex flex-col space-y-8'>
          <BadgeGroup options={badges} />

          <Text body>Let's get a few details about you to see if we can help. Please fill out all the boxes below.</Text>

          <Field
            input='email'
            name='client[email]'
            label='Email Address'
            placeholder='e.g. joebloggs@gmail.com'
            isRequired
            validation={[email]}
          />

          <Field
            input='datepicker'
            name='client[dob]'
            label='Date of Birth'
            isRequired
          />

          <Field
            input='text'
            name='client[postcode]'
            label='Post Code'
            placeholder='e.g. DT4 7BG'
            isRequired
            validation={[postcode]}
            maxLength={8}
            onKeyPress={postcodeKeypress}
          />
        </div>

        { buttons }
      </div>
    </MainView>
  );
};

EligibilityCheck.propTypes = {
  buttons: PropTypes.element,
};

EligibilityCheck.defaultProps = {
  buttons: null,
};

