import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { MainView } from "../../templates/MainView";
import { Heading } from "../../components/atoms/Heading";
import { Card } from "../../components/molecules/Card";

import "./styles.css";
import { MyLiveWellView } from "../../templates/MyLiveWellView";
import { useDispatch, useSelector } from "react-redux";
import { getArticles } from "../../features/articles";
import moment from "moment";

/**
 * Primary UI component for user interaction
 */
export const ArticleList = () => {
  const dispatch = useDispatch();
  const articles = useSelector((state) => state.articles);

  useEffect(() => {
    dispatch(getArticles(true));
  }, [dispatch]);

  return (
    <MyLiveWellView key={0}>
      <div className='max-w-lwd-860 mx-auto px-6 py-8 smd:py-12 smd:px-0 bg-lwd-grey-100'>
        <div className='max-w-lwd-860 mx-auto'>
          <Heading classes='pb-6' h1>
            Articles
          </Heading>

          {articles && articles.length > 0 ? (
            <div className='grid-cols-1 smd:grid-cols-2 lg:grid-cols-3 grid gap-4 article-list'>
              {articles.map((article) => (
                <Card
                  classes='h-full'
                  image={article.image}
                  imageAlt={article.title}
                  date={moment.unix(article.published_at).format("Do MMMM, Y")}
                  title={article.title}
                  body={article.intro}
                  btnLabel='Read More'
                  btnOnClick={() =>
                    window.open(
                      `https://www.livewelldorset.co.uk/${article.url}`,
                      "_blank"
                    )
                  }
                />
              ))}
            </div>
          ) : (
            ""
          )}
        </div>
      </div>
    </MyLiveWellView>
  );
};

ArticleList.propTypes = {};

ArticleList.defaultProps = {};
