import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import { Link, useNavigate } from 'react-router-dom';

import { MainView } from '../../../templates/MainView';
import { Image } from '../../../components/atoms/Image';
import { InfoCard } from '../../../components/molecules/InfoCard';
import { HeadingBlock } from '../../../components/molecules/HeadingBlock';
import { Text } from '../../../components/atoms/Text';
import { Icon } from '../../../components/atoms/Icon';

import { Heading } from '../../../components/atoms/Heading';
import { Button } from '../../../components/atoms/Button';
import { ModalView } from '../../../templates/ModalView';

import telephone from '../../../assets/images/registation/telephone.svg'
import { StickyButtons } from '../../../components/molecules/StickyButtons';
import { ButtonGroup } from '../../../components/molecules/ButtonGroup';


/**
 * Primary UI component for user interaction
 */
export const ArrangeSession = ({ contactMessage, buttons, customButtons }) => {
  const navigate = useNavigate();
  const CallUsNowModalRef = useRef(null);

  return (
    <MainView BPadding2>
      <div className='title-container'>
        <Text classes="text-lwd-grey-900 flex flex-row items-center" body1m>
          <Icon name='chevron-purple-left' />
          <Link className='ml-3' to='/'>Back to home</Link>
        </Text>
      </div>

      <div className='px-6 pt-8 pb-14 smd:p-12 flex flex-col items-center'>
        <Image src={telephone} alt='mailbox image' containerClasses='mb-8 max-w-none sm:max-w-[65%]' />

        <HeadingBlock heading='Have my chat now' text='Give us a call now to begin your first chat with us so we can decide which of our services can help.' classes='mb-8'/>

        <InfoCard
          childrenComponent={[
            <Text key={0} body>{contactMessage}</Text>,
            <a href="/update-contact" title='Login Now' key={1} className='underline font-todaysbbold'>Change contact preference</a>
          ]}
          icon='info-round'
          classes='w-full'
        />

        <StickyButtons classes=''>
          <ButtonGroup buttons={[
            <Button label='Call Us Now' mode='primary' classes='w-full justify-center' onClick={() => { CallUsNowModalRef.current.openModal(); }} key={1} />,
            <Button label="Tell us when you’re free" mode="secondary" classes='w-full justify-center' key={2} onClick={() => navigate('/suggest-time')} />
          ]} />
        </StickyButtons>

        <ModalView key={2} ref={CallUsNowModalRef} title='Call Us Now' type="blackTitle">
          <div className='content-item mb-5 smd:mb-lwd-30'>
            <Text body1>Call us free of charge Monday - Friday from 9 AM to 6:30 PM.</Text>
          </div>
          <div className='flex justify-center p-6 bg-secondary-100 rounded-xl'>
            <Heading h2 classes='text-secondary-900'><a href='tel:08008401628'>0800 840 1628</a></Heading>
          </div>
        </ModalView>

        { customButtons || buttons }
      </div>
    </MainView>
  );
};

ArrangeSession.propTypes = {
  name: PropTypes.string,
};

ArrangeSession.defaultProps = {
};