import api from '../lib/api';
import { logout, pingUser } from './login';

export const SET_PROFILE = 'SET_PROFILE';
export const CLEAR_PROFILE = 'CLEAR_PROFILE';

/** actions */

/** actions */
export const setProfile = (payload) => ({
  type: SET_PROFILE,
  payload,
});

export const clearProfile = () => ({
  type: CLEAR_PROFILE,
});


export const closeAccount = (profile) => (dispatch) => {
  api.closeAccount((err, responseJSON) => {
    dispatch(logout(false));
    window.location = '/account-closed';
  });
};


export const updateProfile = (profile) => (dispatch) => {
  api.updateProfile(profile, (err, responseJSON) => {
    dispatch(pingUser());
  });
};

export const updatePassword = (profile, cb) => (dispatch) => {
  api.updatePassword(profile, (err, responseJSON) => {
    dispatch(pingUser());
    cb(responseJSON.data);
  });
};



/** reducer */
const initialState = { loggedIn: false };


export default function reducer(state = initialState, action) {
    const { type, payload } = action;

    switch (type) {
    case SET_PROFILE:
        return payload;

    case CLEAR_PROFILE:
        return initialState;

    default:
        return state;
    }
}
