import React from 'react';
import PropTypes from 'prop-types';
import { Form } from 'react-final-form'


import { Button } from '../../components/atoms/Button';
import { ButtonGroup } from '../../components/molecules/ButtonGroup';
import { StickyButtons } from '../../components/molecules/StickyButtons';


// More on component templates: https://storybook.js.org/docs/react/writing-stories/introduction#using-args
export const StoryTemplate = ({ children, onSubmit, buttonLabel, onButtonClick }) => {
  if (! buttonLabel) buttonLabel = 'Validate';

  if (typeof onSubmit !== 'function') {
    onSubmit = (values) => {
      console.log(values);
      alert('submit', JSON.stringify(values, null, 2));
    };
  }

  const buttons = [
    <Button label={buttonLabel} title={buttonLabel} mode='primary' isSubmit key={2} onClick={onButtonClick} />
  ];

  const buttonGroup = <StickyButtons>
    <ButtonGroup buttons={buttons} />
  </StickyButtons>;

  const newChild = React.cloneElement(
    children,
    {buttons: buttonGroup}
  );

  return (
    <Form
      onSubmit={onSubmit}
      render={({ handleSubmit, form, submitting, pristine, values }) => (
        <form onSubmit={handleSubmit} className='w-full flex-grow flex flex-col'>
          { newChild }
        </form>
      )}
    />
  )
};

StoryTemplate.propTypes = {
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.array]).isRequired,

};

StoryTemplate.defaultProps = {
};

