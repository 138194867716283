import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';

import { Text } from '../../atoms/Text';
import { Icon } from '../../atoms/Icon';
import { RadioGroup } from '../RadioGroup';

import { updateBCTFeeling } from '../../../features/plan';

import './styles.css';

const EMOJIS = ['0x1F641', '0x1F615', '0x1F610', '0x1F642', '0x1F600'];

export const PlanItem = ({ item, plan, active, classes, setActivePlan }) => {
  const dispatch = useDispatch();

  const toggleAccordion = () => {
    setActivePlan(active ? null : item.id);
  };

  const updateHowFeelScore = (score) => {
    dispatch(updateBCTFeeling(item.id, score));
  };

  if(!item.web_friendly_name) return null;

  return (
    <div key={0} className={['accordion-item', active && 'active', classes && classes].join(' ')}>
      <button type='button' className='accordion-trigger' onClick={() => { toggleAccordion(); }}>
        <span>{item.web_friendly_name}</span>
        <div className='right-icons'>
          <span className='emoji-text'>{item.progress && String.fromCodePoint(EMOJIS[item.progress - 1])}</span>
          <Icon name='chevron-black-down' />
        </div>
      </button>
      <div className='accordion-content'>
        {item.description && (<div>
          <Text classes='mb-lwd-5 font-todaysbbold content-title' body2>A little more information ...</Text>
          <Text classes='text-lwd-grey-400 content-text' body2>{item.description}</Text>
        </div>)}

        {item.notes && item.notes.length > 0 && (<div className={item.description ? 'mt-2.5' : ''}>
          <Text classes='mb-lwd-5 font-todaysbbold content-title' body2>Notes</Text>
          {item.notes.map((note, idx) => ([
            <Text key={`note-date-${idx}`} classes='text-lwd-grey-400 mb-lwd-5 content-text' body2>{note.date}</Text>,
            <Text key={`note-note-${idx}`} classes='text-lwd-grey-400 content-text' body2>{note.note}</Text>
          ]))}
        </div>)}

        <div className={item.description || (item.notes && item.notes.length > 0) ? 'mt-2.5' : ''}>
          <Text classes='mb-2.5 font-todaysbbold content-title' body2>How are you doing?</Text>
          <RadioGroup
            name={`emoji-radios-${item.id}`}
            onChange={val => updateHowFeelScore(val)}
            selectedData={item.progress}
            options={[
              {label: 'slightly-frowning-face', value: '1', icon: '0x1F641'},
              {label: 'confused-face', value: '2', icon: '0x1F615'},
              {label: 'neutral-face', value: '3', icon: '0x1F610'},
              {label: 'slightly-smiling-face', value: '4', icon: '0x1F642'},
              {label: 'grinning-face', value: '5', icon: '0x1F600'},
            ]}
            isEmoji
          />
        </div>
      </div>
    </div>
  );
}

PlanItem.propTypes = {
  item: PropTypes.object,
  plan: PropTypes.object,
};

PlanItem.defaultProps = {
};