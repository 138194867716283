import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import { getArticles } from '../../features/articles';
import { checkLogin } from '../../features/login';
import { MainView } from '../../templates/MainView';
import { Image } from '../../components/atoms/Image';
import { Heading } from '../../components/atoms/Heading';
import { Text } from '../../components/atoms/Text';
import { Button } from '../../components/atoms/Button';
import { Icon } from '../../components/atoms/Icon';
import { StickyButtons } from '../../components/molecules/StickyButtons';
import { ButtonGroup } from '../../components/molecules/ButtonGroup';

import './styles.css';

import HeaderImg from '../../assets/images/header.jpg';

/**
 * Primary UI component for user interaction
 */
export const Article = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const articles = useSelector(state => state.articles);
  const { loggedIn } = useSelector((state) => state.login);

  useEffect(() => {
    dispatch(getArticles(true));
    dispatch(checkLogin());
  }, [dispatch]);

  return (
    <MainView>
      <div className='title-container'>
        <Text classes="font-todaysbmed text-lwd-grey-900 flex flex-row items-center" body1m nav link='/'>
          <Icon name='chevron-purple-left' />
          <span className='ml-3'>Back to home</span>
        </Text>
      </div>

      <div className='px-6 py-8 smd:p-12 flex flex-col items-center'>
        <Image src={HeaderImg} alt='Get support from LiveWell Dorset' imgClass="max-h-lwd-200 object-top mb-8 rounded-xl overflow-hidden" />
        <Heading classes="mb-2" h1>What happens now?</Heading>
        <div className='flex flex-col gap-lwd-30'>
          <Text body>Starting something new can be scary, but we're here to support you so don't worry!</Text>
          <Text body>During your first session with us, one of our friendly advisors will talk to you about how you are right now and what you want to do. This really helps us to help you and to provide the best service we can.</Text>
          <Text body>It's important to us that you feel  happy and relaxed. Some of the things we might chat about include:</Text>

          <ul className='list-disc list-inside'>
            <li className='text-18 leading-normal tracking-lwd-0.5 md:text-20'>The results from your quick health check (you'll find a link on your dashboard)</li>
            <li className='text-18 leading-normal tracking-lwd-0.5 md:text-20'>How much you currently exercise</li>
            <li className='text-18 leading-normal tracking-lwd-0.5 md:text-20'>Whether or not you smoke</li>
            <li className='text-18 leading-normal tracking-lwd-0.5 md:text-20'>How much you currently drink</li>
            <li className='text-18 leading-normal tracking-lwd-0.5 md:text-20'>Your <abbr title="Body Mass Index">BMI</abbr></li>
          </ul>

          <Text body>If you feel uncomfortable or would you'd rather not answer some of our questions, that's OK.</Text>
          <Text body>We are really looking forward to getting to know you!</Text>
        </div>

        <StickyButtons>
          <ButtonGroup isStacked buttons={[
            <Button label='Have My Chat Now' onClick={() => navigate(loggedIn ? '/book-a-chat' : '/')} mode='primary' classes='w-full justify-center' key={0} />
          ]} />
        </StickyButtons>
      </div>
    </MainView>
  );
};

Article.propTypes = {
};

Article.defaultProps = {
};

