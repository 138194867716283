import React, { useState, forwardRef, useRef } from 'react';
import PropTypes from 'prop-types';
import { Field as FormField } from 'react-final-form'
import Select from 'react-select'

import { Icon } from '../../atoms/Icon';
import { Checkbox } from '../../atoms/Checkbox';
import { BadgeGroup } from '../BadgeGroup';
import { InfoCard } from '../InfoCard';
import { ModalView } from '../../../templates/ModalView';
import { Heading } from '../../atoms/Heading';
import { Text } from '../../atoms/Text';
import { DatePicker } from '../DatePicker';
import { FlatDatepicker } from '../FlatDatepicker';
import required from '../../validation/required';
import { RadioGroup } from '../RadioGroup';
import { Rating } from '../Rating';

import composeValidators from '../../validation/composeValidators';

import './styles.css';
import { useEffect } from 'react';

/**
 * Primary UI component for user interaction
 */
export const Field = forwardRef(({ input, inputOptions, isShowingRequired, isRequired, checked, value, label, errorMessage, inputOnChange, modalsubtitle, modaltitle, help, hint, disabled, placeholder, validation, name, hideLabel, heading, score, isSearchable = false, ...props }, ref) => {
  const helpModalRef = useRef(null);
  const [inputValue, setInputValue] = useState('');

  const hideTopLabel = hideLabel ? hideLabel : false;

  const extraStyle = input === 'checkbox' ? 'extrastyle' : '';
  const dobStyle = (input === 'datepicker' ) ? 'dobstyle' : '';
  const checkedStyle = checked ? 'checkedstyle' : '';

  const renderInput = (innerProps) => {
    const hascontents = inputValue ? 'has-contents' : '';
    const hasError = innerProps.meta.error && innerProps.meta.touched;
    const error = hasError ? 'error' : '';

    let addWrapper = true;
    let fieldControl = null;

    const handleOnChange = (e) => {
      innerProps.input.onChange(e);
      inputOnChange && inputOnChange(e);
    }

    switch(input) {
      case 'text':
      case 'email':
      case 'tel':
      case 'password':
      case 'number':
        fieldControl = <input
          {...inputOptions}
          disabled={disabled}
          placeholder={placeholder}
          type={input}
          className={error}
          name={innerProps.input.name}
          value={innerProps.input.value}
          onChange={handleOnChange}
          onBlur={(e) => { innerProps.input.onBlur(e); }}
          onFocus={(e) => { innerProps.input.onFocus(e); }}
          {...props}
        />
        break;
      case 'textarea':
        fieldControl = <textarea
          {...inputOptions}
          rows={props.rows || 1}
          ref={ref}
          disabled={disabled}
          placeholder={placeholder}
          className={[ error, hascontents ].join(' ')}
          name={innerProps.input.name}
          value={innerProps.input.value}
          onChange={handleOnChange}
          onBlur={(e) => { innerProps.input.onBlur(e); }}
          onFocus={(e) => { innerProps.input.onFocus(e); }}
          />
        break;
      case 'select':
        // Note: to keep the dropdown menu open, put defaultMenuIsOpen="true" in <Select />
        fieldControl = <Select
          options={inputOptions}
          onChange={handleOnChange}
          classNamePrefix="react-select"
          isDisabled={disabled}
          isSearchable={isSearchable}
          placeholder={placeholder}
          value={innerProps.input.value}
          className={[
            'react-select',
            isSearchable && 'searchable',
            innerProps.input.value.value && 'has-selected',
            error
          ].join(' ')}
          {...props}
        />
      break;
      case 'datepicker':
        fieldControl = <DatePicker
          meta={innerProps.meta}
          onChange={innerProps.input.onChange}
          classNamePrefix="react-select"
          isDisabled={disabled}
          // placeholder={placeholder}
          value={innerProps.input.value}
          {...props}
        />
        break;
      case 'flatdatepicker':
        fieldControl = <FlatDatepicker
          onChange={innerProps.input.onChange}
          isDisabled={disabled}
          placeholder={placeholder}
          className={error}
          value={value}
          {...props}
        />
        break;
      case 'multiselect':
        fieldControl = <BadgeGroup
          {...inputOptions}
          disabled={disabled}
          type='checkbox'
          name={name}
          label={label}
          value={innerProps.input.value}
          checked={checked}
          onChange={innerProps.input.onChange}
          isClickable
          {...props}
        />
          break;
      case 'checkbox':
        fieldControl = <Checkbox
          {...inputOptions}
          disabled={disabled}
          type={input}
          name={name}
          label={label}
          value={innerProps.input.value}
          checked={checked}
          isRequired={isRequired}
          onChange={innerProps.input.onChange}
          {...props}
          />
        break;
      case 'checkboxgroup':
      case 'radio':
        const fieldType = input === 'radio' ? 'radio' : 'checkbox';
        fieldControl = <RadioGroup
          {...inputOptions}
          disabled={disabled}
          type={fieldType}
          name={name}
          label={label}
          value={innerProps.input.value}
          checked={checked}
          onChange={innerProps.input.onChange}
          {...props}
          wrapperClass={[ 'field-control', 'w-full', 'extrastyle', checkedStyle].join(' ')}
          />
        addWrapper = false;
        break;
      default:
        break;
        case 'rating':
        fieldControl = <Rating
          {...inputOptions}
          heading={heading}
          isRequired={isRequired}
          {...props}
        />
          break;
    }

    return addWrapper ? <div className={['field-control flex', extraStyle, checkedStyle, dobStyle].join(' ')}>{fieldControl}</div> : fieldControl;
  };

  let validationRules = [];
  if(isRequired) validationRules.push(required);
  if(validation) {
    if(Array.isArray(validation)) {
      validation.forEach(validationRule => validationRules.push(validationRule));
    } else {
      validationRules.push(validation);
    }
  }

  useEffect(() => {
    // get all labels
    const labels = document.querySelectorAll('label');

    // label case hack
    labels.forEach(label => {
      const lTextEl = label.classList.contains('field-control') ? label.querySelector('span') : label;
      const cleanedLabel = lTextEl.innerHTML;
      if (cleanedLabel.split(' ').length > 2 || label.classList.contains('field-control')) {
        if (lTextEl.firstChild?.tagName !== 'SPAN' && lTextEl.firstChild?.tagName !== 'DIV') {
          const firstWord = cleanedLabel.split(' ')[0];
          const spanEl = document.createElement('span');
          spanEl.classList.add('capitalize');
          spanEl.innerText = firstWord;
          lTextEl.className = 'lowercase';
          lTextEl.innerHTML = cleanedLabel.replace(firstWord, '');
          lTextEl.insertBefore(spanEl, lTextEl.firstChild);
        }
      } else {
        lTextEl.className = 'capitalize';
      }
    });
  }, [])


  return (
    <FormField
      name={name}
      validate={composeValidators(...validationRules)}
    >
      {fieldProps => (
        <div className="input-container">
          {/* Note: don't show the following <label> when it is 'radio' or 'checkbox' */}
          { (!hideTopLabel && label) && (
            <div className='input-top'>
              <label>
                { label }
                { (isRequired || isShowingRequired) && <em>*</em> }
              </label>
              { help &&
                  <div className='help-icon'>
                    <button onClick={() => { helpModalRef.current.openModal(); }} type='button'>
                      <Icon name='info-round' />
                    </button>
                    <ModalView ref={helpModalRef} title={modalsubtitle}>
                      <Heading classes='font-todaysbbold mb-2.5 smd:mb-5' h2>{modaltitle}</Heading>
                      <Text body2>{help}</Text>
                    </ModalView>
                  </div>
                }
            </div>
          )}

          { renderInput(fieldProps) }

          { hint && <div className='input-bottom'>
            <div className='input-hint'>
              <Icon name='secure' />
              <p>{ hint }</p>
            </div>
          </div> }

          { fieldProps.meta.error && fieldProps.meta.touched && <InfoCard text={fieldProps.meta.error} childrenComponent={props.errorcomponent} icon='form-error'/> }
        </div>
      )}
    </FormField>
  );
});

Field.propTypes = {
  /**
   * Type of input to show
   */
  input: PropTypes.oneOf([
    'text',
    'email',
    'tel',
    'password',
    'number',
    'textarea',
    'select',
    'checkbox',
    'radio',
    'datepicker',
    'flatdatepicker',
    'multiselect',
    'checkboxgroup',
    'rating',
  ]).isRequired,

  /**
   * inputOptions
   */
  inputOptions: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),

  /**
   * Label to show above the input
   */
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),

  /**
   * errorMessage
   */
  errorMessage: PropTypes.string,

  /**
   * Modal subtitle
   */
  modalsubtitle: PropTypes.string,

  /**
   * Modal title
   */
  modaltitle: PropTypes.string,

   /**
   * Modal text contents
   */
  help: PropTypes.string,

   /**
   * Placeholder text of field
   */
  placeholder: PropTypes.string,

  /**
   * Hint to show under the input
   */
  hint: PropTypes.string,

  /**
   * Validation arguements for field, this are additional to the required attribute
   */
  validation: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),

  /**
   * Hide the label on the input
   */
  hideLabel: PropTypes.bool,

  /**
   * Error component to show
   */
  errorcomponent: PropTypes.node,

  /**
   * Is the field disabled?
   */
  disabled: PropTypes.bool,

  /**
   * Is the field required?
   */
  isRequired: PropTypes.bool,
  isShowingRequired: PropTypes.bool,
  inputOnChange: PropTypes.func,
};

Field.defaultProps = {
  inputOptions: null,
  errorMessage: null,
  errorcomponent: null,
  modalsubtitle: null,
  modaltitle: null,
  help: null,
  hint: null,
  validation: null,
  disabled: false,
  isRequired: false,
  isShowingRequired: false
};