import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import './styles.css';


/**
 * Primary UI component for user interaction
 */
export const RadioGroup = ({ name, options, onChange, updateForm, wrapperClass, type, isEmoji, value, classes, selectedData }) => {
  const [selectedValues, setSelectedValues] = React.useState(selectedData || []);

  useEffect(() => {
    if(value) {
      setSelectedValues(value);
    }
  }, [value]);

  const handleChange = e => {
    if(type === 'checkbox') {
      let newValues = [];
      const selectedValue = e.target.value;
      const isSelected = selectedValues.includes(selectedValue);
      if(isSelected) {
        newValues = selectedValues.filter(val => val !== selectedValue);
      } else {
        newValues = [...selectedValues, selectedValue];
      }
      setSelectedValues(newValues);
      onChange(newValues);

      if (typeof updateForm === 'function') {
        updateForm(e.target, isSelected);
      }
    } else {
      setSelectedValues(e.target.value);
      onChange(e.target.value);
    }
  };

  const isSelected = (val) => {
    if(type === 'checkbox') {
      return selectedValues.includes(val);
    } else {
      return val.toString() === selectedValues.toString();
    }
  }

  return (
    <div className={['radio-group-container', isEmoji && 'emoji-radio-group', classes && classes].join(' ')}>
      { options && options.map((opt, idx) => (
        <label htmlFor={`${name}-${idx}`} className={[isSelected(opt.value) && 'checked', wrapperClass].join(' ')} key={idx}>
          <input name={name} type={type} id={`${name}-${idx}`} value={opt.value} onChange={handleChange} aria-label={opt.value} checked={isSelected(opt.value)} />
          {isEmoji ? <span>{String.fromCodePoint(opt.icon)}</span> : <span>{opt.label}</span>}
        </label>
      ))}
    </div>
  );
};

RadioGroup.propTypes = {
  /**
   * Add your name here
   */
  name: PropTypes.string.isRequired,
  /**
   * Add your radio options here
   */
  options: PropTypes.arrayOf(PropTypes.object).isRequired,
  /**
   * on value Change event
   */
  onChange: PropTypes.func,
  wrapperClass: PropTypes.string,
  /**
   * Is this a radio group or a checkbox group
   */
  type: PropTypes.oneOf(['radio', 'checkbox']),
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  selectedData: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  isEmoji: PropTypes.bool,
  classes: PropTypes.string,
};

RadioGroup.defaultProps = {
    isRequired: false,
    type: 'radio',
    onChange: () => {},
    value: null
};
