import api from "../lib/api";

export const SET_MESSAGES = 'SET_MESSAGES';
export const CLEAR_MESSAGES = 'CLEAR_MESSAGES';

/** actions */

export const getMessages = () => (dispatch) => {
  api.messages((err, responseJSON) => {
      if(responseJSON && responseJSON.data) {
        console.log('getMessages',responseJSON);
        return dispatch(setMessages(responseJSON.data.data));
      }

      console.error("Unable to find messages");
  });
};

export const addMessage = (clientPlanId, message) => (dispatch) => {
  api.addMessage(clientPlanId, message, (err, responseJSON) => {
    dispatch(getMessages());
  });
};

/** actions */
export const setMessages = (payload) => ({
  type: SET_MESSAGES,
  payload,
});

export const clearMessages = () => ({
  type: CLEAR_MESSAGES,
});


/** reducer */
const initialState = [];


export default function reducer(state = initialState, action) {
    const { type, payload } = action;

    switch (type) {
    case SET_MESSAGES:
        return payload;

    case CLEAR_MESSAGES:
        return initialState;

    default:
        return state;
    }
}
