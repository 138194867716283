import React, { useEffect, useState }  from 'react';
import PropTypes from 'prop-types';

import { useFormState } from 'react-final-form';
import { InfoCard } from '../InfoCard';

import './styles.css';

/**
 * Primary UI component for user interaction
 */
export const Rating = ({ heading, isRequired, submitting, classes, onChange }) => {
  const formState = useFormState();
  const [score, setScore] = useState(formState.values.rating);

  const handleClick = (e) => {
    const value = e.target.value;
    setScore(value);

    if (typeof onChange === 'function') onChange(value);
  }

  return (
    <div className={['heading-block', classes && classes].join(' ')}>
      <div className='rating-container flex'>
        <label>{ heading }{ isRequired && <em>*</em> }</label>
      </div>

      <div className='flex gap-2 items-center'>
        <fieldset className='rating' name='rating'>
          <input type="radio" id="star5" name="rating" value="5" onChange={ handleClick } /><label className="full" htmlFor="star5" title="Awesome - 5 stars"></label>
          <input type="radio" id="star4" name="rating" value="4" onChange={ handleClick } /><label className="full" htmlFor="star4" title="Pretty good - 4 stars"></label>
          <input type="radio" id="star3" name="rating" value="3" onChange={ handleClick } /><label className="full" htmlFor="star3" title="Meh - 3 stars"></label>
          <input type="radio" id="star2" name="rating" value="2" onChange={ handleClick } /><label className="full" htmlFor="star2" title="Kinda bad - 2 stars"></label>
          <input type="radio" id="star1" name="rating" value="1" onChange={ handleClick } /><label className="full" htmlFor="star1" title="Sucks big time - 1 star"></label>
        </fieldset>
        <p className='text-30 leading-none'>{ score }</p>
      </div>

      {/* manual validation message - fieldset not treated as a field component */}
      {isRequired && submitting && !score && (
        <InfoCard text='Required' icon='form-error'/>
      )}
    </div>
  );
}

Rating.propTypes = {
  heading: PropTypes.string.isRequired,
  isRequired: PropTypes.bool,
  submitting: PropTypes.bool,
  classes: PropTypes.string,
  onChange: PropTypes.func,
};

Rating.defaultProps = {
  heading: null,
  submitting: false,
  isRequired: false,
  classes: '',
};