import api from "../lib/api";
export const SET_TIMES = 'SET_TIMES';
export const CLEAR_TIMES = 'CLEAR_TIMES';

/** times */

export const getTimes = (date) => (dispatch) => {

  api.times( date, (err, responseJSON) => {
      if(responseJSON && responseJSON.data) {
         return dispatch(setTimes(responseJSON.data));
      }
      console.error("Unable to find times");
  });
};



/** times */
export const setTimes = (payload) => ({
  type: SET_TIMES,
  payload,
});

export const clearTimes = () => ({
  type: CLEAR_TIMES,
});


/** reducer */
const initialState = [];


export default function reducer(state = initialState, action) {
    const { type, payload } = action;

    switch (type) {
    case SET_TIMES:
        return payload;

    case CLEAR_TIMES:
        return initialState;

    default:
        return state;
    }
}
