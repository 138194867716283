import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { MultiForm } from '../../../components/organisms/MultiForm';
import { StickyButtons } from '../../../components/molecules/StickyButtons';
import { ButtonGroup } from '../../../components/molecules/ButtonGroup';
import { Button } from '../../../components/atoms/Button';
import { GettingStarted } from '../../AssessmentFlow/GettingStarted';
import { PathwayReview } from '../../AssessmentFlow/PathwayReview';
import { Motivation } from '../../AssessmentFlow/Motivation';
import { ActivityQuestion } from '../../AssessmentFlow/ActivityQuestion';
import { DrinkingQuestion } from '../../AssessmentFlow/DrinkingQuestion';
import { Summary } from '../../AssessmentFlow/Summary';

import activeSrc from '../../../assets/images/assessment/activity-active.svg';
import sittingSrc from '../../../assets/images/assessment/activity-sitting.svg';

import './styles.css';
import { DrinkingOptions } from '../../AssessmentFlow/DrinkingOptions';
import { BMIQuestions } from '../../AssessmentFlow/BMIQuestions';
import { SmokeAndVape } from '../../AssessmentFlow/SmokeAndVape';
import api from '../../../lib/api';
import { getParameterByName } from '../../../features/geturlparameter';
import { useDispatch, useSelector } from 'react-redux';
import { getPreAssessment } from '../../../features/preassessment';

/**
 * Primary UI component for user interaction
 */
export const Assessment = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const preAssessment = useSelector(state => state.preassessment);
  const [totalPageCount, setTotalPageCount] = useState(12);
  const [pageOffset, setPageOffset] = useState(0);
  const [initial, setInitial] = useState({});

  const token = localStorage.getItem('access_token');
  const hash = getParameterByName('h');
  const reminderType = getParameterByName('reminder_type');

  useEffect(() => {
    dispatch(getPreAssessment(hash));
  }, [dispatch, hash]);

  const onSubmit = (values) => {
    if (! token && ! hash) {
      console.log('not authenticated');
      navigate('/');
      return;
    }

    const authType = typeof hash === 'string' ? 'hash' : 'bearer';

    // only submit field values (ignore labels)
    for (let name of Object.keys(values.assessment)) {
      if (values.assessment[name] && values.assessment[name].value) {
        values.assessment[name] = values.assessment[name].value;
      }
    }

    const handleSubmission = (err, res) => {
      console.log(err, res);
      if(err) {
        console.log(err);
        return;
      }

      if (res.data.status !== 'ok') {
        console.log(res.data.status);
        return;
      }

      navigate('/assessment/complete', {
        state: {
          stats: res.data.stats,
          messages: res.data.messages,
          values: values
        }
      });
    };

    switch (authType) {
      case 'bearer':
        api.newAssessment(values, handleSubmission);
        break;

      case 'hash':
        values.hash = hash;
        values.reminder_type = reminderType && reminderType?.toLowerCase() === 'text' ? 'text' : 'email';
        api.followupAssessment(values, handleSubmission);
      break;

      default:
        console.error('unknown auth type');
        break;
    }
  };

  useEffect(() => {
    setTotalPageCount(preAssessment.assessment_count === 0 ? 13 : 12);
    setPageOffset(preAssessment.assessment_count === 0 ? 1 : 0);

    // set default form values
    const initVals = {};
    for (let key of Object.keys(preAssessment)) {
      if (typeof preAssessment[key] !== 'undefined') initVals[key] = preAssessment[key];
    }
    initVals.active_minutes = 0;
    initVals.sitting_minutes = 0;
    setInitial(initVals);
  }, [preAssessment]);

  return (
    <MultiForm
      name="assessment"
      // initialValues={{ email: 'mark@adi.do', postcode: 'bh1 1jn' }}
      initialValues={initial}
      initialHistory={[0]}
      onSubmit={onSubmit}
      alwaysUseButtonRows={true}
    >
      <MultiForm.Page next={(values, step, nextPage) => {
        if (preAssessment.status !== 'available') {
          navigate('/');
          return;
        }
        nextPage(values, step);
      }}>
        <GettingStarted customButtons={
            <StickyButtons classes='md-sticky-pt-0'>
              <ButtonGroup buttons={[
                <Button label="Get Started" mode="primary" key={1} isSubmit />
              ]} />
            </StickyButtons>
          } />
      </MultiForm.Page>

      <MultiForm.Page next={(values, step, nextPage) => {
        if (preAssessment.assessment_count > 0) step = 2;
        nextPage(values, step);
      }}>
        <PathwayReview currentPage={1} totalPages={totalPageCount} />
      </MultiForm.Page>

      <MultiForm.Page>
        <Motivation currentPage={2} totalPages={totalPageCount} />
      </MultiForm.Page>

      <MultiForm.Page>
        <BMIQuestions currentPage={2 + pageOffset} totalPages={totalPageCount} />
      </MultiForm.Page>

      <MultiForm.Page>
        <ActivityQuestion
          totalPages={totalPageCount}
          imageSrc={activeSrc}
          step={3 + pageOffset}
          stepText={`Step ${3 + pageOffset} of ${totalPageCount}`}
          title='On an average day, how long are you moderately active? '
          description='Moderate activity is anything that makes you feel warmer and breathe harder, but you can still hold a conversation.'
          hourFieldName='assessment[active_hours]'
          minFieldName='assessment[active_minutes]'
        />
      </MultiForm.Page>

      <MultiForm.Page>
        <ActivityQuestion
          totalPages={totalPageCount}
          imageSrc={sittingSrc}
          step={4 + pageOffset}
          stepText={`Step ${4 + pageOffset} of ${totalPageCount}`}
          title='On an average day, how much time do you spend sitting? '
          description='This may include time spent sitting at a desk, or sitting watching television. Do not include time spent sleeping.'
          hourFieldName='assessment[sitting_hours]'
          minFieldName='assessment[sitting_minutes]'
        />
      </MultiForm.Page>

      <MultiForm.Page>
        <SmokeAndVape currentPage={5 + pageOffset} totalPages={totalPageCount} />
      </MultiForm.Page>

      <MultiForm.Page next={(values, step, nextPage) => {
        if(values.assessment.alcohol_drink_frequency.toLowerCase() === '0') step = 6;
        nextPage(values, step);
      }}>
        <DrinkingQuestion
          step={6 + pageOffset}
          stepText={`Step ${6 + pageOffset} of ${totalPageCount}`}
          title='How often do you have a drink containing alcohol?'
          radioGroupArray={[
            {
              name: 'assessment[alcohol_drink_frequency]',
              key: 'alcohol_drink_frequency',
              options: [
                {value: '0', label: 'Never', key: 'alcohol_drink_frequency_1'},
                {value: '1', label: 'Monthly or less', key: 'alcohol_drink_frequency_2'},
                {value: '2', label: '2 - 4 times per month', key: 'alcohol_drink_frequency_3'},
                {value: '3', label: '2 -3 times per week', key: 'alcohol_drink_frequency_4'},
                {value: '4', label: '4+ times per week', key: 'alcohol_drink_frequency_5'},
              ],
            }
          ]}
        />
      </MultiForm.Page>

      <MultiForm.Page>
        <DrinkingOptions currentPage={7 + pageOffset} totalPages={totalPageCount} />
      </MultiForm.Page>

      <MultiForm.Page next={(values, step, nextPage) => {
        if(values.assessment.alcohol_how_often_four.toLowerCase() === '0') step = 4;
        nextPage(values, step);
      }}>
        <DrinkingQuestion
          stepText={`Step ${8 + pageOffset} of ${totalPageCount}`}
          step={8 + pageOffset}
          title='How often do you have six or more units in one session? '
          radioGroupArray={[
            {
              name: 'assessment[alcohol_how_often_four]',
              key:'alcohol_how_often_four',
              options: [
                {value: '0', label: 'Never', key: 'alcohol_how_often_four_1'},
                {value: '1', label: 'Less than monthly', key: 'alcohol_how_often_four_2'},
                {value: '2', label: 'Monthly', key: 'alcohol_how_often_four_3'},
                {value: '3', label: 'Weekly', key: 'alcohol_how_often_four_4'},
                {value: '4', label: 'Daily or almost daily', key: 'alcohol_how_often_four_5'},
              ],
            }
          ]}
        />
      </MultiForm.Page>

      <MultiForm.Page next={(values, step, nextPage) => {
        if(values.assessment.assessment_audit_confirm.toLowerCase() === 'no') step = 3;
        nextPage(values, step);
      }}>
        <DrinkingQuestion
          stepText={`Step ${9 + pageOffset} of ${totalPageCount}`}
          step={9 + pageOffset}
          title='Are you happy to answer a few more questions about your drinking activity?'
          cardDescription='Answering these questions will help us provide more accurate advice, and will only take a minute!'
          radioGroupArray={[
            {
              name: 'assessment[assessment_audit_confirm]',
              key: 'assessment_audit_confirm',
              options: [
                {label: 'Yes', value: 'Yes', key: 'assessment_audit_confirm_1'},
                {label: 'No', value: 'No', key: 'assessment_audit_confirm_2'},
              ],
            }
          ]}
        />
      </MultiForm.Page>

      <MultiForm.Page>
        <DrinkingQuestion
          step={10 + pageOffset}
          stepText={`Step ${10 + pageOffset} of ${totalPageCount}`}
          title='Tell us about your drinking activity in the last year'
          description=''
          titleWithStar={false}
          radioGroupArray={[
            {
              title: 'How often were you not able to stop drinking once you had started?',
              name: 'assessment[alcohol_unable_to_stop]',
              key: 'alcohol_unable_to_stop',
              options: [
                {value: '0', label: 'Never', key: 'alcohol_unable_to_stop_1'},
                {value: '1', label: 'Less than monthly', key: 'alcohol_unable_to_stop_2'},
                {value: '2', label: 'Monthly', key: 'alcohol_unable_to_stop_3'},
                {value: '3', label: 'Weekly', key: 'alcohol_unable_to_stop_4'},
                {value: '4', label: 'Daily or almost daily', key: 'alcohol_unable_to_stop_5'},
              ],
            },
            {
              title: 'How often have you failed to do what was expected from you due to your drinking?',
              name: 'assessment[alcohol_failed_to_do]',
              key: 'alcohol_failed_to_do',
              options: [
                {value: '0', label: 'Never', key: 'alcohol_failed_to_do_1'},
                {value: '1', label: 'Less than monthly', key: 'alcohol_failed_to_do_2'},
                {value: '2', label: 'Monthly', key: 'alcohol_failed_to_do_3'},
                {value: '3', label: 'Weekly', key: 'alcohol_failed_to_do_4'},
                {value: '4', label: 'Daily or almost daily', key: 'alcohol_failed_to_do_5'},
              ],
            },
            {
              title: 'How often have you needed an alcoholic drink to get yourself going the morning after a heavy drinking session?',
              name: 'assessment[alcohol_morning_drink]',
              key: 'alcohol_morning_drink',
              options: [
                {value: '0', label: 'Never', key: 'alcohol_morning_drink_1'},
                {value: '1', label: 'Less than monthly', key: 'alcohol_morning_drink_2'},
                {value: '2', label: 'Monthly', key: 'alcohol_morning_drink_3'},
                {value: '3', label: 'Weekly', key: 'alcohol_morning_drink_4'},
                {value: '4', label: 'Daily or almost daily', key: 'alcohol_morning_drink_5'},
              ],
            },
            {
              title: 'How often have you had a feeling of guilt or remorse after drinking?',
              name: 'assessment[alcohol_guilt]',
              key: 'alcohol_guilt',
              options: [
                {value: '0', label: 'Never', key: 'alcohol_guilt_1'},
                {value: '1', label: 'Less than monthly', key: 'alcohol_guilt_2'},
                {value: '2', label: 'Monthly', key: 'alcohol_guilt_3'},
                {value: '3', label: 'Weekly', key: 'alcohol_guilt_4'},
                {value: '4', label: 'Daily or almost daily', key: 'alcohol_guilt_5'},
              ],
            },
            {
              title: 'How often have you been unable to remember the night before due to drinking?',
              name: 'assessment[alcohol_loss_memory]',
              key: 'alcohol_loss_memory',
              options: [
                {value: '0', label: 'Never', key: 'alcohol_loss_memory_1'},
                {value: '1', label: 'Less than monthly', key: 'alcohol_loss_memory_2'},
                {value: '2', label: 'Monthly', key: 'alcohol_loss_memory_3'},
                {value: '3', label: 'Weekly', key: 'alcohol_loss_memory_4'},
                {value: '4', label: 'Daily or almost daily', key: 'alcohol_loss_memory_5'},
              ],
            }
          ]}
        />
      </MultiForm.Page>

      <MultiForm.Page>
        <DrinkingQuestion
          step={11 + pageOffset}
          stepText={`Step ${11 + pageOffset} of ${totalPageCount}`}
          title='A few final questions about your drinking activity'
          description='Based on your score we’d like to ask a few more questions to better understand how your drinking habits might be impacting you.'
          titleWithStar={false}
          radioGroupArray={[
            {
              title: 'Have you or somebody else been injured as a result of your drinking?',
              name: 'assessment[alcohol_caused_injury]',
              key: 'alcohol_caused_injury',
              options: [
                {value: '0', label: 'No', key: 'alcohol_caused_injury_1'},
                {value: '2', label: 'Yes, but not in the last year', key: 'alcohol_caused_injury_2'},
                {value: '4', label: 'Yes, during the last year', key: 'alcohol_caused_injury_3'},
              ],
            },
            {
              title: 'Has a relative or friend, doctor or other health worker been concerned about your drinking or suggested that you cut down?',
              name: 'assessment[alcohol_others_concerned]',
              key: 'alcohol_others_concerned',
              options: [
                {value: '0', label: 'No', key: 'alcohol_others_concerned_1'},
                {value: '2', label: 'Yes, but not in the last year', key: 'alcohol_others_concerned_2'},
                {value: '4', label: 'Yes, during the last year', key: 'alcohol_others_concerned_3'},
              ],
            }
          ]}
        />
      </MultiForm.Page>

      <MultiForm.Page>
        <Summary
          currentPage={12 + pageOffset}
          totalPages={totalPageCount}
          pageOffset={pageOffset}
          customButtons={
            <StickyButtons classes='md-sticky-pt-0'>
              <ButtonGroup buttons={[
                <Button label="Submit" mode="primary" key={1} isSubmit />
              ]} />
            </StickyButtons>
          }
        />
      </MultiForm.Page>

    </MultiForm>
  );
};


Assessment.propTypes = {
};

Assessment.defaultProps = {
};

