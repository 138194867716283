import React from 'react';
import PropTypes from 'prop-types';
import { useFormState } from 'react-final-form';

import { MainView } from '../../../templates/MainView';
import { Text } from '../../../components/atoms/Text';
import { Heading } from '../../../components/atoms/Heading';
import { Field } from '../../../components/molecules/Field';
import { ProgressBar } from '../../../components/molecules/ProgressBar';
import { useDropDown } from '../../../lib/useDropdown';

/**
 * Primary UI component for user interaction
 */
export const SmokeAndVape = ({ buttons, currentPage, totalPages }) => {
  const smokingTypes = useDropDown('SmokingType', (values) => values
    .filter(obj => ['Non-Smoker', 'e-Cigarettes'].indexOf(obj.label) === -1)
    .sort((a,b) => a.value - b.value)
  );
  const vapeVolumes = useDropDown('vapeVolumes');

  const radiosData = [
    {label: 'I smoke', value: 'smoke', key: '1'},
    {label: 'I vape', value: 'vape', key: '2'},
    {label: 'I smoke and vape', value: 'smoke and vape', key: '3'},
    {label: 'I don’t smoke or vape', value: 'neither', key: '4'},
  ];

  const radiosData2 = [
    {label: 'I smoke daily', value: 'I smoke daily', key: '1'},
    {label: 'I smoke less than daily', value: 'I smoke less than daily', key: '2'},
  ];

  const radiosData3 = [
    {label: 'Less than monthly', value: 'less than monthly', key: '1'},
    {label: 'Monthly', value: 'monthly', key: '2'},
    {label: 'Weekly', value: 'weekly', key: '3'},
  ];

  const radiosData4 = [
    {label: 'I vape daily', value: 'I vape daily', key: '1'},
    {label: 'I vape less than daily', value: 'I vape less than daily', key: '2'},
  ];

  const radiosData5 = [
    {label: 'Less than monthly', value: 'less than monthly', key: '1'},
    {label: 'Monthly', value: 'monthly', key: '2'},
    {label: 'Weekly', value: 'weekly', key: '3'},
  ];


  const formState = useFormState();
  const getValue = (name) => {
    // let val = (typeof formState.values[name] === 'string' ? formState.values[name] : '');
    switch (name) {
      case 'smoking_type':
        const type = smokingTypes.find(type => type.value === parseInt(formState.values.assessment[name]));
        return type ? type.label : formState.values.assessment[name];

      default:
        if(typeof formState.values.assessment[name] === 'string') {
          return formState.values.assessment[name];
        } else if(formState.values.assessment[name]) {
          return formState.values.assessment[name].value;
        }
      break;
    }

    return '';
  }

  return (
    <MainView BPadding2>
      <ProgressBar min={1} max={totalPages} current={currentPage} label='Quick health check' />

      <div className='px-6 pt-8 pb-14 smd:p-12 flex flex-col'>
        <Text classes='mb-2' body>{`Step ${currentPage} of ${totalPages}`}</Text>
        <Heading classes='mb-8' h1>Do you smoke or vape? <span className='text-error-500'>*</span></Heading>

        <div className='flex flex-col space-y-8 smd:space-y-14 pb-10 smd:pb-0'>
          <Field
            input='radio'
            name='assessment[smoking_smoke_or_vape]'
            label=''
            inputOptions={{
              options: radiosData
            }}
            isRequired
          />

          {['smoke and vape', 'smoke'].includes(getValue('smoking_smoke_or_vape')) && <>

          <Field
            input='radio'
            name='assessment[smoking_type]'
            label='Which of the below do you smoke the most?'
            inputOptions={{
              options: smokingTypes
            }}
            isRequired
          />

          {getValue('smoking_type') && getValue('smoking_type').toLowerCase() === 'other' && (<Field
            input='text'
            name='assessment[smoking_type_other]'
            label='What do you smoke?'
            placeholder='Enter what you smoke'
            isRequired
          />)}

          <Field
            input='radio'
            name='assessment[smoking_daily]'
            label='Do you smoke daily?'
            inputOptions={{
              options: radiosData2
            }}
            isRequired
          />

          {getValue('smoking_daily') === 'I smoke less than daily' && (<Field
            input='radio'
            name='assessment[smoking_frequency]'
            label='How often do you smoke?'
            inputOptions={{
              options: radiosData3
            }}
            isRequired
          />)}

          {getValue('smoking_daily') === 'I smoke daily' && (<Field
            input='text'
            name='assessment[smoking_numbers]'
            label='How many do you smoke a day?'
            isRequired
          />)}

          <div className="input-container">
            <div className='input-top'>
              <label htmlFor="">How long have you been smoking?</label>
            </div>

            <div className='flex flex-row gap-4 w-full'>
              <Field
                input='text'
                name='assessment[smoking_time_years]'
                label='Years'
                placeholder='Year'
                isRequired
              />

              <Field
                input='text'
                name='assessment[smoking_time_months]'
                label='Months'
                placeholder='Month'
                isRequired
              />
            </div>
          </div>
          </>
        }

        {['smoke and vape', 'vape'].includes(getValue('smoking_smoke_or_vape')) && <>
          <Field
            input='radio'
            name='assessment[smoking_vape_daily]'
            label='Do you vape daily?'
            inputOptions={{
              options: radiosData4
            }}
            isRequired
          />

          {getValue('smoking_vape_daily') === 'I vape less than daily' && (<Field
            input='radio'
            name='assessment[smoking_vape_frequency]'
            label='How often do you vape?'
            inputOptions={{
              options: radiosData5
            }}
            isRequired
          />)}

          <Field
            input='select'
            name='assessment[smoking_vape_nicotine]'
            label='What nicotine strength do you use?'
            inputOptions={vapeVolumes}
            isRequired
            placeholder='Please Select'
            // modalsubtitle='Why do we ask this?'
            // modaltitle='Nicotine'
            // help= 'This is some help text.'
          />

          <div className="input-container">
            <div className='input-top'>
              <label htmlFor="">How long have you been vaping?<em>*</em></label>
            </div>
            <div className='flex flex-row gap-4 w-full'>
              <Field
                input='text'
                name='assessment[smoking_vape_time_years]'
                label='Years'
                placeholder='Year'
                isRequired
              />

              <Field
                input='text'
                name='assessment[smoking_vape_time_months]'
                label='Months'
                placeholder='Month'
                isRequired
              />
            </div>
          </div>
        </> }
        </div>

        { buttons }
      </div>
    </MainView>
  );
};

SmokeAndVape.propTypes = {
  buttons: PropTypes.element,
  currentPage: PropTypes.number,
  totalPages: PropTypes.number,
};

SmokeAndVape.defaultProps = {
  buttons: null,
  totalPages: 5,
  currentPage: 5,
};