import React from 'react';
import PropTypes from 'prop-types';

import './styles.css';

/**
 * Primary UI component for user interaction
 */
export const StickyButtons = ({ children, classes }) => (
  <div className={[
    'sticky-buttons',
    classes && classes
    ].join(' ')}
  >
    { children }
  </div>
);

StickyButtons.propTypes = {
  classes: PropTypes.string,
};

StickyButtons.defaultProps = {
  
};