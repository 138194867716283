import React from 'react';
import PropTypes from 'prop-types';
import './styles.css';

/**
 * Primary UI component for user interaction
 */
export const ButtonGroup = ({ isStacked, alwaysRow, buttons, classes }) => {
    const mode = buttons.length === 1 ? 'single' : 'pair';

    return (
    <div className={[
        mode,
        isStacked && 'stacked',
        alwaysRow && 'always-row',
        classes && classes
    ].join(' ')}>
        { buttons }
    </div>
    );
};

ButtonGroup.propTypes = {
   /**
   * Is this buttons should be stacked?
   */
  isStacked: PropTypes.bool,
  /**
   * Add your buttons here
   */
  buttons: PropTypes.oneOfType([PropTypes.object, PropTypes.array]).isRequired
};

ButtonGroup.defaultProps = {
  isStacked: false,
};
