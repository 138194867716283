import React from 'react';
import PropTypes from 'prop-types';
import { MainView } from '../../templates/MainView';
import { Image } from '../../components/atoms/Image';
import { ButtonGroup } from '../../components/molecules/ButtonGroup';
import { Button } from '../../components/atoms/Button';
import { HeadingBlock } from '../../components/molecules/HeadingBlock';
import { StickyButtons } from '../../components/molecules/StickyButtons';

import balloons from '../../assets/images/registation/balloons.svg'

import './styles.css';

/**
 * Primary UI component for user interaction
 */
export const ThankYou = (props) => {
  const { isImageSmall, imageSrc, imageClass, title, description, onClick, isSubmit, isStickyBtns, hideImage, isStackedBtns, buttonLabel, secondBtnLabel, secondBtnOnClick, secondBtnIsSubmit, highlightedAction } = props;

  const buttons = [
    <Button label={buttonLabel} mode='primary' onClick={onClick} isSubmit={isSubmit} key={1} />
  ];

  if (secondBtnLabel) buttons.push(<Button label={secondBtnLabel} mode='secondary' onClick={secondBtnOnClick} isSubmit={secondBtnIsSubmit} key={2} />);

  return (
    <MainView>
      <div className='px-6 pt-8 pb-28 smd:p-12'>
        {! hideImage && (<Image
          src={imageSrc || balloons}
          alt='feedback related image'
          containerClasses={['thankyou-image-container', isImageSmall && 'small', 'flex', 'justify-center', imageClass].join(' ')}
          imgClass='object-contain'
        />)}
        <HeadingBlock heading={title} text={description} h1/>
        {highlightedAction}

        {isStickyBtns ? (
          <StickyButtons>
            <ButtonGroup isStacked={isStackedBtns} buttons={buttons} />
          </StickyButtons>
        ) : (
          <div className='w-full pt-2'>
            <ButtonGroup isStacked classes='mx-auto' buttons={buttons} />
          </div>
        )}

      </div>
    </MainView>
  );
};

ThankYou.propTypes = {
   /***
    * Image source
    **/
  imageSrc: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired, /* title of the feedback page */
  description: PropTypes.string.isRequired, /* description of the feedback page */
  onClick: PropTypes.func,
  isSubmit: PropTypes.bool,
  isImageSmall: PropTypes.bool,
  imageClass: PropTypes.string,
  hideImage: PropTypes.bool,
  buttonLabel: PropTypes.string,
  highlightedAction: PropTypes.array,
  secondBtnLabel: PropTypes.string,
  secondBtnOnClick: PropTypes.func,
  isStackedBtns: PropTypes.bool,
  secondBtnOnClick: PropTypes.bool,
  isStickyBtns: PropTypes.bool,
};

ThankYou.defaultProps = {
  isImageSmall: false,
  hideImage: false,
  imageClass: '',
  isStackedBtns: false,
  isStickyBtns: true,
  buttonLabel: 'Continue',
  highlightedAction: [],
  onClick: () => {},
};

