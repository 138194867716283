const postcode = value => {
  const errorMsg = 'Please enter a valid postcode';
  if (! value) return errorMsg;

  value = value.replace(/\s/g, "");
  const regex = /^[A-Z]{1,2}[0-9]{1,2} ?[0-9][A-Z]{2}$/i;
  const isValid = regex.test(value);
  return isValid ? undefined : errorMsg;
};

const dorsetDistricts = [
    'BA21','BA22',
    'BH1','BH2','BH3','BH4','BH5','BH6','BH7','BH8','BH9','BH10','BH11',
    'BH12','BH13','BH14','BH15','BH16','BH17','BH18','BH19','BH20','BH21',
    'BH22','BH23','BH24','BH25','BH31',
    'DT1','DT2','DT3','DT4','DT5','DT6','DT7','DT8','DT9','DT10','DT11',
    'SP5','SP6','SP7','SP8',
    'TA18','TA20',
];

export const isDorsetPostcode = (postcode) => {
    let isValid = false;
    for (let areaCode of dorsetDistricts) {
        const postArea = postcode.toUpperCase().substring(0, areaCode.length);
        if (areaCode === postArea) {
            isValid = true;
            break;
        }
    }

    console.log(postcode, isValid);

    return isValid;
}

export default postcode;