import React from 'react';
import PropTypes from 'prop-types';
import { Text } from '../../atoms/Text';
import { Icon } from '../../atoms/Icon';

/**
 * Primary UI component for user interaction
 */
export const PreferenceItem = ({ title, icon, classes }) => {
  return (
    <div className={['flex items-center mb-4 text-primary-500', classes].join(' ')}>
      <div className='flex justify-center w-10'>
        <Icon name={icon} key={`${icon}-preference-item`} /> 
      </div>
      <Text body classes='ml-5 text-lwd-grey-900'>{title}</Text>
    </div>
  );
};

PreferenceItem.propTypes = {
  title: PropTypes.string,
  icon: PropTypes.string,
  classes: PropTypes.string,
};

PreferenceItem.defaultProps = {
  title: '',
  icon: '',
  classes: '',
};