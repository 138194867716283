import { setAlert } from './alert';
import api from '../lib/api';
import { setProfile } from './profile';


export const LOGIN = 'LOGIN';
export const LOGGEDIN = 'LOGGEDIN';
export const LOGOUT = 'LOGOUT';

/** actions */

export const attemptLogin = (username, dob, password, cb) => (dispatch) => {
    api.login(username, dob, password, (valid, res) => {
        console.log("valid", valid)
        if (valid) {
            localStorage.setItem('access_token', res.access_token);
            // localStorage.setItem('refresh_token', res.refresh_token);
            console.log("pinging profile")
            dispatch(pingUser());
        } else {
            dispatch(setAlert('Invalid username/password'));
        }

        if (typeof cb === 'function') cb(valid, res);
    });
};

export const pingUser = () => (dispatch) => {
    api.me((err, responseJSON) => {
        if (!responseJSON) {
            // api.clearUser();
            return dispatch({
                type: LOGOUT
            });
            // return dispatch(setAlert('Can\'t find user'));
        } else {
          dispatch({
              type: LOGIN
          });

          dispatch(setProfile(responseJSON.data.data));
        }

        return dispatch({
            type: LOGIN
        });
    });
};

export const checkLogin = () => async (dispatch) => {
    const accessToken = localStorage.getItem('access_token');

    if(accessToken) {
        dispatch(pingUser());
        return dispatch({
            type: LOGIN
        });
    } else {
        return dispatch({
            type: LOGOUT
        });
    }
};

export const logout = (redirect=true) => async (dispatch) => {
    localStorage.clear();
    if(redirect) window.location = '/';
    return dispatch({
        type: LOGOUT
    });
};



/** reducer */
const initialState = { loggedIn: false };


export default function reducer(state = initialState, action) {
    const { type } = action;

    switch (type) {
    case LOGIN:
        return { loggedIn: true };

    case LOGOUT:
        return { loggedIn: false };

    default:
        return state;
    }
}
