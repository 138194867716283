import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { MainView } from '../../../templates/MainView';
import { Text } from '../../../components/atoms/Text';
import { Heading } from '../../../components/atoms/Heading';
import { Field } from '../../../components/molecules/Field';
import { Switch } from '../../../components/molecules/Switch';
import { ProgressBar } from '../../../components/molecules/ProgressBar';
import { useField, useFormState } from "react-final-form";
// import { getPlan } from '../../../features/plan';
import { useSelector } from 'react-redux';
import { isAgeOrOver } from '../../../components/validation/age';
import requiredNumeric from '../../../components/validation/requiredNumeric';
import { useDropDown } from '../../../lib/useDropdown';

/**
 * Primary UI component for user interaction
 */
export const BMIQuestions = ({ buttons, currentPage, totalPages }) => {
  const formState = useFormState();

  const [pounds, setPounds] = useState(0);
  const [stone, setStone] = useState(0);
  const [foot, setFoot] = useState(0);
  const [inch, setInch] = useState(0);
  const [weightSwitchIndex, setWeightSwitchIndex] = useState(()=>{
    if(!formState.values.assessment.weight_metric) {
        formState.values.assessment.weight_metric = 'st';
        return 1;
    }
    return formState.values.assessment.weight_metric === 'kg' ? 0 : 1
  });
  const [heightSwitchIndex, setHeightSwitchIndex] = useState(1);

  const weightKgField = useField('assessment[assessment_weight_kg]');
  const weightStField = useField('assessment[assessment_weight_st]');
  const weightLbsField = useField('assessment[assessment_weight_lbs]');
  const heightCmField = useField('assessment[assessment_height_cm]');
  const heightFtField = useField('assessment[assessment_height_ft]');
  const heightInField = useField('assessment[assessment_height_in]');

  const preAssessment = useSelector(state => state.preassessment);

  const ethnicGroups = useDropDown('ethnicity', (values) => values.filter(obj => ['4 Not Disclosed'].indexOf(obj.label) === -1));

  useEffect(() => {
    if (formState.values.assessment.assessment_height_cm) {
      covertHeight(formState.values.assessment.assessment_height_cm);
    }

    if (formState.values.assessment.assessment_weight_kg) {
      covertWeight(formState.values.assessment.assessment_weight_kg)
    }
  }, [
    formState.values.assessment.assessment_height_cm,
    formState.values.assessment.assessment_weight_kg,
  ]);

  // get the values of weightStField and weightLbsField in the form

  const covertWeight = (kg) => {
    let lb = (kg * 2.204623).toFixed(0);
    const st = Math.floor(lb / 14);
    lb %= 14;

    setPounds(lb);
    setStone(st);

    weightStField.input.onChange(st);
    weightLbsField.input.onChange(lb);
  };

  const covertHeight = (value) => {
    const cm = parseInt(value, 10);
    if (!cm) return;
    let inc = (cm * 0.393700787).toFixed(0);
    const ft = Math.floor(inc / 12);
    inc %= 12;

    setFoot(ft);
    setInch(inc);

    heightFtField.input.onChange(ft);
    heightInField.input.onChange(inc);
  };

  const weightChange = (e) => {
    const weights = {
      st: stone,
      lb: pounds,
    };

    weights.st = weightStField.input.value;
    setStone(weights.st);
    weights.lb = weightLbsField.input.value;
    setPounds(weights.lb);

    if (e.target.name === 'assessment[assessment_weight_st]') {
      weights.st = e.target.value;
      setStone(e.target.value);
    } else if (e.target.name === 'assessment[assessment_weight_lbs]') {
      weights.lb = e.target.value;
      setPounds(e.target.value);
    }

    weights.st = (weights.st) ? weights.st : 0;
    weights.lb = (weights.lb) ? weights.lb : 0;

    let kg = weights.st * 6.35;
    kg += weights.lb * 0.453;
    kg = kg.toFixed(2);

    weightKgField.input.onChange(kg);
  };

  const heightChange = (e) => {
    const heights = {
      ft: foot,
      in: inch,
    };

    heights.ft = heightFtField.input.value;
    setFoot(heights.ft);
    heights.in = heightInField.input.value;
    setInch(heights.in);

    if (e.target.name === 'assessment[assessment_height_ft]') {
      heights.ft = e.target.value;
      setFoot(e.target.value);
    } else if (e.target.name === 'assessment[assessment_height_in]') {
      heights.in = e.target.value;
      setInch(e.target.value);
    }

    heights.ft = (heights.ft) ? heights.ft : 0;
    heights.in = (heights.in) ? heights.in : 0;

    let cm = heights.ft * 30.48;
    cm += heights.in * 2.54;
    cm = cm.toFixed(2);

    heightCmField.input.onChange(cm);
  };

  const toggleWeightUnit = (idx) => {
    setWeightSwitchIndex(idx);
    if (idx === 0) {
      formState.values.assessment.weight_metric = 'kg';
    } else {
      formState.values.assessment.weight_metric = 'st';
    }
  };

  const toggleHeightUnit = (idx) => {
    setHeightSwitchIndex(idx);
  };

  // Note: disable mouse scroll to prevent changing input fields ("number" type) value
  document.addEventListener("wheel", function(event){
    if(document.activeElement.type === "number"){
        document.activeElement.blur();
    }
  });

  return (
    <MainView BPadding2 allowOverflow>
      <ProgressBar min={0} max={totalPages} current={currentPage} label='Quick health check' />

      <div className='px-6 pt-8 pb-14 smd:p-12 flex flex-col'>
        <Text classes='mb-2' body>{`Step ${currentPage} of ${totalPages}`}</Text>
        <Heading classes='mb-2' h1>Let’s calculate your BMI!</Heading>
        <Text classes='mb-8' body>Calculating your Body Mass Index (BMI) can be helpful in identifying if you are within a healthy weight range.</Text>

        <div className='flex flex-col space-y-8 smd:space-y-14 pb-10 smd:pb-0'>
          <div className='flex flex-col items-end'>
            {weightSwitchIndex === 0 ? (
              <div className='relative w-full'>
                <Field
                  input='number'
                  name='assessment[assessment_weight_kg]'
                  label='Weight'
                  placeholder='Weight'
                  // modalsubtitle='Why do we ask this?'
                  // modaltitle='Weight'
                  // help='This is some help text'
                  isRequired
                  inputOnChange={(e) => {
                    covertWeight(e.target.value);
                  }}
                />
                <Heading classes='absolute right-4 top-lwd-53.5' h3>kg.</Heading>
              </div>
            ) : (
              <div className='flex flex-row gap-4 w-full items-start'>
                <div className='relative w-full'>
                  <Field
                    input='number'
                    name='assessment[assessment_weight_st]'
                    label='Weight'
                    placeholder='Stone'
                    isShowingRequired
                    validation={[requiredNumeric]}
                    inputOnChange={weightChange}
                  />
                  <Heading classes='absolute right-4 top-lwd-53.5' h3>st.</Heading>
                </div>

                <div className='relative w-full mt-lwd-35 smd:mt-lwd-39'>
                  <Field
                    input='number'
                    name='assessment[assessment_weight_lbs]'
                    placeholder='Pounds'
                    hideLabel
                    validation={[requiredNumeric]}
                    value={pounds}
                    inputOnChange={weightChange}
                  />
                  <Heading classes='absolute right-4 top-lwd-14.5' h3>lbs.</Heading>
                </div>
              </div>
            )}

            <Switch classes='mt-2' labels={['kg.', 'st.']} selectedIndex={1} onChange={toggleWeightUnit} />
          </div>

          <div className='flex flex-col items-end'>
            {heightSwitchIndex === 0 ? (
              <div className='relative w-full'>
                <Field
                  input='number'
                  name='assessment[assessment_height_cm]'
                  label='Height'
                  placeholder='Height'
                  inputOnChange={(e) => {
                    covertHeight(e.target.value);
                  }}
                  isRequired
                />
                <Heading classes='absolute right-4 top-lwd-53.5' h3>cm.</Heading>
              </div>
            ) : (
              <div className='flex flex-row gap-4 w-full items-start'>
                <div className='relative w-full'>
                  <Field
                    input='number'
                    name='assessment[assessment_height_ft]'
                    label='Height'
                    placeholder='Foot'
                    isShowingRequired
                    validation={[requiredNumeric]}
                    inputOnChange={heightChange}
                  />
                  <Heading classes='absolute right-4 top-lwd-53.5' h3>ft.</Heading>
                </div>

                <div className='relative w-full mt-lwd-35 smd:mt-lwd-39'>
                  <Field
                    input='number'
                    name='assessment[assessment_height_in]'
                    placeholder='Inch'
                    hideLabel
                    validation={[requiredNumeric]}
                    inputOnChange={heightChange}
                  />
                  <Heading classes='absolute right-4 top-lwd-14.5' h3>in.</Heading>
                </div>
              </div>
            )}

            <Switch classes='mt-2' labels={['cm.', 'in.']} selectedIndex={1} onChange={toggleHeightUnit} />
          </div>

          {preAssessment.assessment_count === 0 && (<Field
            input='select'
            name='assessment[ethnicity]'
            label='Ethnicity'
            placeholder='Please select'
            isRequired
            inputOptions={ethnicGroups}
          />)}

          {preAssessment.gender !== 'Male' && !isAgeOrOver(preAssessment.dob, 65) && (
            <Field
              input='radio'
              name='assessment[assessment_pregnant]'
              label='Are you currently pregnant?'
              inputOptions={{
                options: [
                  {value: 'Yes', label: 'Yes', key: '1'},
                  {value: 'No', label: 'No', key: '2'},
                ]
              }}
              isRequired
            />
          )}
        </div>

        { buttons }
      </div>
    </MainView>
  );
};

BMIQuestions.propTypes = {
  buttons: PropTypes.element,
  currentPage: PropTypes.number,
  totalPages: PropTypes.number,
};

BMIQuestions.defaultProps = {
  buttons: null,
  totalPages: 1,
  currentPage: 1,
};