import React, { useState, useEffect, forwardRef, useImperativeHandle } from 'react';
import ReactModal from 'react-modal';
import PropTypes from 'prop-types';

import { Icon } from '../../components/atoms/Icon';
import { Heading } from '../../components/atoms/Heading';

import './styles.css';

// Note: bind modal to your appElement
ReactModal.setAppElement('#root');

/**
 * Primary UI component for user interaction
 */
export const ModalView = forwardRef((props, ref) => {
  const { title, extraTitle, subtitle, children, isModalOpen, classes, overlayClasses, parentSelector, contentLabel, type } = props;
  const [modalIsOpen, setModalIsOpen] = useState(isModalOpen !== null ? isModalOpen : false);

  const openModal = () => {
    setModalIsOpen(true);
  }

  const closeModal = () => {
    setModalIsOpen(false);
  }

  const toggleModal = () => {
    modalIsOpen ? setModalIsOpen(false) : setModalIsOpen(true);
  }

  useImperativeHandle(ref, () => ({
    openModal: () => {
      openModal();
    },
    closeModal: () => {
      closeModal();
    },
    toggleModal: () => {
      toggleModal();
    },
    isModalOpen: () => modalIsOpen
  }));

  // Note: prevent background contents scrolling when modal is open
  useEffect(() => {
    modalIsOpen && (document.body.style.overflow = 'hidden');
    !modalIsOpen && (document.body.style.overflow = 'unset');
  }, [ modalIsOpen ]);

  return (
    <ReactModal
      isOpen={modalIsOpen}
      onRequestClose={closeModal}
      contentLabel={contentLabel}
      parentSelector={parentSelector}
      className={['modal', classes && classes].join(' ')}
      overlayClassName={['overlay', overlayClasses && overlayClasses].join(' ')}
    >
      <div className='modal-top'>
        <div className='modal-top-title'>
          { type === "blackTitleOnly" ?
            <Heading h2>{title}</Heading> : 
            <p className='font-todaysbbold text-16 leading-normal text-secondary-900'>{title}{subtitle && (<span> - {subtitle}</span>)}</p>
          }
        </div>
        
        <button onClick={closeModal} type='button'><Icon name='exit-button' /></button>
      </div>
      
      <div className='modal-content'>
        { type === "extraTitle" ? 
          <Heading h2>{extraTitle}</Heading> : 
          ""
        }
        {children}
      </div>
    </ReactModal>
    );
});

ModalView.propTypes = {
  isModalOpen: PropTypes.bool,
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.array]).isRequired,
  title: PropTypes.string,
  classes: PropTypes.string,
  overlayClasses: PropTypes.string,
};

ModalView.defaultProps = {
  isModalOpen: null,
  title: null,
  classes: null,
};
