import React from 'react';
import PropTypes from 'prop-types';
import './styles.css';

import { Icon } from '../../atoms/Icon';

/**
 * Primary UI component for user interaction
 */
export const Image = ({ src, alt, square, round, icon, containerClasses, imgClass }) =>(
  <div className={[
    'image-container',
    'image',
    square && 'square',
    round && 'round',
    containerClasses && containerClasses
    ].join(' ')}
  >
    <img src={src} alt={alt} className={[
      'image',
      square && 'square',
      round && 'round',
      imgClass && imgClass
      ].join(' ')}
    />
    {icon && <Icon name={icon} />}
  </div>
);

Image.propTypes = {

  /**
   * Image source
   */
  src: PropTypes.string.isRequired,

  /**
   * Alt text for image
   */
  alt: PropTypes.string.isRequired,

  /**
   * Square image
   */
  square: PropTypes.bool,
  
  /**
   * Round image
   */
  round: PropTypes.bool,
  /**
   * Round image
   */
  icon: PropTypes.bool,
  containerClasses: PropTypes.string,
  imgClass: PropTypes.string,
};

Image.defaultProps = {
  square: false,
  round: false,
  icon: false,
  containerClasses: null,
  imgClass: null,
};
