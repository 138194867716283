import React from 'react';
import PropTypes from 'prop-types';
import './styles.css';

/**
 * Primary UI component for user interaction
 */
export const Heading = ({ children, ...props }) => {
  if(props.h1) return (<h1 className={props.classes}>{children}</h1>);
  if(props.h2) return (<h2 className={props.classes}>{children}</h2>);
  if(props.h3) return (<h3 className={props.classes}>{children}</h3>);
  if(props.h4) return (<h4 className={props.classes}>{children}</h4>);
  return (<h5 className={props.classes}>{children}</h5>);
};

Image.propTypes = {

  /**
   * Text of heading
   */
   children: PropTypes.string.isRequired,

  /**
   * is this a h1?
   */
  h1: PropTypes.bool,
  
  /**
   * is this a h2?
   */
  h2: PropTypes.bool,
  
  /**
   * is this a h3?
   */
  h3: PropTypes.bool,
  
  /**
   * is this a h4?
   */
  h4: PropTypes.bool,
  
  /**
   * is this a h5?
   */
  h5: PropTypes.bool,
  classes: PropTypes.string,
};

Image.defaultProps = {
  h1: false,
  h2: false,
  h3: false,
  h4: false,
  h5: false,
  classes: null,
};
