import React, { useRef } from 'react';
import PropTypes from 'prop-types';

import { Icon } from '../../atoms/Icon';
import { ModalView } from '../../../templates/ModalView';
import { Text } from '../../atoms/Text';

import './styles.css';

/**
 * Primary UI component for user interaction
 */
export const Badge = ({ icon, title, extraTitle, type, helpText, text, children, onChange, checked, isClickable, tickIfNotClickable, value, classes }) => {
  const helpModalRef = useRef(null);

  const handleChange = () => {
    onChange(value);
  }

  const tickRound = checked ? 'tick-round' : '';
  const addBorder = checked ? 'add-border' : '';

  if(isClickable) {
    return (
      <div className='badge-container'>
        <div tabIndex='0' className={[ 'badge', 'clickable', addBorder ].join(' ')} onClick={handleChange}>
          <div className='badge-icon'>
            <Icon name={icon} />
          </div>
          <div className={[ 'tick', tickRound ].join(' ')}>
            <Icon name='tick' />
          </div>
          { text }
        </div>
      </div>
    );
  }

  return (
      <div className={['badge-container', classes && classes].join(' ')}>
        <div className='badge'>
          {icon &&
            <div className='badge-icon'>
              <Icon name={icon} />
            </div>
          }
          {tickIfNotClickable && <div className={[ 'tick', tickRound ].join(' ')}>
            <Icon name='tick' />
          </div>}
          {helpText &&
            <div className="info-icon">
              <button onClick={() => { helpModalRef.current.openModal(); }} type='button'>
                <Icon name='info-round' />
              </button>
              <ModalView key={1} ref={helpModalRef} title={title} extraTitle={extraTitle} type={type} >
                <Text body2>{helpText}</Text>
              </ModalView>
            </div>
          }
          {text && text}
          {children && children}
        </div>
      </div>
    );
};

Badge.propTypes = {
   /**
   * The icon to show on the badge
   */
  icon: PropTypes.string,

  /**
   * Help text on hover
   */
  helpText: PropTypes.string,
  
  /**
   * Many badge text
   */
  text: PropTypes.string,

  /**
   * onChange event for badge, This will change the colour to purple instead of blue
   */
   onChange: PropTypes.func,

  /**
    * Default action for checked state
    */
  checked: PropTypes.bool,

  /**
    * This will make the badge clickable
    */
  isClickable: PropTypes.bool,

  /**
    * This will make tick be displayed for non-clickable badges
    */
   tickIfNotClickable: PropTypes.bool,
};

Badge.defaultProps = {
  helpText: null,
  text: null,
  onChange: null,
  checked: false,
  isClickable: false,
  tickIfNotClickable: false,
};
