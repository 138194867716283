import React from 'react';
import { useNavigate } from 'react-router-dom';

import { Button } from '../../../components/atoms/Button';
import { ButtonGroup } from '../../../components/molecules/ButtonGroup';
import { StickyButtons } from '../../../components/molecules/StickyButtons';

import { MultiForm } from '../../../components/organisms/MultiForm';
import { PasswordCreation as Page } from '../../RegistrationFlow/PasswordCreation';

import { getParameterByName } from '../../../features/geturlparameter';
import api from '../../../lib/api';

import './styles.css';

/**
 * Primary UI component for user interaction
 */
export const PasswordCreation = () => {

  const navigate = useNavigate();

  const hash = getParameterByName('hash');
  const id = getParameterByName('id');
  if (! hash || ! id) {
    navigate('/register');
  }

  const onSubmit = (values) => {
    values.hash = hash;
    values.client_id = id;

    api.registerPassword(values, (err, res) => {
      if(err) {
        alert('Something went wrong.');
        console.log(err);
        return;
      }

      if (res.data.status !== 'complete') {
        alert('Something went wrong.');
        return;
      }

      navigate('/password-creation/complete');
    });
  };

  return (
    <MultiForm
      name="PasswordCreation"
      // initialValues={{ email: 'mark@adi.do', postcode: 'bh1 1jn' }}
      onSubmit={onSubmit}
    >
      <MultiForm.Page>
        <Page customButtons={
          <StickyButtons>
            <ButtonGroup isStacked buttons={[
              <Button label="Complete Registration" mode="primary" key={2} isSubmit />
            ]} />
          </StickyButtons>
        } />
      </MultiForm.Page>
    </MultiForm>
  );
};

PasswordCreation.propTypes = {
};

PasswordCreation.defaultProps = {
};

