import React from 'react';
import PropTypes from 'prop-types';
import { MainView } from '../../../templates/MainView';
import { Field } from '../../../components/molecules/Field';
import { Text } from '../../../components/atoms/Text';
import { Heading } from '../../../components/atoms/Heading';
import { Icon } from '../../../components/atoms/Icon';

import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useDropDown } from '../../../lib/useDropdown';

/**
 * Primary UI component for user interaction
 */
export const UpdateContactMethod = ({ buttons, customButtons }) => {
  const contactMethods = useDropDown('PreferredContact', (values) => values.filter(obj => ['Phone Call', 'Email'].indexOf(obj.label) !== -1));

  const location = useLocation();
  const navigate = useNavigate();

  const fromHome = location.state && location.state.fromHome;
  return (
    <MainView BPadding1>
      <div className='title-container'>
        <div onClick={()=>{
          if(fromHome){
            navigate('/');
          } else {
            navigate(-1);
          }
        }}>
          <Text classes="text-lwd-grey-900 flex flex-row items-center" body1m>
            <Icon name='chevron-purple-left' />
            <Text classes='ml-3' to='/'>{fromHome ?'Back to home':'Back'}</Text>
          </Text>
        </div>
      </div>

      <div className='px-6 pt-8 pb-32 smd:p-12'>
        <Heading classes='mb-2' h1>Select your preferred contact method</Heading>
        <Text classes='mb-8' body>Our advisors will aim to complete our chats with you via your chosen method.</Text>

        <Field
          input='radio'
          name='contact_method'
          label=''
          inputOptions={{
            options: contactMethods || []
          }}
          isRequired
        />

        { customButtons || buttons }
      </div>
    </MainView>
  );
};

UpdateContactMethod.propTypes = {
    buttons: PropTypes.element
};

UpdateContactMethod.defaultProps = {
    buttons: null
};
