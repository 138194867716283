import React, { useState } from 'react';
import api from '../../../lib/api';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { Button } from '../../../components/atoms/Button';
import { ButtonGroup } from '../../../components/molecules/ButtonGroup';
import { StickyButtons } from '../../../components/molecules/StickyButtons';
import { MultiForm } from '../../../components/organisms/MultiForm';
import { LeaveFeedback } from '../../MyLiveWellFull/LeaveFeedback';
import { ThankYou } from '../../ThankYou';

import balloons from '../../../assets/images/registation/balloons.svg';

/**
 * Primary UI component for user interaction
 */
export const FeedbackForm = () => {
  const navigate = useNavigate();
  const [feedbackTopic, setFeedbackTopic] = useState(0);
  const [submitting, setSubmitting] = useState(false);
  const [showFeedbackError, setShowFeedbackError] = useState(false);

  return (
    <MultiForm
      name="FeedbackForm"
      hideButtons={false}
      initialHistory={[0]}
      onSubmit={() => {
        navigate("/");
      }}
    >
      <MultiForm.Page next={(values, step, nextPage) => {
        if (values.topic === 'experience' && ! values.rating) return;
        if (values.topic != 'experience') values.rating = undefined;

        setShowFeedbackError(false);

        api.submitFeedback(values, (err, res) => {
          if(err) {
            console.log(err);
            return;
          }

          if (res.data !== 'ok') {
            setShowFeedbackError(true);
            return;
          }

          setFeedbackTopic(values.topic);
          nextPage(values, step);
        });
      }}>
        <LeaveFeedback submitting={submitting} showFeedbackError={showFeedbackError} customButtons={
          <StickyButtons>
            <ButtonGroup isStacked classes='mx-auto md:pb-10' buttons={[
              <Button label="Send Feedback" mode="primary" key={1} isSubmit onClick={() => {
                setSubmitting(true);
              }} />
            ]} />
          </StickyButtons>
        }/>
      </MultiForm.Page>

      <MultiForm.Page>
        <ThankYou
          imageSrc={balloons}
          title={`Thank you for your feedback on ${feedbackTopic === 'experience' ? 'your' : 'the'} ${feedbackTopic}.`}
          description='Your feedback helps us to improve our advice and support.'
          buttonLabel='Back To Dashboard'
          isSubmit={true}
        />
      </MultiForm.Page>
    </MultiForm>
  );
};

FeedbackForm.propTypes = {
};

FeedbackForm.defaultProps = {
};

