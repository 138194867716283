import api from "../lib/api";

export const SET_ARTICLES = 'SET_ARTICLES';
export const CLEAR_ARTICLES = 'CLEAR_ARTICLES';

/** articles */

export const getArticles = (fetchPublic = false) => (dispatch) => {
  const handleResponse = (err, responseJSON) => {
    if(responseJSON && responseJSON.data) {
      // console.log('getArticles',responseJSON);
      return dispatch(setArticles(responseJSON.data.data));
    }

    console.error("Unable to find articles");
  };

  fetchPublic ? api.publicArticles(handleResponse) : api.articles(handleResponse);
};

/** articles */
export const setArticles = (payload) => ({
  type: SET_ARTICLES,
  payload,
});

export const clearArticles = () => ({
  type: CLEAR_ARTICLES,
});


/** reducer */
const initialState = [];


export default function reducer(state = initialState, action) {
    const { type, payload } = action;

    switch (type) {
    case SET_ARTICLES:
        return payload;

    case CLEAR_ARTICLES:
        return initialState;

    default:
        return state;
    }
}
