import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import "./styles.css";
import { Icon } from "../Icon";
import { useSelector } from "react-redux";

/**
 * Primary UI component for user interaction
 */
export const Button = ({
  mode,
  label,
  isIcon,
  iconComponent,
  classes,
  isSubmit,
  ...props
}) => {
  const { loading } = useSelector((state) => state.loading);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setIsLoading(loading);
  }, [loading]);

  return (
    <button
      type={isSubmit ? "submit" : "button"}
      className={[
        "btn",
        mode,
        isSubmit && isLoading && "loading",
        isIcon && "icon",
        classes && classes,
      ].join(" ")}
      disabled={props.isDisabled || (isSubmit && isLoading)}
      {...props}>
      {isSubmit && isLoading && <Icon name='loading' />}
      {label}
      {isIcon && (iconComponent ? iconComponent : <Icon name='chevron' />)}
    </button>
  );
};
Button.propTypes = {
  /**
   * What is the mode?
   */
  mode: PropTypes.oneOf([
    "primary",
    "secondary",
    "tertiary",
    "underlineBtn",
    "plainBtn",
  ]),
  /**
   * Button contents
   */
  label: PropTypes.string.isRequired,
  /**
   * Optional click handler
   */
  onClick: PropTypes.func,
  /**
   * Is this button should show the down arrow icon?
   */
  isIcon: PropTypes.bool,
  /**
   * Classes to pass through to button
   */
  classes: PropTypes.string,
  /**
   * Is a form submit button
   */
  isSubmit: PropTypes.bool,
};

Button.defaultProps = {
  onClick: undefined,
  isSubmit: false,
  isIcon: false,
  classes: null,
  mode: "tertiary",
};
