import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Link, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import './styles.css';

import { MainView } from '../../templates/MainView';

import { Heading } from '../../components/atoms/Heading';
import { Text } from '../../components/atoms/Text';
import { Field } from '../../components/molecules/Field';
import { Icon } from '../../components/atoms/Icon';

import { getPlan } from '../../features/plan';
import { Button } from '../../components/atoms/Button';
import api from '../../lib/api';
import { StickyButtons } from '../../components/molecules/StickyButtons';
import { ButtonGroup } from '../../components/molecules/ButtonGroup';

/**
 * Primary UI component for user interaction
 */
export const UpdateGoal = ({ buttons, customButtons }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const plan = useSelector(state => state.plan);

  const [status, setStatus] = useState('');
  const [values, updateValues] = useState([]);

  useEffect(() => {
    api.preAssessment('', (err, responseJSON) => {
      if(responseJSON && responseJSON.data) {
        setStatus(responseJSON.data.status);
      }

      console.error(err, 'Unable to find client pre-assessment details');
    });
  }, []);

  useEffect(() => {
    dispatch(getPlan());
  }, [dispatch]);

  useEffect(() => {
    const pathways = [];

    if(plan.pathways && plan.pathways.weight) pathways.push('lose-weight');
    if(plan.pathways && plan.pathways.smoking) pathways.push('stop-smoking');
    if(plan.pathways && plan.pathways.alcohol) pathways.push('drink-less');
    if(plan.pathways && plan.pathways.activity) pathways.push('move-more');

    updateValues(pathways);
  }, [plan]);

  return (
    <MainView>
      {status === 'notfound' ? (
        <div className='px-6 pt-8 pb-28 smd:p-12'>
          <Text body>Sorry your case is currently closed, if you’d like to get started with LiveWell Dorset again please <Button label='send us a message' onClick={() => navigate('/help')} mode='underlineBtn' classes='!inline-block !p-0' />.</Text>

          <StickyButtons classes='md-sticky-pt-0 md:mt-4'>
            <ButtonGroup isStacked buttons={[
              <Button label="Back" mode="primary" classes='justify-center' onClick={() => navigate('/')} key={1} />
            ]} />
          </StickyButtons>
        </div>
      ) : (
        <div>
          <div className='title-container'>
            <Text classes="text-lwd-grey-900 flex flex-row items-center" body1m>
              <Icon name='chevron-purple-left' />
              <Link className='ml-3' to='/'>Back to home</Link>
            </Text>
          </div>

          <div className='px-6 pt-8 pb-28 smd:p-12'>
            <Heading classes="mb-8" h1>What do you want to do?</Heading>

            <div className='inner-box'>
              <Field
                input='multiselect'
                name='pathways'
                label='Please select all that apply:'
                inputOptions={{
                  options:[
                    { value: 'lose-weight', label: 'Lose Weight', icon: 'lose-weight', tickIfNotClickable: true },
                    { value: 'move-more', label: 'Move More', icon: 'move-more', tickIfNotClickable: true },
                    { value: 'drink-less', label: 'Drink Less', icon: 'drink-less', tickIfNotClickable: true },
                    { value: 'stop-smoking', label: 'Quit Smoking', icon: 'quit-smoking', tickIfNotClickable: true },
                  ]
                }}
                selectedArray={values}
              />
            </div>

            { customButtons || buttons }
          </div>
        </div>
      )}
    </MainView>
  );
};

UpdateGoal.propTypes = {
  buttons: PropTypes.node,
  customButtons: PropTypes.node,
};

UpdateGoal.defaultProps = {
  buttons: null
};

