import React from 'react';
import PropTypes from 'prop-types';

import { Heading } from '../../atoms/Heading';
import { Text } from '../../atoms/Text';

import './styles.css';

/**
 * Primary UI component for user interaction
 */
export const HeadingBlock = ({ heading, text, classes, mode, props }) => (
  <div className={[
        'heading-block',
        classes && classes
    ].join(' ')}
  >
    {(mode === 'h2' ?
      <Heading h2 classes="mb-2" children={heading} {...props} /> :
      <Heading h1 classes="mb-2" children={heading} {...props} />
    )}
    <Text body>{ text }</Text>
  </div>
);

HeadingBlock.propTypes = {
    /**
     * The heading
     */
     heading: PropTypes.string.isRequired,

    /**
     * Many heading block text
     */
    text: PropTypes.string.isRequired,
    classes: PropTypes.string,
    mode: PropTypes.string,
    props: PropTypes.array,
};

HeadingBlock.defaultProps = {
    heading: null,
    text: null,
    props: [],
};