import React from 'react';
import PropTypes from 'prop-types';
import { Button } from '../../atoms/Button';
import { Text } from '../../atoms/Text';
import { Heading } from '../../atoms/Heading';
import './styles.css';

/**
 * Primary UI component for user interaction
 */
export const ButtonCard = ({ title, body, btnLabel, btnOnClick, btnMode, btnIsSubmit, footer, classes, ...props }) => {
  return (
    <div className={['p-5 rounded-xl shadow-card', classes && classes].join(' ')}>
      {title && (<Heading h3>{title}</Heading>)}
      {body}
      {btnLabel && (<Button
        classes='mt-5 w-full justify-center'
        label={btnLabel}
        onClick={btnOnClick}
        mode={btnMode}
        isSubmit={btnIsSubmit}
        />)}
      {footer}
    </div>
  );
};

ButtonCard.propTypes = {
  title: PropTypes.string.isRequired,
  body: PropTypes.object,
  btnLabel: PropTypes.string,
  btnOnClick: PropTypes.func,
  btnMode: PropTypes.string,
  btnIsSubmit: PropTypes.bool,
  classes: PropTypes.string,
  footer: PropTypes.object,
};

ButtonCard.defaultProps = {
  title: '',
  body: {},
  btnLabel: '',
  btnOnClick: () => {},
  btnMode: 'secondary',
  btnIsSubmit: false,
  footer: {},
  classes: '',
};

