import React from 'react';
import { Form } from 'react-final-form';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { Heading } from '../../../components/atoms/Heading';
import { Text } from '../../../components/atoms/Text';
import { Button } from '../../../components/atoms/Button';
import { Field } from '../../../components/molecules/Field';
import { Icon } from '../../../components/atoms/Icon';

import logo from '../../../assets/logos/logo-stack.svg';

import email from '../../../components/validation/email';

import { forgotPassword } from '../../../features/forgotpassword';
import { getParameterByName } from '../../../features/geturlparameter';
import password from '../../../components/validation/password';
import { resetPassword } from '../../../features/resetpassword';
import { InfoCard } from '../../../components/molecules/InfoCard';

/**
 * Primary UI component for user interaction
 */
export const ResetPassword = (props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const clientEmail = getParameterByName('email');
  const dob = getParameterByName('dob');
  const hash = getParameterByName('t');

  const onResetReqSubmit = (values) => {
    dispatch(
      forgotPassword(values, (err, res) => {
        if(err) return alert(err);
        navigate('/check-email');
      })
    );
  };

  const onResetPwdSubmit = (values) => {
    dispatch(
      resetPassword(values, (err, res) => {
        if(err) return alert(err);

        if (res.data.status !== true) {
          console.log(res);
          alert('Failed to update password.');
          return;
        }

        navigate('/password-updated');
      })
    );
  };

  const requestResetForm = () => {
    return (
      <div className='mx-auto max-w-lwd-600 w-full'>
          <Heading classes='text-lwd-grey-900 mb-lwd-5 lg:text-58' h1>Reset password</Heading>
          <Text classes='mb-5 smd:mb-lwd-30' body2>Enter your below to get a password reset email sent.</Text>
          <Form
            onSubmit={onResetReqSubmit}
            render={({ handleSubmit, form, submitting, pristine, values }) => (
              <form onSubmit={handleSubmit} className='w-full flex-grow flex flex-col'>
                <div className='flex flex-col items-start gap-8'>
                  <Field
                    input='datepicker'
                    name='dob'
                    label='Date of Birth'
                    isRequired
                    limitToAdultAge
                  />
                  <Field
                    input='email'
                    name='email'
                    label='Email Address'
                    placeholder='Enter your email address'
                    isRequired
                    validation={[email]}
                  />
                  <Button classes='reset-padding' label='Back to log in' title='Back to log in' mode='underlineBtn' onClick={() => navigate('/')} />
                  <Button
                    isSubmit
                    mode='primary'
                    label='Reset password'
                    isIcon
                    iconComponent={<Icon name='chevron-white-right' />}
                  />
                </div>
              </form>
            )}
          />
        </div>
    );
  };

  const newPasswordForm = () => {
    return (
      <div className='mx-auto max-w-lwd-600 w-full'>
          <Heading classes='text-lwd-grey-900 mb-lwd-5 lg:text-58' h1>Set a new password</Heading>
          <Text classes='mb-5 smd:mb-lwd-30' body2>Please enter a new password. Please don’t use a password you have used before.</Text>
          <Form
            onSubmit={onResetPwdSubmit}
            validate={(values) => {
              const errors = {};
              if (values.password_confirmation !== values.password) {
                errors.password_confirmation = 'Must match';
              }
              return errors;
            }}
            render={({ handleSubmit, form, submitting, pristine, values }) => (
              <form onSubmit={handleSubmit} className='w-full flex-grow flex flex-col'>
                <div className='flex flex-col items-start gap-8'>
                <InfoCard text='Passwords must contain a minimum of 8 characters.' icon='info-round' />
                  <Field
                    input='password'
                    name='password'
                    label='New password'
                    placeholder='Enter new password'
                    validation={[password]}
                    isRequired
                  />
                  <Field
                    input='password'
                    name='password_confirmation'
                    label='Confirm password'
                    placeholder='Enter new password again'
                    isRequired
                  />
                  <Button classes='reset-padding' label='Back to log in' title='Back to log in' mode='underlineBtn' onClick={() => navigate('/')} />
                  <Button
                    isSubmit
                    mode='primary'
                    label='Save password'
                    isIcon
                    iconComponent={<Icon name='chevron-white-right' />}
                  />
                </div>
              </form>
            )}
          />
        </div>
    );
  };

  return (
    <div className='full-page response-page w-screen h-screen flex flex-col items-center p-10'>
      <img className='block h-lwd-70 mb-10 smd:md-lwd-30' src={logo} alt="livewell dorset logo" />

      <div className='grow w-full flex flex-col items-center justify-center'>
        {clientEmail && dob && hash ? newPasswordForm() : requestResetForm()}
      </div>
    </div>
  );
};

ResetPassword.propTypes = {
};

ResetPassword.defaultProps = {
};

