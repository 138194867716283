import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import moment from 'moment';

import { Text } from '../../atoms/Text';
import { Button } from '../../atoms/Button';
import { ButtonGroup } from '../../molecules/ButtonGroup';
import { StickyButtons } from '../../molecules/StickyButtons';
import { Icon } from '../../atoms/Icon';
import { Notifications } from '../../molecules/Notifications';

import logo from '../../../assets/logos/logo-desktop.svg';
import smallLogo from '../../../assets/logos/logo.svg';
import { logout } from '../../../features/login';
import { getPlan } from '../../../features/plan';
import './styles.css';

/**
 * Primary UI component for user interaction
 */
export const AuthedNav = (props) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const plan = useSelector(state => state.plan);
  const nextSession = useSelector(state => state.sessions.filter(session => session.status.toLowerCase() === 'due').sort((a, b) => new Date(a.due_date) - new Date(b.due_date)).shift());

  const [isOpen, setIsOpen] = useState(false);
  const [hasClientPlan, setHasClientPlan] = useState(typeof plan.id === 'number');

  useEffect(() => {
    dispatch(getPlan());
  }, [dispatch]);

  useEffect(() => {
    setHasClientPlan(typeof plan.id === 'number');
  }, [plan]);

  return (
    <div className={['main-nav', isOpen && 'menu-open'].join(' ')}>
        <a href="https://my.livewelldorset.co.uk/" title='home page link' className='flex flex-row items-center'>
          <img className='hidden 2xl:block' src={logo} alt="livewell dorset logo" />
          <img className='block 2xl:hidden w-11 xl:w-12' src={smallLogo} alt="livewell dorset logo" />
          <span className='sr-only'>Livewell Dorset Home page link</span>
        </a>

        <div className='main-container'>
          <div className='main-nav-container'>
            { props.plus && isOpen && nextSession && (
              <div className='nav-session-container'>
                <p className='nav-session-title'>{`Your next session is on ${moment(nextSession.due_date, "YYYY-MM-DD").format('DD/MM/YYYY')}`}</p>
              </div>
            )}
            <nav className='navigation' aria-label="MyLivewell navigation">
              { hasClientPlan ? <>
                <Text nav link='/'>Home</Text>
                <Text nav link='/plan'>Plan</Text>
                <Text nav link='/sessions'>Sessions</Text>
                <Text nav link='/actions'>Actions</Text>

                {[2, 3].includes(plan.service_level) && <Text nav link='/messages'>Messages</Text>}
              </>
              : <>
                <Text nav link='/'>Home</Text>
                <Text nav link='/articles'>Aricles & Advice</Text>
              </>
              }
                <Text nav link='/account'>Account</Text>
            </nav>

            <StickyButtons classes='main-nav-buttons'>
              <ButtonGroup buttons={[
                <Button label="Get In Touch" mode="primary" onClick={() => navigate('/help')} key={0} classes='order-2 lg:order-1' />,
                <Button label='Log out' mode='secondary' onClick={() => dispatch(logout())} key={1} classes='order-1 lg:order-2' />
              ]} />
            </StickyButtons>
          </div>

          <div className='block lg:hidden top-getintouch'>
            <Button classes="top-getintouch-btn" label="Get In Touch" mode="secondary" onClick={() => navigate('/help')} />
          </div>
          <div className='hidden lg:block' onClick={() => setIsOpen(false)}>
            <Notifications />
          </div>
        </div>

        <button type='button' className='menu-button' title='Toggle menu icon' onClick={() => {setIsOpen(!isOpen)}}>
          {isOpen ? <Icon name='exit-button' /> : <Icon name='nav-button' /> }
          <span className='sr-only'>Menu</span>
        </button>
    </div>
  );
};

AuthedNav.propTypes = {
  plus: PropTypes.bool,
};

AuthedNav.defaultProps = {
  plus: false,
};
