import React, { useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { Form, FormSpy } from 'react-final-form';
import { useLocation } from 'react-router-dom';

import { StickyButtons } from '../../molecules/StickyButtons';
import { ButtonGroup } from '../../molecules/ButtonGroup';
import { Button } from '../../atoms/Button';
import { useDropDown } from '../../../lib/useDropdown';
import { analytics } from '../../../lib/analytics';

export const MultiForm = (props) => {
  const location = useLocation();

  const { name, initialValues, children, onSubmit, initialHistory, hideButtons, alwaysUseButtonRows } = props;

  const loadedValueStr = localStorage.getItem(`${name}-values`);
  const loadedHistoryStr = localStorage.getItem(`${name}-history`);
  let defaultValues = typeof initialValues === 'object' ? initialValues : [];
  let loadedHistory = [0];

  if(loadedValueStr) defaultValues = JSON.parse(loadedValueStr);
  if(loadedHistoryStr) loadedHistory = JSON.parse(loadedHistoryStr);

  if (initialHistory) loadedHistory = initialHistory;

  const formRef = useRef(null);
  const [history, setHistory] = useState(loadedHistory ? loadedHistory : [0]);
  const [vals, setVals] = useState(defaultValues || {});

  const ethnicGroups = useDropDown('ethnicity', (values) => values.filter(obj => ['4 Not Disclosed'].indexOf(obj.label) === -1));

  useEffect(() => {
    localStorage.setItem(`${name}-values`, JSON.stringify(vals));
  }, [vals]);

  useEffect(() => {
    localStorage.setItem(`${name}-history`, JSON.stringify(history));

    const lastItem = history[history.length - 1];
    analytics('pageview', {page: `${location.pathname}/${lastItem}`});
  }, [history]);

  useEffect(() => {
    if(loadedHistoryStr) setHistory(JSON.parse(loadedHistoryStr));
  }, []);

  useEffect(() => {
    switch (name) {
      case 'assessment':
        defaultValues[name] = {}; //{...initialValues};
        defaultValues[name].motivation_other = initialValues.motivation_other;
        defaultValues[name].ethnicity = ethnicGroups.find(ethnicity => ethnicity.value === initialValues.ethnicity);
        defaultValues[name].assessment_height_cm = initialValues.height;
        defaultValues[name].assessment_weight_kg = initialValues.weight;
        defaultValues[name].active_minutes = initialValues.active_minutes;
        defaultValues[name].sitting_minutes = initialValues.sitting_minutes;
        break;
      case 'SelectATime':
        defaultValues.days = initialValues.days;
        defaultValues.times = initialValues.times;
        break;
      case 'UpdateContactMethodForm':
        defaultValues.contact_method = initialValues.contact_method;
        break;
    }

    // console.log('defaultValues', initialValues, defaultValues);
    setVals(defaultValues);
  }, [initialValues]);


  const next = (values, step) => {
    const currentPage = history[history.length - 1];
    console.log('Current Page', currentPage, values);

    const nextPage = currentPage + step;
    setHistory([...history, nextPage]);
    setVals(values);
  };

  const previous = () => {
    const newHistory = history.slice(0, history.length - 1);
    setHistory(newHistory);
  };

  const skipTo = (page) => {
  const newHistory = history.slice(0, history.indexOf(page) + 1);
  setHistory(newHistory);
  };

  /**
   * NOTE: Both validate and handleSubmit switching are implemented
   * here because 🏁 Redux Final Form does not accept changes to those
   * functions once the form has been defined.
   */
  const validate = values => {
    const page = history[history.length - 1];
    const activePage = React.Children.toArray(children)[page];
    return activePage.props.validate ? activePage.props.validate(values) : {};
  };

  const handleSubmit = values => {
    const page = history[history.length - 1];
    const activePage = React.Children.toArray(children)[page];
    const isLastPage = page === React.Children.count(children) - 1;

    if (isLastPage) {
      // clear the values from local storage
      localStorage.removeItem(`${name}-values`);
      localStorage.removeItem(`${name}-history`);
      return onSubmit(values);
    } else {
      // we pass step variable through because the page next() function may change the step value
      if(activePage.props.next) {
        activePage.props.next(values, 1, next);
      } else {
        next(values, 1);
      }
    }
  };

  const page = history[history.length - 1];
  const activePage = React.Children.toArray(children)[page];
  const isLastPage = page === React.Children.count(children) - 1;

  const buttons = [];
  if (! hideButtons) {
    if(page > 0) buttons.push(<Button label='Back' mode='secondary' onClick={previous} key={1} />);
    if(!isLastPage) buttons.push(<Button label='Continue' mode='primary' isSubmit key={2} />);
    if(isLastPage) buttons.push(<Button label='Submit' mode='primary' isSubmit key={2} />);
  }

  const buttonGroup = (
    <StickyButtons>
      <ButtonGroup buttons={buttons} alwaysRow={alwaysUseButtonRows} />
    </StickyButtons>
  );

  // we inject the buttons into the child prop here.
  const kids = React.Children.toArray(activePage.props.children);
  const newChild = React.cloneElement(
    kids[0],
    {buttons: buttonGroup, skipToPage: skipTo}
  );

  return (
    <Form
      initialValues={vals}
      validate={validate}
      onSubmit={handleSubmit}
    >
      {({ handleSubmit, submitting, values }) => (
        <form onSubmit={handleSubmit} className='w-full flex-grow flex flex-col' ref={formRef}>
          {newChild}

          <FormSpy
            subscription={{ submitFailed: true, errors: true }}
            onChange={(props) => {
              const errors = props.errors.client;
              if (formRef.current && props.submitFailed && errors) {
                let fieldName = Object.keys(errors)[0];
                switch (fieldName) {
                  case 'dob':
                    fieldName = '[name="dob"]';
                    break;
                  case 'pathways':
                    fieldName = '.badge-group';
                    break;
                  default:
                    fieldName = `[name="client[${fieldName}]"]`;
                    break;
                }

                const el = formRef.current.querySelector(fieldName);
                if (el) {
                  const parentEl = el.closest('.input-container');
                  parentEl.scrollIntoView({ behavior: 'smooth' });
                }
              }
            }}
          />
        </form>
      )}
    </Form>
  )
};

MultiForm.Page = ({ children }) => children;

MultiForm.propTypes = {
  name: PropTypes.string.isRequired,
  onSubmit: PropTypes.func.isRequired,
  historyOverride: PropTypes.array,
  hideButtons: PropTypes.bool,
  alwaysUseButtonRows: PropTypes.bool,
}

MultiForm.defaultProps = {
  hideButtons: false,
  alwaysUseButtonRows: false,
}