export const SET_LOADING = 'SET_LOADING';
export const CLEAR_LOADING = 'CLEAR_LOADING';

/** actions */
export const setLoading = (loading) => ({
    type: SET_LOADING,
    payload: loading,
});

export const clearLoading = () => ({
    type: CLEAR_LOADING,
});



/** reducer */
const initialState = {};

export default function reducer(state = initialState, action) {
    const { type, payload } = action;

    switch (type) {
    case SET_LOADING:
        return { loading: payload };

    case CLEAR_LOADING:
        return { loading: false };

    default:
        return state;
    }
}
