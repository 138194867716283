import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

import { MainView } from '../../../templates/MainView';

import { Text } from '../../../components/atoms/Text';
import { Heading } from '../../../components/atoms/Heading';
import { Field } from '../../../components/molecules/Field';
import { ProgressBar } from '../../../components/molecules/ProgressBar';

import { useDropDown } from '../../../lib/useDropdown';

/**
 * Primary UI component for user interaction
 */
export const Motivation = ({ buttons, currentPage, totalPages }) => {
  const values = useDropDown('motivation');


  return (
    <MainView BPadding1>
      <ProgressBar min={0} max={totalPages} current={currentPage} label='quick health check' />

      <div className='px-6 pt-8 pb-14 smd:p-12 flex flex-col'>
        <Text classes='mb-2' body>{`Step ${currentPage} of ${totalPages}`}</Text>
        <Heading classes='mb-8' h1>Why do you want to make a change? <span className='text-primary-500'>*</span></Heading>

        <div className='flex flex-col space-y-10 smd:space-y-14 pb-10 smd:pb-0'>
          <Field
            input='checkboxgroup'
            name='assessment[motivations]'
            label='Please select all that apply:'
            options={values}
            isRequired
          />
          <Field
            input='text'
            name='assessment[motivation_other]'
            label='Do you have any other reasons?'
            placeholder='Enter any other motivation here'
            modalsubtitle='Why do we ask this?'
            modaltitle='Other'
            help='This is some help text'
          />
        </div>

        { buttons }
      </div>
    </MainView>
  );
};

Motivation.propTypes = {
  buttons: PropTypes.element,
  currentPage: PropTypes.number,
  totalPages: PropTypes.number,
};

Motivation.defaultProps = {
  buttons: null,
  totalPages: 2,
  currentPage: 2,
};