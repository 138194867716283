import React from 'react';

import './styles.css';

import logo from '../../../assets/logos/logo-desktop-big.svg';
import twitter from '../../../assets/icons/twitter-no-bg.svg';
import facebook from '../../../assets/icons/facebook-no-bg.svg';
import instagram from '../../../assets/icons/instagram-no-bg.svg';
import { Text } from '../../atoms/Text';

/**
 * Primary UI component for user interaction
 */
export const Footer = ({ BPadding1, BPadding2, ...props }) => {
  return (
    <div className='footer-container'>
      <div className={['footer', BPadding1 && 'b-padding-1', BPadding2 && 'b-padding-2'].join(' ')}>
        <div className='footer-top'>
          <div className='footer-logo'>
            <img src={logo} alt="livewell dorset logo" />
          </div>

          <div className='media-list'>
            <a className='social-icon' href='https://twitter.com/lwdorset/' target='_blank' rel='noopener noreferrer'>
              <img src={twitter} alt="twitter icon" className='pt-0.5'/>
            </a>
            <a className='social-icon' href='https://www.facebook.com/lwdorset/' target='_blank' rel='noopener noreferrer'>
              <img src={facebook} alt="facebook icon" />
            </a>
            <a className='social-icon' href='https://www.instagram.com/lwdorset/' target='_blank' rel='noopener noreferrer'>
              <img src={instagram} alt="instagram icon" />
            </a>
          </div>
        </div>

        <div className='footer-contents'>
          <div className='first-link-group'>
            <ul>
              <li>
                <Text body1m nav link='https://www.livewelldorset.co.uk/about-livewell/'>
                  About Us
                </Text>
              </li>
              <li>
                <Text body1m nav link='https://www.livewelldorset.co.uk/get-active/'>
                  Get Active
                </Text>
              </li>
              <li>
                <Text body1m nav link='https://www.livewelldorset.co.uk/lose-weight/'>
                  Lose Weight
                </Text>
              </li>
              <li>
                <Text body1m nav link='https://www.livewelldorset.co.uk/stop-smoking/'>
                  Stop Smoking
                </Text>
              </li>
              <li>
                <Text body1m nav link='https://www.livewelldorset.co.uk/drink-less/'>
                  Drink Less
                </Text>
              </li>
              <li>
                <Text body1m nav link='https://www.livewelldorset.co.uk/contact/'>
                  Contact Us
                </Text>
              </li>
            </ul>
          </div>

          <div className='second-link-group'>
            <ul>
              <li>
                <Text body1m nav link='https://www.livewelldorset.co.uk/privacy/'>
                  Privacy Policy
                </Text>
              </li>
              <li>
                <Text body1m nav link='https://www.livewelldorset.co.uk/sitemap/'>
                  Sitemap
                </Text>
              </li>
              <li>
                <Text body1m nav link='https://www.livewelldorset.co.uk/terms-and-conditions/'>
                  Terms &amp; Conditions
                </Text>
              </li>
              <li>
                <Text body1m nav link='https://www.livewelldorset.co.uk/cookie-policy/'>
                  Cookie Policy
                </Text>
              </li>
              <li>
                <Text body1m nav link='https://www.livewelldorset.co.uk/accessibility/'>
                  Accessibility
                </Text>
              </li>
            </ul>
          </div>

          <div className='address'>
            <Text body1m>Registered office address</Text>
            <Text body>Jubliee Close Retail Park</Text>
            <Text body>Weymouth & Portland Local Office</Text>
            <Text body>DT4 7BG</Text>
          </div>
        </div>

        <div className='footer-bottom'>
          <Text body>© LiveWell Dorset 2022</Text>
        </div>
      </div>
    </div>
  );
};

Footer.propTypes = {

};

Footer.defaultProps = {
};