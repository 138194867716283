import React from 'react';
import PropTypes from 'prop-types';

import { Image } from '../../atoms/Image';

import './styles.css';

import loseWeightImgSrc from '../../../assets/images/registation/lose-weight.svg';
import moveMoreImgSrc from '../../../assets/images/registation/move-more.svg';
import drinkLessImgSrc from '../../../assets/images/registation/drink-less.svg';
import quitSmokingImgSrc from '../../../assets/images/registation/quit-smoking.svg';
import addGoalImgSrc from '../../../assets/images/registation/add-goal.svg';

/**
 * Primary UI component for user interaction
 */
export const Goal = ({ image, text, onClick }) => {

  const goalBlock = (
    <div className='goal'>
      <div className='goal-image'>
        { image === 'lose-weight' && <Image src={loseWeightImgSrc} alt='lose weight image' />}
        { image === 'move-more' && <Image src={moveMoreImgSrc} alt='move more image' />}
        { image === 'drink-less' && <Image src={drinkLessImgSrc} alt='drink less image' />}
        { image === 'quit-smoking' && <Image src={quitSmokingImgSrc} alt='quit smoking image' />}
        { image === 'add-goal' && <Image src={addGoalImgSrc} alt='add goal image' />}
      </div>
      <div className='goal-text'>
        { text }
      </div>
    </div>
  );

  if(onClick) {
    return (
      <a className='goal-container' onClick={onClick}>
        {goalBlock}
      </a>
    );
  }
  
  return (
    <div className='goal-container'>
      {goalBlock}
    </div>
  );
};

Goal.propTypes = {
   /**
   * The icon to show on the badge
   */
  image: PropTypes.string,
 
  /**
   * Many badge text
   */
  text: PropTypes.string,

  /**
   * Callback function to be called when the badge is clicked
   */
  onClick: PropTypes.func
};

Goal.defaultProps = {
  image: null,
  text: null,
  onClick: null
};
