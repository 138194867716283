import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { MainView } from '../../../templates/MainView';
import { Text } from '../../../components/atoms/Text';
import { Heading } from '../../../components/atoms/Heading';
import { Field } from '../../../components/molecules/Field';
import { ProgressBar } from '../../../components/molecules/ProgressBar';
import { getPlan } from '../../../features/plan';

/**
 * Primary UI component for user interaction
 */
export const PathwayReview = ({ buttons, currentPage, totalPages }) => {
  const dispatch = useDispatch();

  const preAssessment = useSelector(state => state.preassessment);

  const [values, updateValues] = useState([]);

  useEffect(() => {
    dispatch(getPlan());
  }, [dispatch]);

  useEffect(() => {
    const pathways = [];

    if(preAssessment.pathways && preAssessment.pathways.weight) pathways.push('lose-weight');
    if(preAssessment.pathways && preAssessment.pathways.smoking) pathways.push('quit-smoking');
    if(preAssessment.pathways && preAssessment.pathways.alcohol) pathways.push('drink-less');
    if(preAssessment.pathways && preAssessment.pathways.activity) pathways.push('move-more');

    updateValues(pathways);
  }, [preAssessment]);

  return (
    <MainView BPadding2>
      <ProgressBar min={0} max={totalPages} current={currentPage} label='Quick health check' />

      <div className='px-6 pt-8 pb-14 smd:p-12 flex flex-col'>
      <Text classes='mb-2' body>{`Step ${currentPage} of ${totalPages}`}</Text>
        <Heading classes='mb-8' h1>Would you like to do anything else with us?</Heading>

        <div className='inner-box'>
          <Field
            input='multiselect'
            name='assessment[pathways]'
            label='Please select all that apply:'
            // isRequired
            inputOptions={{
              options:[
                { value: 'lose-weight', label: 'Lose Weight', icon: 'lose-weight', tickIfNotClickable: true },
                { value: 'move-more', label: 'Move More', icon: 'move-more', tickIfNotClickable: true },
                { value: 'drink-less', label: 'Drink Less', icon: 'drink-less', tickIfNotClickable: true },
                { value: 'quit-smoking', label: 'Quit Smoking', icon: 'quit-smoking', tickIfNotClickable: true },
              ]
            }}
            isShowingRequired
            selectedArray={values}
          />
        </div>

        { buttons }
      </div>
    </MainView>
  );
};

PathwayReview.propTypes = {
  buttons: PropTypes.element,
  currentPage: PropTypes.number,
  totalPages: PropTypes.number,
};

PathwayReview.defaultProps = {
  buttons: null,
  totalPages: 1,
  currentPage: 1,
};