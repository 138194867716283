import React from 'react';
import PropTypes from 'prop-types';
import './styles.css';

/**
 * Primary UI component for user interaction
 */
export const ProgressBar = ({ min, max, current, type, label }) => {  
  const percentage = Math.abs((current - min) / (max - min) * 100);

  if(type === 'buttons') {
    const pills = [];
    for(let i = min; i <= max; i++) {
      if(current > 0) {
        pills.push(<div className="progress-bar__pill active" key={i}><span>{i}</span></div>);
        current--;
      } else {
        pills.push(<div className="progress-bar__pill" key={i}><span>{i}</span></div>);
      }
      
    }
    return (
      <div className="progress-buttons">
        <div className="progress-buttons-bar" style={{width: `${percentage}%`}}></div>
        <div className='pills-container'>
          { pills }
        </div>
        { label && <div className="progress-bar__label">{label}</div> }
      </div>
    );
    }

  return (
    <div className="multi-step">
      <div className="multi-step-bar" style={{width: `${percentage}%`}}></div>
      { label && <div className="progress-bar__label multi-step-text">{label}</div> }
    </div>
  );
};

ProgressBar.propTypes = {
   /**
   * Minimum number of steps
   */
  min: PropTypes.number,

  /**
   * Label to show under the progress bar
   */
  label: PropTypes.string,

  /**
   * Maximum number of steps
   */
  max: PropTypes.number.isRequired,

  /**
   * Current number of steps
   */
  current: PropTypes.number.isRequired,

  /**
   * Current number of steps
   */
  type: PropTypes.oneOf(['buttons','slider']).isRequired
};

ProgressBar.defaultProps = {
  min: 1,
  max: 5,
  current: 1,
  label: null,
  type: 'slider'
};
