import React from 'react';
import PropTypes from 'prop-types';
import './styles.css';

/**
 * Primary UI component for user interaction
 */
export const Text = ({ children, link, ...props }) => {
  const target = props.newTab ? '_blank' : '_self';
  if(props.nav) return (
    <a href={link} onClick={props.onClick} target={target} rel={target === '_blank' ? 'noopener' : ''} className={[
      props.nav && 'nav',
      props.body1m && 'body1m',
      window.location.pathname === link && 'active',
      props.classes && props.classes,
    ].join(' ')}>
      {children}
    </a>
  );
  return (
    <p className={[
      props.body && 'body',
      props.subtitle && 'subtitle',
      props.body1m && 'body1m',
      props.body2 && 'body2',
      props.caption && 'caption',
      props.overline && 'lwd-overline',
      props.step && 'step',
      props.nav && 'nav',
      props.button && 'button',
      props.classes && props.classes,
    ].join(' ')}>
      {children}
    </p>
  );
}

Image.propTypes = {

  /**
   * Text of heading
   */
  children: PropTypes.string.isRequired,

  /**
   * is this a subtitle?
   */
  subtitle: PropTypes.bool,

  /**
   * is this a body1m?
   */
  body1m: PropTypes.bool,

  /**
   * is this a body?
   */
  body: PropTypes.bool,

  /**
   * is this a body2?
   */
  body2: PropTypes.bool,

  /**
   * is this a caption?
   */
  caption: PropTypes.bool,

  /**
   * is this a overline?
   */
  overline: PropTypes.bool,

  /**
   * is this a nav?
   */
  nav: PropTypes.bool,

  /**
   * add link for the nav
   */
  link: PropTypes.string,

  /**
   * is this a button?
   */
   button: PropTypes.bool,

  /**
   * open link in a new tab?
   */
   newTab: PropTypes.bool,

  /**
   * add classes
   */
  classes: PropTypes.string,
};

Image.defaultProps = {
  subtitle: false,
  body: false,
  body1m: false,
  body2: false,
  caption: false,
  overline: false,
  nav: false,
  link: false,
  button: false,
  classes: null,
};
