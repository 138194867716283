const password = value => {
  if (! value || value.length < 8) {
    return 'Your password should be at least 8 characters long';
  }

  // const passwordCriteria = /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{8,}$/g;
  // if (! passwordCriteria.test(value)) {
  //   return 'Please choose password that includes letters and numbers';
  // }

  return undefined;
};

export default password;