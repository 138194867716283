import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';

import { MyLiveWellView } from '../../templates/MyLiveWellView';

import { Heading } from '../../components/atoms/Heading';
import { Card } from '../../components/molecules/Card';
import { StepCard } from '../../components/molecules/StepCard';
import { Carousel } from '../../components/organisms/Carousel';
import { Button } from '../../components/atoms/Button';
import { Image } from '../../components/atoms/Image';
import { HeadingBlock } from '../../components/molecules/HeadingBlock';
import { NextSteps } from '../../components/organisms/NextSteps';
import { PreferenceItem } from '../../components/molecules/PreferenceItem';

import { getPlan } from '../../features/plan';
import { getArticles } from '../../features/articles';

import yogaImgSrc from '../../assets/images/registation/yoga.svg';
import questionsImgSrc from '../../assets/images/registation/questions.svg'

import './styles.css';
import { Text } from '../../components/atoms/Text';
import { pingUser } from '../../features/login';
import { ImageCtaSection } from '../../components/organisms/ImageCtaSection';

/**
 * Primary UI component for user interaction
 */
export const Dashboard = ({ children }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const profile = useSelector(state => state.profile);
  const articles = useSelector(state => state.articles);

  const [initialPreferenceValues, setInitialPreferenceValues] = useState({
    days: [],
    times: [],
  });

  useEffect(() => {
    dispatch(getPlan());
    dispatch(getArticles());
    dispatch(pingUser());
  }, [dispatch]);

  const dayOptions = useMemo(() => [
    {label: 'Monday', value: 'mon', rank:1},
    {label: 'Tuesday', value: 'tue', rank:2},
    {label: 'Wednesday', value: 'wed', rank:3},
    {label: 'Thursday', value: 'thu', rank:4},
    {label: 'Friday', value: 'fri', rank:5},
  ], []);

  const timeOptions = useMemo(() => [
    {label: 'Morning', value: 'morning', rank:1},
    {label: 'Lunch time', value: 'lunch', rank:2},
    {label: 'Afternoon', value: 'afternoon', rank:3},
    {label: 'Early evening', value: 'evening', rank:4},
  ], []);

  useEffect(() => {
    let previouslySelected = {
      days: [],
      times: [],
    };

    for(let preference of Object.keys(profile).filter(key => key.substring(0, 8) === 'contact_')) {
      if (profile[preference] !== true) continue;
      let key = preference.replace('contact_', '');
      let type = typeof dayOptions.find(day => day.value === key) !== 'undefined' ? 'day' : 'time';
      type === 'day' ? previouslySelected.days.push(key) : previouslySelected.times.push(key);
    }

    setInitialPreferenceValues(previouslySelected);
  }, [profile, dayOptions]);

  const getContactOptionChosen = (options, selected) => {
    if (! options) return '';
    let selectOptions = [];

    for (let item of selected) {
      let selected = options.find(option => option.value === item);
      if (selected) selectOptions.push(selected);
    }

    return selectOptions.sort((a, b) => a.rank - b.rank).map(option => option.label).join(', ');
  };

  return (
    <MyLiveWellView key={0}>
      <div className='py-8 px-6 smd:p-12'>
        <div className='max-w-lwd-860 mx-auto'>
          <div className="max-w-lwd-860 mx-auto">
            <Heading classes="pb-6" h2>Next steps</Heading>
            <div className='flex flex-col gap-6'>
              <NextSteps />
            </div>
          </div>
        </div>
      </div>

      {profile.assessment_count !== 0 && typeof profile.completed_calls === 'number' && profile.completed_calls > 0 &&(<ImageCtaSection
        containerClasses='understand-your-habits'
        image={questionsImgSrc}
        imageAlt='yoga image'
        imageContainerClasses='yoga-image-container smd:order-1'
        heading='Take a health checkup'
        content="Take health checkup today to help you find out how you're doing. This check takes just five minutes."
        contentClasses='my-auto'
        btnLabel='Take checkup'
        btnOnClick={() => navigate('/assessment')}
      />)}

      {articles && articles.length > 0 && (<div className='bg-primary-100 py-8 smd:p-12'>
        <div className='max-w-lwd-860 mx-auto'>
          <div className="max-w-lwd-860 mx-auto">
            <div className='flex justify-between items-center mb-6'>
              <Heading classes="px-6 smd:px-0" h2>Latest articles</Heading>
              <Button label='View All Articles' onClick={() => window.open('https://www.livewelldorset.co.uk/articles/', '_blank', 'noopener')} mode='underlineBtn' classes='see-all-mobile' />
            </div>

            <Carousel
              classes="pl-6 mb-2 smd:mb-0 smd:pl-0 smd:mr-neg-12 w-auto lg:mr-0 lg:w-full"
              data={articles.slice(0,3).map((article, idx) => (
                  <Card
                    key={`article-${idx}`}
                    classes='h-full'
                    image={article.image}
                    imageAlt={article.title}
                    date={moment.unix(article.published_at).format('Do MMMM, Y')}
                    title={article.title}
                    body={article.intro}
                    btnLabel="Read More"
                    btnOnClick={() => window.open(`https://www.livewelldorset.co.uk/${article.url}`, '_blank')}
                  />
                ))}
            />
            <div className='px-6 smd:px-0 block smd:hidden'>
              <Button label='View All Articles' onClick={() => window.open('https://www.livewelldorset.co.uk/articles/', '_blank', 'noopener')} mode='primary' classes='w-full justify-center' />
            </div>
          </div>
        </div>
      </div>)}

      <ImageCtaSection
        containerClasses='understand-your-habits py-8 px-6 smd:py-lwd-72 smd:px-12'
        image={yogaImgSrc}
        imageAlt='yoga image'
        imageContainerClasses='yoga-image-container'
        heading='Understand your habits'
        content='Our self-help habit hacker tool will support you to make changes by understanding what you find tough and making a plan.'
        contentClasses='my-auto'
        btnLabel='Try Habit Hacker'
        btnOnClick={() => window.open('https://www.livewelldorset.co.uk/habit-hacker/', '_blank', 'noopener')}
      />

      <div className='bg-primary-100 py-8 px-6 smd:py-14 smd:px-12'>
        <div className='max-w-lwd-860 mx-auto space-y-10'>
            <StepCard
              classes='bg-white rounded-xl'
              key={'dashboard-contact-time'}
              title={initialPreferenceValues.days.length > 0 || initialPreferenceValues.times.length > 0 ? 'Your availability:' : 'When are you available?'}
              secBtnOnClick={() => navigate('/suggest-time', { state:{ fromHome: true}})}
              secBtnLabel={initialPreferenceValues.days.length > 0 || initialPreferenceValues.times.length > 0 ? 'Change availability' : 'Confirm my availability'}
              secBtnClass='btn secondary mt-4 smd:my-auto justify-center w-full'
              mode='secondary'
              bodyHtml={<div>
                {initialPreferenceValues.times.length > 0 && (<PreferenceItem
                  icon='timer'
                  classes='mt-5'
                  title={getContactOptionChosen(timeOptions, initialPreferenceValues.times)}
                />)}
                {initialPreferenceValues.days.length > 0 && (<PreferenceItem
                  icon='calendar'
                  classes='mt-5'
                  title={getContactOptionChosen(dayOptions, initialPreferenceValues.days)}
                />)}
              </div>}
            />

            <StepCard
              classes='bg-white rounded-xl'
              key={'dashboard-contact-method'}
              title={profile && profile.preferred_method ? 'Your contact preferences:' : 'What is your preferred contact method?'}
              secBtnOnClick={() => navigate('/update-contact', { state:{ fromHome: true}})}
              secBtnLabel={profile && profile.preferred_method ? 'Change preference' : 'Confirm my preferences'}
              secBtnClass='btn secondary mt-4 smd:my-auto justify-center w-full'
              mode='secondary'
              bodyHtml={profile && profile.preferred_method && <PreferenceItem
                  icon='phone'
                  classes='mt-5'
                  title={profile.preferred_method}
                />}
            />

            <div className='pt-2 smd:pt-8'>
              <Heading h2>We would love to hear your feedback!</Heading>
              <Text classes='mt-2' body>Have something to say about your experience with us? Please let us know! </Text>
              <Button classes='mt-4 smd:mt-8 justify-center w-full smd:w-5/12' mode='primary' onClick={() => navigate('/feedback')} label='Leave Feedback' />
            </div>
        </div>
      </div>
    </MyLiveWellView>
  );
};

Dashboard.propTypes = {
};

Dashboard.defaultProps = {
};

