import React from 'react';
import PropTypes from 'prop-types';
import { useFormState } from 'react-final-form';

import { MainView } from '../../../templates/MainView';
import { Heading } from '../../../components/atoms/Heading';
import { Field } from '../../../components/molecules/Field';
import { Icon } from '../../../components/atoms/Icon';

import './styles.css';
import { useDropDown } from '../../../lib/useDropdown';
import { Text } from '../../../components/atoms/Text';

/**
 * Primary UI component for user interaction
 */
export const SignupBehalf = ({ buttons, skipToPage }) => {
  const formState = useFormState()
  const getValue = (name) => {
    const clientData = formState.values['client'];
    if (! clientData) return '';

    if(typeof clientData[name] === 'string') {
        return clientData[name];
    } else if(clientData[name] && clientData[name].value) {
      return clientData[name].value;
    } else if(clientData[name]) {
      return clientData[name];
    }
    return '';
  }

  const referrerRelations = useDropDown('referrerRelations');

  return (
    <MainView BPadding1>
      <div className='title-container'>
        <Text body1m='true'>MyLiveWell Registration</Text>
      </div>

      <div className='px-6 pt-8 pb-32 smd:p-12'>
        <Heading classes="pb-2" h1>Tell us about yourself and your relationship with {getValue('first_name')}</Heading>
        <Text classes="pb-8" body>As you have told us you are signing up on behalf of {getValue('first_name')}, we just need a few of your details should we need to get in touch.</Text>

        <div className='flex flex-col gap-8'>
          <div className='flex flex-row gap-4 w-full'>
            <Field
              input='text'
              name='client[someone_else_first_name]'
              label='First Name'
              placeholder='First Name'
              isRequired
            />

            <Field
              input='text'
              name='client[someone_else_last_name]'
              label='Last Name'
              placeholder='Last Name'
              isRequired
            />
          </div>
          <Field
            input='email'
            name='client[someone_else_email]'
            label='Email Address'
            placeholder='e.g. joebloggs@gmail.com'
            modalsubtitle='Why do we ask this?'
            modaltitle='Email Address'
            help='We would like to be able to contact you if you are helping someone to register.'
            isRequired
            />

          <Field
            input='radio'
            name='client[someone_else_relationship]'
            label={`What is your relationship to ${getValue('first_name')}?`}
            inputOptions={{
              options: referrerRelations
            }}
            isRequired
          />
        </div>

        { buttons }
      </div>
    </MainView>
  );
};

SignupBehalf.propTypes = {
  buttons: PropTypes.node,
  skipToPage: PropTypes.func,
};

SignupBehalf.defaultProps = {
};

