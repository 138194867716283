import React from 'react';
import PropTypes from 'prop-types';

import './styles.css';

/**
 * Primary UI component for user interaction
 */
export const GoalGroup = ({ goals }) => {  
    return (
    <div className='goal-group'>
        { goals }
    </div>
    );
};

GoalGroup.propTypes = {
  /**
   * Add your goals here
   */
   goals: PropTypes.arrayOf(PropTypes.object).isRequired
};

GoalGroup.defaultProps = {
};
