import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router';

import { MainView } from '../../../templates/MainView';
import { Image } from '../../../components/atoms/Image';
import { BadgeGroup } from '../../../components/molecules/BadgeGroup';
import { HeadingBlock } from '../../../components/molecules/HeadingBlock';
import { Text } from '../../../components/atoms/Text';
import { Button } from '../../../components/atoms/Button';

import api from '../../../lib/api';

import HeaderImg from '../../../assets/images/assessment-header.jpeg';
import { getParameterByName } from '../../../features/geturlparameter';
import { StickyButtons } from '../../../components/molecules/StickyButtons';
import { ButtonGroup } from '../../../components/molecules/ButtonGroup';

/**
 * Primary UI component for user interaction
 */
const badges = [
  { label: 'Around 5 Minutes', icon: 'timer', value: null },
  { label: '12 Steps To Complete', icon: 'questions', value: null },
  { label: 'Secure Data Handling', icon: 'secure', value: null },
]

export const GettingStarted = ({ buttons, customButtons }) => {
  const navigate = useNavigate();
  const [status, setStatus] = useState('');
  const hash = getParameterByName('h');

  useEffect(() => {
    api.preAssessment(hash ? hash : '', (err, responseJSON) => {
      if(responseJSON && responseJSON.data) {
        setStatus(responseJSON.data.status);
      }

      console.error(err, 'Unable to find client pre-assessment details');
    });
  }, [hash]);

  return (
    <MainView BPadding1>
      <div className='p-0 md:px-12 md:pt-12'>
        <Image src={HeaderImg} containerClasses='smd:rounded-t-xl' imgClass='max-h-lwd-200 object-top' alt='Get support from LiveWell Dorset' />
      </div>

      <div className='flex flex-col px-6 pt-8 pb-14 smd:px-12 smd:pb-12'>
        {status === 'notfound' ? (
          <Text body>Sorry your case is currently closed, if you’d like to get started with LiveWell Dorset again please <Button label='send us a message' onClick={() => navigate('/help')} mode='underlineBtn' classes='!inline-block !p-0' />.</Text>
        ) : ([
          <HeadingBlock key={0} classes="mb-8" heading="Let's do a quick health check" text="Let's find out more about your health and wellbeing." />,
          <BadgeGroup key={1} options={badges} />,
          <br key={2} />
        ])}

        {status === 'notfound' ? (
          <StickyButtons classes='md-sticky-pt-0 md:mt-4'>
            <ButtonGroup isStacked buttons={[
              <Button label="Back" mode="primary" classes='justify-center' onClick={() => navigate('/')} key={1} />
            ]} />
          </StickyButtons>
        ) : (
          customButtons || buttons
        )}
      </div>
    </MainView>
  )
};

GettingStarted.propTypes = {
  buttons: PropTypes.element,
  customButtons: PropTypes.element,
};

GettingStarted.defaultProps = {
  buttons: null,
};