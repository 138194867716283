import React, { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Text } from '../../../components/atoms/Text';
import { Badge } from '../../../components/molecules/Badge';

import { MultiForm } from '../../../components/organisms/MultiForm';
import { pingUser } from '../../../features/login';
import { ModalView } from '../../../templates/ModalView';
import { ArrangeSession } from '../../RegistrationFlow/ArrangeSession';
import { CompleteAssessment } from '../../RegistrationFlow/CompleteAssessment';

import './styles.css';

/**
 * Primary UI component for user interaction
 */
export const BookAChat = () => {
  const dispatch = useDispatch();
  const profile = useSelector((state) => state.profile);
  const CallUsNowModal = useRef(null);

  let startingHistory = [0, 1];
  if (typeof profile.assessment_count === 'number' && profile.assessment_count === 0) {
    startingHistory = [0];
  }

  useEffect(() => {
    dispatch(pingUser());
  }, [dispatch]);

  return (
    <>
      <MultiForm
        name="BookAChat"
        initialHistory={startingHistory}
        onSubmit={() => {}}
        hideButtons={false}
      >
        <MultiForm.Page>
          <CompleteAssessment customButtons={[]} />
        </MultiForm.Page>

        <MultiForm.Page>
          <ArrangeSession
            contactMessage={profile.preferred_method !== 'Email' ? 'One of our advisors will give you a phone call soon!' : 'One of our advisors will email you soon, but you can call us now to get started early!'}
            customButtons={[]}
          />
        </MultiForm.Page>
      </MultiForm>

      <ModalView
        key={1325}
        ref={CallUsNowModal}
        title='Call Us Now'
        classes='rounded-xl'
      >
        <p className='mb-4'>Call us free of charge Monday - Friday from 9 AM to 6:30 PM.</p>
        <Badge children={[
          <Text key={0} classes='secondary-900 font-todaysbmed text-26' caption
            onClick={() => alert('nav')}
          ><a href="tel:08008401628">0800 840 1628</a></Text>
        ]}
        />
      </ModalView>
    </>
  );
};

BookAChat.propTypes = {
};

BookAChat.defaultProps = {
};

