import './App.css';
import { useEffect } from 'react';
import { Routes, Route, Link, useNavigate, useLocation } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";


import balloons from './assets/images/registation/balloons.svg';
import alterBalloons from './assets/images/registation/alternative-balloons.svg';

import { Registration } from './pages/Forms/Registration';
import { Assessment } from './pages/Forms/Assessment';
import { BookAChat } from './pages/Forms/BookAChat';
import { NotEligible } from './pages/Forms/NotEligible';
import { SelectATimeForm } from './pages/Forms/SelectATimeForm';
import { PasswordCreation } from './pages/Forms/PasswordCreation';
import { UpdateGoals } from './pages/Forms/UpdateGoals';

import { TooYoung } from './pages/RegistrationFlow/TooYoung';
import { EmailAlreadyExists } from './pages/RegistrationFlow/EmailAlreadyExists';
import { SignupBehalf } from './pages/RegistrationFlow/SignupBehalf';
import { EmailHandler } from './pages/RegistrationFlow/EmailHandler';

import { StoryTemplate } from './templates/StoryTemplate';
import { Article } from './pages/Article';
import { VerificationEmail } from './pages/RegistrationFlow/VerificationEmail';
import { StickyButtons } from './components/molecules/StickyButtons';
import { ButtonGroup } from './components/molecules/ButtonGroup';
import { Button } from './components/atoms/Button';

import { ThankYou } from './pages/ThankYou';

import { Login } from './pages/MyLivewellFlow/Login';
import { ResetPassword } from './pages/MyLivewellFlow/ResetPassword';
import { Sessions } from './pages/MyLivewellFlow/Sessions';
import { Plan } from './pages/MyLivewellFlow/Plan';
import { Actions } from './pages/MyLivewellFlow/Actions';
import { Account } from './pages/MyLivewellFlow/Account';
import { Messages } from './pages/MyLivewellFlow/Messages';
import { Help } from './pages/MyLivewellFlow/Help';
import { Responses } from './pages/MyLivewellFlow/Responses';

import { attemptLogin, checkLogin } from './features/login';
import { Dashboard } from './pages/Dashboard';
import { ArticleList } from './pages/ArticleList';
import { UpdateContactMethodForm } from './pages/Forms/UpdateContactMethodForm';
import { AssessmentResultsForm } from './pages/Forms/AssessmentResultsForm';
import { FeedbackForm } from './pages/Forms/FeedbackForm';
import { VerificationEmailForm } from './pages/Forms/VerificationEmailForm';
import { analytics } from './lib/analytics';

// const RedirectToLogin = () => {
//   const navigate = useNavigate();
//   useEffect(() => {
//     navigate('/');
//   }, []);
//   return null;
// }

function App() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();

  const { loggedIn } = useSelector((state) => state.login);
  const plan = useSelector(state => state.plan);


  useEffect(() => {
    analytics('pageview', {page: location.pathname});
  }, [location]);

  useEffect(() => {
    dispatch(checkLogin());
  }, [dispatch]);

  let routeList = <>
    <Route path="/" exact element={<Login />} />
    <Route path="/forgot-password" element={<Login />} />
    <Route path="/check-email" element={
      <Responses
        title='Check your email'
        description='We have emailed you instructions on how to reset your password.'
        buttonLabel='Back to log in'
        onClick={() => navigate('/')}
      />
    } />
    <Route path="/reset-password" element={<ResetPassword />} />
    <Route path="/password-updated" element={
      <Responses
        title='Password saved'
        description='Your password has been reset.'
        buttonLabel='Log in'
        onClick={() => navigate('/')}
      />
    } />
    <Route path="/account-closed" element={
      <Responses
        title='Account closed'
        description='You have requested account closure, a member of the team will action this within two working days.'
      />
    } />
  </>;

  if (loggedIn) {
    routeList = <>
      <Route path="/" element={<Dashboard />} />
      <Route path="plan" element={<Plan />} />
      <Route path="sessions" element={<Sessions />} />
      <Route path="articles" element={<ArticleList />} />
      <Route path="actions" element={<Actions />} />
      <Route path="messages" element={<Messages />} />
      <Route path="account" element={<Account />} />
      <Route path="help" element={<Help />} />

      <Route path="update-goals" element={<UpdateGoals />} />
      <Route path="update-goals/complete" element={
          <ThankYou
            imageSrc={balloons}
            title='Your choices have been updated!'
            description='Thank you. It is important to keep your health choices updated so that we can support you in the best possible way.'
            buttonLabel='Go Back To Home'
            onClick={() => {
              navigate("/");
            }}
          />
      } />

      <Route path="/account-updated" element={
        <Responses
          title='Account updated'
          description='Thanks for updating your account.'
          buttonLabel='Back to my dashboard'
          onClick={() => navigate('/')}
        />
      } />
      <Route path="/session-updated" element={
        <Responses
          title='Session updated'
          description='Thanks for updating your session.'
          buttonLabel='Back to sessions'
          onClick={() => navigate('/sessions')}
        />
      } />
      <Route path="/cancel-checkin" element={
        <Responses
          title='Session cancelled'
          description="We still want to catch up - we'll email you near the date to gather your feedback."
          buttonLabel='Back to sessions'
          onClick={() => navigate('/sessions')}
        />
      } />
      <Route path="/cancel-coaching" element={
        <Responses
          title='Session cancelled'
          description='Thanks for cancelling your session.'
          buttonLabel='Back to sessions'
          onClick={() => navigate('/sessions')}
        />
      } />
      <Route path="/message-received" element={
        <Responses
          title='Message received'
          description='Thank you for your message. We will be in touch shortly.'
          buttonLabel='Back to my dashboard'
          onClick={() => navigate('/')}
        />
      } />
      <Route path="book-a-chat" element={<BookAChat />} />
      <Route path="suggest-time" element={<SelectATimeForm />} />
      <Route path="update-contact" element={<UpdateContactMethodForm />} />

      <Route path="feedback" element={<FeedbackForm />} />
    </>;
  }
  console.log('loggedIn',loggedIn);
  return (
    <Routes>
      { routeList }

      <Route path="register" element={<Registration />} />
      <Route path="register/complete" element={<VerificationEmailForm />} />
      <Route path="register/somebody-else" element={<StoryTemplate><SignupBehalf first_name='test' /></StoryTemplate>} />
      <Route path="register/email-not-received" element={<StoryTemplate><EmailHandler /></StoryTemplate>} />

      <Route path="assessment" element={<Assessment />} />
      <Route path="assessment/complete" element={<AssessmentResultsForm />} />

      <Route path="eligibility/not-in-dorset" element={<NotEligible />} />
      <Route path="eligibility/too-young" element={<TooYoung />} />
      <Route path="eligibility/email-exists" element={<StoryTemplate
        onSubmit={(values) => {
          dispatch(attemptLogin(values.email, values.dob, values.password, (valid, res) => {
            if (valid) navigate('/');
          }));
        }}
        buttonLabel='Log in'
        >
          <EmailAlreadyExists customButtons={
            <StickyButtons classes='md-sticky-pt-0'>
              <ButtonGroup isStacked buttons={[
                <Button label="Login" mode="primary" key={1} isSubmit />,
                <Button label="Forgot Password" mode="secondary" key={2} onClick={() => navigate('/reset-password')} />
              ]} />
            </StickyButtons>
          } />
        </StoryTemplate>} />

      <Route path="what-happens-next" element={<Article />} />

      <Route path="password-creation" element={<PasswordCreation />} />
      <Route path="password-creation/complete" element={
          <ThankYou
            imageSrc={balloons}
            title={`Your account is now ready to view`}
            description='You can now contact us for advice and support. We are so happy to have you onboard!'
            onClick={() => {
              navigate("/");
            }}
          />
      } />
      {/* <Route path='*' element={<RedirectToLogin />} /> */}
    </Routes>
  );
}

export default App;
