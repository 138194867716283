import React from 'react';
import PropTypes from 'prop-types';

import './styles.css';

/**
 * Primary UI component for user interaction
 */
export const InfoCardGroup = ({ cards, classes, horizontal }) => {  
    return (
    <div className={[
      'info-card-group', 'flex', 'gap-4', 'w-full',
      horizontal ? 'horizontal' : 'stacked',
      classes && classes].join(' ')}
    >
      { cards }
    </div>
    );
};

InfoCardGroup.propTypes = {
  /**
   * Add your buttons here
   */
   cards: PropTypes.arrayOf(PropTypes.object).isRequired,
   classes: PropTypes.string,
};

InfoCardGroup.defaultProps = {
  classes: '',
  horizontal: false
};
