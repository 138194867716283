import React from 'react';
import PropTypes from 'prop-types';
import { Text } from '../../atoms/Text';
import { Heading } from '../../atoms/Heading';
import { ResultBar } from '../ResultBar';
import { Icon } from '../../atoms/Icon';

/**
 * Primary UI component for user interaction
 */
export const ResultCardBig = ({ title, body, type, min, max, current, icon, classes, invertResultBar }) => {
  return (
    <div className={[
      'result-card bg-lwd-grey-100 rounded-xl shadow-card overflow-hidden w-full',
      classes && classes
      ].join(' ')}>
      <div className="p-4 flex flex-col justify-center items-center text-center">
        <Heading classes="mb-4 text-45" h3>{title}</Heading>
        { type === "NoResultBar" ? "" : <ResultBar min={min} max={max} current={current} type={type} invert={invertResultBar} /> }
        { icon ? <Icon name={icon} /> : "" }
        <Text classes={type === "NoResultBar" && !icon ? "" : 'mt-4'} body1m>{body}</Text>
      </div>
    </div>
  );
};

ResultCardBig.propTypes = {
  /**
   * Heading of the card
   */
  title: PropTypes.string,

  /**
   * Main body text
   */
  body: PropTypes.string,

  /**
   * Type
   */
  type: PropTypes.string,

  /**
   * Min value for ResultBar
   */
  min: PropTypes.number,

  /**
   * Max value for ResultBar
   */
  max: PropTypes.number,

  /**
   * Max value for ResultBar
   */
  current: PropTypes.number,

  /**
   * Icon name
   */
  icon: PropTypes.string,

  /**
   * Invert the positive result direction
   */
   invertResultBar: PropTypes.bool,
};

ResultCardBig.defaultProps = {
  heading: '',
  body: '',
  type: '',
  icon: '',
  invertResultBar: false,
};