import React from 'react';
import { useFormState } from 'react-final-form';
import { useDropDown } from '../../../lib/useDropdown';
import PropTypes from 'prop-types';
import { MainView } from '../../../templates/MainView';
import { Heading } from '../../../components/atoms/Heading';
import { Field } from '../../../components/molecules/Field';
import { Text } from '../../../components/atoms/Text';

import './styles.css';

/**
 * Primary UI component for user interaction
 */
export const GPAndWorkplace = ({ buttons }) => {
    const formState = useFormState();

    const gpSurgeries = useDropDown('gpSurgery', null, true);
    const workplaces = useDropDown('workplaces');

    return (
        <MainView BPadding1 allowOverflow>
            <div className='title-container'>
              <Text body1m='true'>MyLiveWell Registration</Text>
            </div>
            <div className='px-6 pt-8 pb-14 smd:p-12 flex flex-col'>
                <div className='flex flex-col space-y-8'>
                    <Heading h1>We just need to do one final check</Heading>

                    {formState.values && formState.values.not_in_dorset === 'gp in Dorset' && (<Field
                        input='select'
                        id='gp_surgery'
                        name='gp_surgery'
                        label={<span>Which <span style={{textTransform: 'capitalize'}}>GP</span> surgery are you registered to?</span>}
                        placeholder='Please select'
                        modalsubtitle='What is this?'
                        modaltitle='Which GP surgery are you registered to?'
                        help='Please enter the name of the surgery where you are registered with your GP.'
                        isRequired
                        inputOptions={gpSurgeries}
                    />)}

                    {formState.values && formState.values.not_in_dorset === 'health professional in Dorset' && (<Field
                        input='select'
                        id='where_work'
                        name='where_work'
                        label='Where do you work?'
                        placeholder='Please select'
                        modalsubtitle='Why do we ask this?'
                        modaltitle='Where do you work?'
                        help='This helps us check whether you are eligible for support.'
                        isRequired
                        inputOptions={workplaces}
                    />)}
                </div>

                { buttons }
            </div>
        </MainView>
    );
};

GPAndWorkplace.propTypes = {
    buttons: PropTypes.element,
};

GPAndWorkplace.defaultProps = {
    buttons: null
};

