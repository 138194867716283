import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';

import { MyLiveWellView } from '../../../templates/MyLiveWellView';
import { Heading } from '../../../components/atoms/Heading';
import { Text } from '../../../components/atoms/Text';
import { ActionItem } from '../../../components/molecules/ActionItem';
import { TabView } from '../../../templates/TabView';

import './styles.css';
import { getActions } from '../../../features/actions';

export const Actions = () => {
  const [current, setCurrent] = useState([]);
  const [completed, setCompleted] = useState([]);

  const dispatch = useDispatch();
  const actions = useSelector((state) => state.actions);

  useEffect(() => {
    dispatch(getActions());
  }, [dispatch]);

  useEffect(() => {
    if (!actions.length) return;

    const smCur = [];
    const smCom = [];

    actions.forEach((act) => {
      console.log(act);
      if (act.status === "complete") {
        smCom.push(act);
      } else {
        smCur.push(act);
      }
    });

    setCurrent(smCur);
    setCompleted(smCom);
  }, [actions]);

  const tab1Content = () => (
    <div className='tab-content flex flex-col'>
      {current.length === 0 ? (
        <Text classes='font-todaysbbold' body>You have no current actions.</Text>
      ):(
        current.map((action, idx) => (
          <ActionItem
            key={`current${idx}`}
            id={action.id}
            title={action.title}
            date={moment(action.due_date).format('MM/DD/YYYY')}
            description={action.description}
            isCompleted={action.status === 'complete'}
          />
        ))
      )}
    </div>
  );

  const tab2Content = () => (
    <div className='tab-content flex flex-col'>
      {completed.length === 0 ? (
        <Text classes='font-todaysbbold' body>You have no completed actions.</Text>
      ): (
        completed.map((action, idx) => (
          <ActionItem
            key={`current${idx}`}
            id={action.id}
            title={action.title}
            date={moment(action.due_date).format('MM/DD/YYYY')}
            description={action.description}
            isCompleted={action.status === 'complete'}
          />
        ))
      )}
    </div>
  );

  return (
    <MyLiveWellView>
      <div className='max-w-lwd-860 w-full mx-auto px-6 py-8 smd:py-12 lg:px-0 flex flex-col gap-8'>
        <div>
          <Heading classes='text-lwd-grey-900' h2>Actions</Heading>
          <Text classes="mt-2.5" body2>These are your actions that have been set up by your coach.</Text>
        </div>

        <TabView
          tabTitles={['Current', 'Completed']}
          tabContents={[
            tab1Content(),
            tab2Content()
          ]}
        />
      </div>
    </MyLiveWellView>
  );
};

Actions.propTypes = {
};

Actions.defaultProps = {
};