import React from 'react';
import PropTypes from 'prop-types';
import { useLocation, useNavigate } from 'react-router-dom';

import { MainView } from '../../../templates/MainView';
import { Text } from '../../../components/atoms/Text';
import { Button } from '../../../components/atoms/Button';
import { Heading } from '../../../components/atoms/Heading';
import { InfoCard } from '../../../components/molecules/InfoCard';
import { ResultCard } from '../../../components/molecules/ResultCard';
import { ResultCardBig } from '../../../components/molecules/ResultCardBig';
import { HeadingTopBlock } from '../../../components/molecules/HeadingTopBlock';
import { ResultCarousel } from '../../../components/organisms/ResultCarousel';

import { StickyButtons } from '../../../components/molecules/StickyButtons';
import { ButtonGroup } from '../../../components/molecules/ButtonGroup';
import { ProgressBar } from '../../../components/molecules/ProgressBar';

import './styles.css';
import { useSelector } from 'react-redux';
import { useDropDown } from '../../../lib/useDropdown';

/**
 * Primary UI component for user interaction
 */
export const Results = ({ buttons, storyData, redirectIfInvalid }) => {
  const navigate = useNavigate();
  const profile = useSelector((state) => state.profile);
  const smokingTypes = useDropDown('SmokingType', (values) => values.filter(obj => ['Non-Smoker', 'e-Cigarettes'].indexOf(obj.label) === -1));

  const redirectToHome = () => {
    navigate('/');
    return (<div></div>);
  };

  let location = useLocation();
  if (storyData) {
    location = storyData;
  }
  if (redirectIfInvalid && !(location.state && location.state.stats && location.state.messages)) return redirectToHome();

  const stats = location.state && location.state.stats ? location.state.stats : [];
  const messages = location.state && location.state.messages ? location.state.messages : [];
  const formValues = location.state && location.state.values ? location.state.values : [];
  if (! formValues.assessment) formValues.assessment = [];
  if (redirectIfInvalid && (! stats.current || ! stats.previous || !formValues.assessment)) return redirectToHome();


  // standardize units
  const caloriesPerUnit = 56; // average calories in a unit of alcohol
  const unitsPerBurger = 5; // assumes 305 calories in a burger
  const pricePerCigarette = 0.45; // 45p flat rate for all smoking types
  let caloriesPerHour = 370; // average female, 'moderate activity'
  if (profile.gender === 'Male') {
    caloriesPerHour = 460; // average male, 'moderate activity'
  }

  const drinksConsumed = () => {
    if (! formValues) return null;

    const resultCards = [];
    const alcoholDrinks = Object.keys(formValues.assessment).filter(key => key.substring(0, 17) === 'alcohol_beverage_');

    let count = 0;
    for (let drink of alcoholDrinks) {
      if (! formValues.assessment[drink]) continue;
      let drinkName = drink.replace('alcohol_beverage_', '').replaceAll('_', ' ');
      count++;

      resultCards.push(<ResultCard
        key={`drink-results-${count}`}
        title={`${formValues.assessment[drink]}x`}
        body={drinkName}
        />);
    }

    return resultCards.length > 0 ? (
      <ResultCarousel
        key='beverage-results'
        classes="mr-neg-6 w-auto mb-2 sm:mb-6 sm:mr-0"
        data={resultCards}
      />
    ) : null;
  };

  const getSmokingType = (value) => {
    let smokingType = smokingTypes.find(type => type.value === parseInt(value));
    return smokingType ? smokingType.label : '';
  };

  // show drinking if currently drinking, or not currently drinking but did previously
  const shouldShowDrinking = (stats.current && stats.current.alcohol) ||
    (formValues.assessment.alcohol_drink_frequency === '0' && stats.previous && stats.previous.alcohol);

  return (
    <MainView BPadding1>
      <div className='title-container'>
        <Text body1m='true'>Quick health check</Text>
      </div>

      <div className='px-6 pt-8 pb-14 smd:p-12 flex flex-col results'>
        <div className='divide-y-4 divide-primary-100'>
          <div className='px-6 py-8 smd:px-0 smd:pt-0'>
            <Heading classes='mb-2' h1>Here are your results!</Heading>
            <Text body>This is what we found out from your quick health check.</Text>
          </div>

          {/* BMI */}
          {formValues.assessment.assessment_pregnant !== 'Yes' && stats.current && stats.current.bmi && (<div className='px-6 py-8 smd:px-0 smd:pb-lwd-72'>
              <Heading classes='mb-2' h2>Your BMI</Heading>
              <Text body>Calculating your Body Mass Index (BMI) can be helpful in identifying if you are within a healthy weight range.</Text>

              <div className='flex flex-col mt-6'>
                <ResultCardBig
                  title={stats.current.bmi}
                  body={messages.weight ? messages.weight.overview : ''}
                  min={10}
                  max={40}
                  current={stats.current.bmi}
                />
                {messages.weight && messages.weight.message && (<InfoCard
                  // icon='info-round'
                  setInnerHtml
                  text={messages.weight.message}
                  classes='mt-6'
                  neutral
                />)}
              </div>

              {stats.current.bmi > 25 && (<Button classes='mt-6 smd:mb-8 w-full justify-center' label='Tips For Losing Weight' mode='secondary' onClick={() => window.open('https://www.livewelldorset.co.uk/faq/lose-weight/', '_blank', 'noopener')} />)}
            </div>)}

          {/* Activity */}
          <div className='px-6 py-8 smd:px-0 smd:pb-10'>
            <Heading classes='mb-2' h2>Your activity</Heading>
            <Text body>Your daily activity in numbers:</Text>

            {stats.current && typeof stats.current.activity === 'number' && (<div className='flex flex-col mt-6'>
              <div className='flex flex-row mb-6'>
                <ResultCard
                  key={0}
                  title={Math.round(((stats.current.activity / 7) / 60) * 100, 2) / 100}
                  body="Hours of Exercise"
                  classes="mr-6 w-1/2"
                />
                <ResultCard
                  key={1}
                  title={Math.round((parseInt(stats.current.activity / 7) / 60) * caloriesPerHour, 2)}
                  body="Calories Burned"
                  classes="w-1/2"
                />
              </div>
              <ResultCardBig
                type="oneThird"
                min={0}
                max={44}
                current={stats.current.activity / 7}
                body={messages.activity ? messages.activity.overview : ''}
              />
              {messages.activity && messages.activity.message && (<InfoCard
                classes='mt-6'
                // icon='info-round'
                text={messages.activity.message}
                setInnerHtml
                neutral
              />)}
            </div>)}

            <Button classes='mt-6 smd:mb-8 w-full justify-center' label='Tips For Moving More' mode='secondary' onClick={() => window.open('https://www.livewelldorset.co.uk/faq/get-active/', '_blank', 'noopener')} />
          </div>

          {/* Smoking */}
          <div className='px-6 py-8 smd:px-0 smd:pb-10'>
            <Heading classes='mb-2' h2>
              {`Your ${['vape'].includes(formValues.assessment.smoking_smoke_or_vape) ? 'smoking and vaping' : 'smoking'} habits`}
            </Heading>
            <Text body>Your smoking activity:</Text>

            <div>
              <div className='flex flex-col space-y-6 mt-6'>
                {/* Smoking daily variant - we know how many they smoke */}
                {stats.current && stats.current.smoking ? (<div>
                  <ResultCarousel
                    key='smoking-quantity-results'
                    classes="mr-neg-6 w-auto mb-2 sm:mb-6 sm:mr-0"
                    data={[
                      <ResultCard
                        key={'current-smoking'}
                        title={stats.current.smoking}
                        body={formValues && formValues.assessment && formValues.assessment.smoking_type ? getSmokingType(formValues.assessment.smoking_type) : ''}
                      />,
                      // <ResultCard
                      //   key={1}
                      //   title="3"
                      //   body="Cigars"
                      // />,
                      // <ResultCard
                      //   key={2}
                      //   title="12X"
                      //   body="Roll ups"
                      // />,
                    ]}
                  />
                  <ResultCardBig
                    type="NoResultBar"
                    title={`£${(stats.current.smoking * 7 * pricePerCigarette).toFixed(2)}`}
                    body="Roughly how much you could save a week if you quit smoking!"
                  />
                  {messages.smoking && messages.smoking.message && (<InfoCard
                    classes='mt-6'
                    text={messages.smoking.message}
                    setInnerHtml
                    neutral
                  />)}
                </div>) : ''}

                {/* Non smoking variant */}
                {!['smoke and vape', 'smoke'].includes(formValues.assessment.smoking_smoke_or_vape) && (<div>
                  {messages.smoking && messages.smoking.title && (<ResultCard
                    title={messages.smoking.title}
                  />)}
                  {messages.smoking && messages.smoking.message && (<InfoCard
                    classes='mt-6'
                    text={messages.smoking.message}
                    neutral
                  />)}
                </div>)}
              </div>
              <div className='mt-6 smd:mb-8'>
                {['smoke and vape', 'smoke'].includes(formValues.assessment.smoking_smoke_or_vape) && <Button classes='w-full justify-center' label='Tips To Quit Smoking' mode='secondary' onClick={() => window.open('https://www.livewelldorset.co.uk/faq/stop-smoking/', '_blank', 'noopener')} />}
              </div>
            </div>

            {/* Vaping variant */}
            {['vape'].includes(formValues.assessment.smoking_smoke_or_vape) && (<div className='flex flex-col mt-6 space-y-6'>
              <Text body>Your vaping activity:</Text>
              <ResultCard title={`You are currently vaping ${formValues.assessment.smoking_vape_frequency ? formValues.assessment.smoking_vape_frequency.toLowerCase() : 'daily'}`} />
              <InfoCard
                text='Vaping can be a great tool to quit smoking, but does come with it’s own issues. Consider quitting vaping completely!'
                setInnerHtml
                neutral
              />
              <Button classes='smd:mb-8 w-full justify-center' label='Tips To Quit Vaping' mode='secondary' onClick={() => window.open('https://www.livewelldorset.co.uk/articles/how-to-stop-vaping/', '_blank', 'noopener')} />
            </div>)}
          </div>

          {/* Drinking */}
          {shouldShowDrinking ? (<div className='px-6 py-8 smd:px-0 smd:pb-10'>
            <Heading classes='mb-2' h2>Your drinking habits</Heading>
            <Text body>Based on what you’ve told us about your current habits, this is what your daily drinking adds up to.</Text>

            <div className='flex flex-col mt-6'>
              {drinksConsumed()}
              <ResultCardBig
                type="oneThird"
                min={0}
                max={formValues.assessment.assessment_pregnant === 'Yes' ? 1 : 33}
                current={stats.current.alcohol}
                invertResultBar
                body={messages.alcohol ? messages.alcohol.overview : ''}
              />
              {stats.current.alcohol !== 0 && (<ResultCardBig
                type="NoResultBar"
                icon="burger"
                body={`The calories you are consuming through alcohol is equivalent to ${Math.round((stats.current.alcohol / unitsPerBurger) * 100) / 100} cheeseburgers per week.`}
                classes='mt-6'
              />)}
              {messages.alcohol && (<InfoCard
                classes='mt-6'
                text={messages.alcohol.message}
                setInnerHtml
                neutral
              />)}
            </div>

            {stats.current.alcohol !== 0 && (<Button classes='mt-6 smd:mb-0 w-full justify-center' label='Tips For Drinking Less' mode='secondary' onClick={() => window.open('https://www.livewelldorset.co.uk/faq/drink-less/', '_blank', 'noopener')} />)}
          </div>) : ''}
        </div>

        <StickyButtons>
          <ButtonGroup buttons={[
            <Button label='Continue' mode='primary' isSubmit key='results-continue' />
          ]} />
        </StickyButtons>
      </div>
    </MainView>
  );
};

Results.propTypes = {
  redirectIfInvalid: PropTypes.bool,
};

Results.defaultProps = {
  // redirectIfInvalid: true,
};